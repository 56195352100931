import React from 'react'

import { CNavItem, CNavTitle } from '@coreui/react'
import CIcon from '@coreui/icons-react'
// React icons
import {
    cilAudioDescription,
    cilBalanceScale,
    cilNewspaper,
    cilLocationPin,
    cilUser,
    cilGrid,
    cilVideo,
    cilScreenDesktop,
    cilCalendar,
    cilBook,
    cilGroup,
    cilChartLine,
    cilImage,
} from '@coreui/icons'

const adminConstants = {
    navbar: [
        {
            component: CNavTitle,
            name: 'Edit Options',
            value:
                'role-view' &&
                'user-view' &&
                'advertisement-status' &&
                'user-view' &&
                'gold-silver-view',
        },

        {
            component: CNavItem,
            name: 'User',
            to: '/user',
            icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
            value: 'user-view',
        },

        {
            component: CNavItem,
            name: 'Role',
            to: '/role',
            icon: <CIcon icon={cilGroup} customClassName="nav-icon" />,
            value: 'role-view',
        },
    ],
    compulsoryNavBar: [
        {
            component: CNavItem,
            name: 'Advertisements',
            to: '/advertisement',
            icon: (
                <CIcon icon={cilAudioDescription} customClassName="nav-icon" />
            ),
            value: 'advertisement-status',
        },
        {
            component: CNavItem,
            name: 'Gold and Silver',
            to: '/gold&silver',
            icon: <CIcon icon={cilBalanceScale} customClassName="nav-icon" />,
            value: 'gold-silver-view',
        },
        {
            component: CNavItem,
            name: 'Category',
            to: '/category',
            icon: <CIcon icon={cilGrid} customClassName="nav-icon" />,
        },
        {
            component: CNavItem,
            name: 'Fm And Tvs',
            to: '/fm&tvs',
            icon: <CIcon icon={cilScreenDesktop} customClassName="nav-icon" />,
        },

        {
            component: CNavItem,
            name: 'Epaper',
            to: '/epaper',
            icon: <CIcon icon={cilBook} customClassName="nav-icon" />,
        },
        {
            component: CNavItem,
            name: 'News',
            icon: <CIcon icon={cilNewspaper} customClassName="nav-icon" />,
            childrens: [
                {
                    component: CNavItem,
                    name: 'News List',
                    to: '/ekadmin-news',
                    icon: (
                        <CIcon icon={cilNewspaper} customClassName="nav-icon" />
                    ),
                    value: 'news-index',
                },
                {
                    component: CNavItem,
                    name: 'Channel List',
                    to: '/channel-list',
                    icon: (
                        <CIcon icon={cilChartLine} customClassName="nav-icon" />
                    ),
                    value: 'channel-list',
                },
            ],
        },
        {
            component: CNavItem,
            name: 'Nepse',
            icon: <CIcon icon={cilChartLine} customClassName="nav-icon" />,
            value: 'nepse-index',
            childrens: [
                {
                    component: CNavItem,
                    name: 'Nepse Index',
                    to: '/nepse',
                    icon: (
                        <CIcon icon={cilChartLine} customClassName="nav-icon" />
                    ),
                    value: 'nepse-index',
                },
                {
                    component: CNavItem,
                    name: 'Nepse Index Value',
                    to: '/nepseIndexValue',
                    icon: (
                        <CIcon icon={cilChartLine} customClassName="nav-icon" />
                    ),
                    value: 'nepse-index-value',
                },
                {
                    component: CNavItem,
                    name: 'Daily Closing Price',
                    to: '/nepse-daily-closing-price',
                    icon: (
                        <CIcon icon={cilChartLine} customClassName="nav-icon" />
                    ),
                    value: 'nepse-closing-price',
                },
                {
                    component: CNavItem,
                    name: 'Company',
                    to: '/company',
                    icon: (
                        <CIcon icon={cilChartLine} customClassName="nav-icon" />
                    ),
                    value: 'company',
                },
                {
                    component: CNavItem,
                    name: 'RTS',
                    to: '/registrar-to-shares',
                    icon: (
                        <CIcon icon={cilChartLine} customClassName="nav-icon" />
                    ),
                    value: 'rst',
                },
                {
                    component: CNavItem,
                    name: 'sectors',
                    to: '/sectors',
                    icon: (
                        <CIcon icon={cilChartLine} customClassName="nav-icon" />
                    ),
                    value: 'sectors',
                },
            ],
        },
    ],
}

export default adminConstants

export const sortingOrder = {
    ASCENDING: 'ASC',
    DESCENDING: 'DESC',
}

export const URL_REG =
    '^(https?://)?([a-zA-Z0-9.-]+).([a-zA-Z]{2,})(:[0-9]+)?(/[^s]*)?$'

export const URL_REG_HTTPS =
    /^(https?:\/\/)[\w.-]+\.[a-z]{2,6}\.?([/\w.-]*)*\/?$/i

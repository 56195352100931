import React from 'react'

const Advertisement = React.lazy(() => import('pages/admin/advertisement'))
const AddAdvertisement = React.lazy(() =>
    import('pages/admin/advertisement/AddAdvertisement')
)
const AdvertisementDetails = React.lazy(() =>
    import('pages/admin/advertisement/AdvertisementDetails')
)
const EditAdvertiseMent = React.lazy(() =>
    import('pages/admin/advertisement/EditAdvertisement/EditAdvertisement')
)
const Category = React.lazy(() => import('pages/admin/Category'))
const AddCategory = React.lazy(() => import('pages/admin/Category/AddCategory'))
const CategoryDetails = React.lazy(() =>
    import('pages/admin/Category/CategoryDetails')
)
const EditCategory = React.lazy(() =>
    import('pages/admin/Category/EditCategory')
)
const Epaper = React.lazy(() => import('pages/admin/Epaper'))
const AddEpaper = React.lazy(() => import('pages/admin/Epaper/AddEpaper'))
const EditEpaper = React.lazy(() => import('pages/admin/Epaper/EditEpaper'))
const EpaperDetails = React.lazy(() =>
    import('pages/admin/Epaper/EpaperDetails')
)
const Events = React.lazy(() => import('pages/admin/Events'))
const AddEvents = React.lazy(() => import('pages/admin/Events/AddEvents'))
const EditEvents = React.lazy(() => import('pages/admin/Events/EditEvents'))
const EventsDetails = React.lazy(() =>
    import('pages/admin/Events/EventsDetails')
)
const FmAndTvs = React.lazy(() => import('pages/admin/fmandtvs'))
const AddFmAndTvs = React.lazy(() => import('pages/admin/fmandtvs/AddFmAndTvs'))
const EditFmAndTvs = React.lazy(() =>
    import('pages/admin/fmandtvs/EditFmAndTvs')
)

const GoldAndSilver = React.lazy(() => import('pages/admin/GoldAndSilver'))
const AddGoldAndSilver = React.lazy(() =>
    import('pages/admin/GoldAndSilver/AddGoldAndSilver')
)
const EditGoldAndSilver = React.lazy(() =>
    import('pages/admin/GoldAndSilver/EditGoldAndSilver')
)
const GoldAndSilverDetails = React.lazy(() =>
    import('pages/admin/GoldAndSilver/GoldAndSilverDetails')
)
const Location = React.lazy(() => import('pages/admin/Location'))
const News = React.lazy(() => import('pages/admin/News'))
const NewsDetail = React.lazy(() => import('pages/admin/News/NewsDetails'))
const Role = React.lazy(() => import('pages/admin/Role'))
const AddRole = React.lazy(() => import('pages/admin/Role/AddRole'))
const EditRole = React.lazy(() => import('pages/admin/Role/editRole/EditRole'))
const User = React.lazy(() => import('pages/admin/user'))
const AddUser = React.lazy(() => import('pages/admin/user/AddUser'))
const EditUser = React.lazy(() => import('pages/admin/user/EditUser'))
const UserDetail = React.lazy(() => import('pages/admin/user/UserDetail'))
const Videos = React.lazy(() => import('pages/admin/Videos'))
const NepseIndex = React.lazy(() => import('pages/admin/nepseIndex'))
const Sectors = React.lazy(() => import('pages/admin/sectors'))
const AddNepseIndexValue = React.lazy(() =>
    import('pages/admin/nepseIndexValue/AddNepseIndexValue')
)
const NepseIndexValue = React.lazy(() => import('pages/admin/nepseIndexValue'))
const EditNepseIndexValue = React.lazy(() =>
    import('pages/admin/nepseIndexValue/EditNepseIndexValue.jsx')
)
const AddCompany = React.lazy(() => import('pages/admin/company/AddCompnay'))
const Comapny = React.lazy(() => import('pages/admin/company'))
const EditCompany = React.lazy(() => import('pages/admin/company/EditCompany'))
const ListNepseClosingPrice = React.lazy(() =>
    import(
        'pages/admin/NepseClosingPrice/ListNepseClosingPrice/ListNepseClosingPrice'
    )
)
const ImportNepseClosingPrice = React.lazy(() =>
    import(
        'pages/admin/NepseClosingPrice/ImportNepseClosingPrice/ImportNepseClosingPrice'
    )
)
const FeaturedNews = React.lazy(() => import('pages/admin/News/FeaturedNews'))

const ListRTS = React.lazy(() => import('pages/admin/RTS/ListRTS/ListRTS'))
const AddRTS = React.lazy(() => import('pages/admin/RTS/AddRTS/AddRTS'))
const EditRTS = React.lazy(() => import('pages/admin/RTS/EditRTS/EditRTS'))
const Dashboard = React.lazy(() => import('pages/admin/Dashboard'))
const ChannelList = React.lazy(() => import('pages/admin/News/ChannelList'))

const routes = [
    { path: '/ekadmin', exact: true, name: 'Dashboard', component: Dashboard },
    { path: '/advertisement', name: 'Advertisement', component: Advertisement },
    {
        path: '/advertisement/add',
        name: 'Add Advertisement',
        component: AddAdvertisement,
    },
    {
        path: '/advertisement/edit/:id',
        name: 'Advertisement Details',
        component: EditAdvertiseMent,
    },
    {
        path: '/advertisement/:id',
        name: 'Advertisement Details',
        component: AdvertisementDetails,
    },
    { path: '/user', name: 'User', component: User },
    { path: '/user/add', name: 'Add User', component: AddUser },
    { path: '/user/edit/:id', name: 'Edit User', component: EditUser },
    { path: '/user/:id', name: 'User Detail', component: UserDetail },
    { path: '/gold&silver', name: 'Gold And Silver', component: GoldAndSilver },
    {
        path: '/gold&silver/add',
        name: 'Add Gold And Silver',
        component: AddGoldAndSilver,
    },
    {
        path: '/gold&silver/edit/:id',
        name: 'Edit Gold And Silver',
        component: EditGoldAndSilver,
    },
    {
        path: '/gold&silver/:id',
        name: 'Gold And Silver Details',
        component: GoldAndSilverDetails,
    },
    { path: '/category', name: 'Category', component: Category },
    { path: '/category/add', name: 'Add Category', component: AddCategory },
    {
        path: '/category/edit/:id',
        name: 'Edit Category',
        component: EditCategory,
    },
    {
        path: '/category/:id',
        name: 'Category Details',
        component: CategoryDetails,
    },
    { path: '/location', name: 'Location', component: Location },
    { path: '/news', name: 'News', component: News },
    { path: '/news/:id', name: 'News Details', component: NewsDetail },
    { path: '/videos', name: 'Videos', component: Videos },
    { path: '/fm&tvs', name: 'Fm And Tvs', component: FmAndTvs },
    { path: '/fm&tvs/add', name: 'Add Fm And Tvs', component: AddFmAndTvs },
    {
        path: '/fm&tvs/edit/:id',
        name: 'Edit Fm And Tvs',
        component: EditFmAndTvs,
    },

    { path: '/events', name: 'Events', component: Events },
    { path: '/events/add', name: 'Add Events', component: AddEvents },
    { path: '/events/edit/:id', name: 'Edit Events', component: EditEvents },
    { path: '/events/:id', name: 'Events Details', component: EventsDetails },
    { path: '/epaper', name: 'Epaper', component: Epaper },
    { path: '/epaper/add', name: 'Add Epaper', component: AddEpaper },
    { path: '/epaper/edit/:id', name: 'Edit Epaper', component: EditEpaper },
    { path: '/epaper/:id', name: 'Epaper Details', component: EpaperDetails },
    { path: '/role', name: 'Role', component: Role },
    { path: '/role/add', name: 'Add Role', component: AddRole },
    { path: '/role/edit/:id', name: 'Edit Role', component: EditRole },
    { path: '/role/:id', name: 'Role Details', component: EpaperDetails },
    { path: '/sectors', name: 'Sectors', component: Sectors },
    { path: '/nepse', name: 'Nepse Index', component: NepseIndex },
    {
        path: '/nepse-daily-closing-price',
        name: 'Nepse Daily Closing',
        component: ListNepseClosingPrice,
    },
    {
        path: '/nepse-daily-closing-price/import',
        name: 'Import',
        component: ImportNepseClosingPrice,
    },
    {
        path: '/nepseIndexValue',
        name: 'nepse-index-value',
        component: NepseIndexValue,
    },
    {
        path: '/nepseIndexValue/add',
        name: 'Add Nepse Index Value',
        component: AddNepseIndexValue,
    },
    {
        path: '/nepseIndexValue/edit/:id',
        name: 'Edit Nepse Value',
        component: EditNepseIndexValue,
    },
    {
        path: '/company',
        name: 'Company',
        component: Comapny,
    },
    {
        path: '/company/add',
        name: 'Add Company',
        component: AddCompany,
    },
    {
        path: '/company/edit/:id',
        name: 'Edit Company',
        component: EditCompany,
    },
    {
        path: '/registrar-to-shares',
        name: 'RTS',
        component: ListRTS,
    },
    {
        path: '/registrar-to-shares/add',
        name: 'Add RTS',
        component: AddRTS,
    },
    {
        path: '/registrar-to-shares/edit/:id',
        name: 'Edit RTS',
        component: EditRTS,
    },
    {
        path: '/ekadmin-news',
        name: 'News',
        component: News,
    },
    {
        path: '/channel-list',
        name: 'Channel List',
        component: ChannelList,
    },
]

export default routes

import './App.scss'
import './scssStyles/index.scss'
import 'react-loading-skeleton/dist/skeleton.css'
import 'react-toastify/dist/ReactToastify.css'
import React, { Suspense, useEffect } from 'react'
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary'
import PublicRoutes from 'components/Routing/PublicRoutes'
import Pusher from 'pusher-js'
import ScrollToTop from 'hooks/scrollToTop/ScrollToTop'
import useStore from 'store/store'
import Loading from 'utils/helpers/loading/Loading'
import { BrowserRouter as Router } from 'react-router-dom'
import { permissionRoutes } from 'global/permissionRoute'
import { queryClient } from 'utils/queryClient'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { ToastContainer } from 'react-toastify'

const App = () => {
    const { updateRouteList } = useStore((store) => store)
    const setBreakingNews = useStore((state) => state.setBreakingNews)
    useEffect(
        () => {
            updateRouteList(permissionRoutes)
        },
        // eslint-disable-next-line
        []
    )
    let pusher = new Pusher('04457a2201324f0e34eb', {
        cluster: 'ap2',
    })
    let channel = pusher.subscribe('Media-App')
    channel.bind('breaking-news-event', function (data) {
        setBreakingNews()
    })
    return (
        <div className="app-container">
            <ErrorBoundary>
                <Suspense fallback={<Loading spinner={true} visible={true} />}>
                    <QueryClientProvider client={queryClient}>
                        <ToastContainer
                            position="bottom-left"
                            autoClose={5000}
                            hideProgressBar={true}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            // REMINDER: Implement a global state for which theme it is, then utilize it to set the theme to either dark or light for the toaster component
                            theme="dark"
                        />
                        <Router>
                            <ScrollToTop />
                            <PublicRoutes />
                        </Router>
                        <ReactQueryDevtools initialIsOpen={false} />
                    </QueryClientProvider>
                </Suspense>
            </ErrorBoundary>
        </div>
    )
}
export default App
export const routePaths = {
    // First time
    language: '/',
    interestTopics: '/interested-topics',

    // After first time
    dashboard: '/',
    news: {
        base: '/news',
        moduleSubCategory: '/news?module={moduleName}',
        details: '/news/details?id={idNumber}',
        detailRouting: '/news/details',
        tag: '/news/tag?tag-name={tagName}',
        tagRouting: '/news/tag',
    },
   test:'/test',
    forex: '/forex',
    shareCalculator: '/share-calculator',
    shareMarket: '/share-market',
    todaySharePrice: '/today-share-price',
    calendar: '/calendar',
    dateConverter: '/date-converter',
    gold: '/gold',
    unicode: '/unicode',
    emiCalculator: '/emi-calculator',
    tvLists: '/tv',
    radio: '/radio',
    horoscope: {
        base: '/horoscope',
        details: '/horoscope/details?sign={horoscopeSign}',
        detailRouting: '/horoscope/details',
    },
    fm: '/fm',
    calculator: '/calculator',
    about: {
        base: '/about',
        eknepal: '/about/eknepal',
        mediaAllianceNepal: '/about/media-alliance-nepal',
    },

    privacy: {
        base: '/privacy',
    },
    mediahub: '/mediahub/:channelName',
    companyDetails: '/company-details',

    shareSubMenu: {
        companyListing: '/company-Listing',
        sectorlisting: '/sectorlisting',
        rtslisting: '/rtslisting',
        todayPrice: '/today-price',
        Indices: '/indices',
    },

    admin: {
        // public routes
        auth: {
            forgotPassword: '/forgot-password',
            login: '/ekadmin/login',
            resetPassword: '/reset-password',
            resetPasswordSuccess: '/reset-password-success',
        },

        home: {
            base: '/ekadmin',
            advertisement: {
                base: '/advertisement',
                add: '/advertisement/add',
                edit: '/advertisement/edit/{id}',
                detail: '/advertisement/{id}',
            },
            user: {
                base: '/user',
                add: '/user/add',
                edit: '/user/edit/{id}',
                detail: '/user/{id}',
            },
            news: {
                base: '/news',
                detail: '/news/details?id={id}',
            },
            goldSilver: {
                base: '/gold&silver',
                add: '/gold&silver/add',
                edit: '/gold&silver/edit/{id}',
                detail: '/gold&silver/{id}',
            },
            category: {
                base: '/category',
                add: '/category/add',
                edit: '/category/edit/{id}',
                detail: '/category/{id}',
            },
            fmandtvs: {
                base: '/fm&tvs',
                add: '/fm&tvs/add',
                edit: '/fm&tvs/edit/{id}',
                detail: '/fm&tvs/{id}',
            },
            events: {
                base: '/events',
                add: '/events/add',
                edit: '/events/edit/{id}',
                detail: '/events/{id}',
            },
            epaper: {
                base: '/epaper',
                add: '/epaper/add',
                edit: '/epaper/edit/{id}',
                detail: '/epaper/{id}',
            },
            role: {
                base: '/role',
                add: '/role/add',
                edit: '/role/edit/{id}',
                detail: '/role/{id}',
            },
            sectors: {
                base: '/sectors',
            },
            nepseIndex: {
                base: '/nepse',
            },
            nepseValue: {
                base: '/nepseIndexValue',
                add: '/nepseIndexValue/add',
                edit: '/nepseIndexValue/edit/{id}',
            },
            company: {
                base: '/company',
                add: '/company/add',
                edit: '/company/edit/{id}',
                detail: '/company/{id}',
            },
            nepseDailyClosingPrice: {
                base: '/nepse-daily-closing-price',
                import: '/nepse-daily-closing-price/import',
                delete: '/nepse-daily-closing-price/delete/{date}',
            },
            rts: {
                base: '/registrar-to-shares',
                get: '/registrar-to-shares/{id}',
                add: '/registrar-to-shares/add',
                edit: '/registrar-to-shares/edit/{id}',
                delete: '/registrar-to-shares/{id}',
            },
            location: '/location',
            videos: '/videos',
            images: {
                base: '/images',
                add: '/images/add',
            },
        },
    },
}

/* eslint-disable no-unused-vars */
import React from 'react'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import LabelInput  from 'components/Layout/LabelInput/LabelInput'
import './resetPassword.scss'
import { useFormik } from 'formik'
import { object, string, ref } from 'yup'
import Button  from 'components/Layout/Button/Button'
import { PropTypes } from 'prop-types'
import classNames from 'classnames'
import { authRoutes } from 'global/constants/appConstants'
import Spinner  from 'components/Spinner/Spinner'
import usePostResetPassword from 'hooks/userAuth/usePostResetPassword'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import useStore from 'store/store'

const ResetPassword = ({ show, setCurrentPage }) => {
    const { t } = useTranslation()

    const forgotEmail = useStore((store) => store.forgotEmail)

    const [showPassword, setShowPassword] = useState({
        pwd: false,
        confirmPwd: false,
    })

    const {
        mutate: resetPasswordFn,
        isLoading: resetPasswordLoading,
        isSuccess: resetPasswordSuccess,
    } = usePostResetPassword()

    const schema = object().shape({
        password: string()
            .required(t('errors.fieldRequired'))
            .test(
                'len',
                t('errors.mustBe620Long'),
                (val) => val?.trim()?.length >= 6 && val?.trim()?.length <= 20
            ),
        confirmPassword: string()
            .required(t('errors.fieldRequired'))
            .oneOf([ref('password'), null], t('errors.mustMatch')),
    })

    const handleSubmit = (e) => {
        e.preventDefault()
        if (isValid) {
            const postData = {
                email: forgotEmail,
                password: values.password,
                password_confirmation: values.confirmPassword,
            }
            resetPasswordFn(postData)
        }
    }

    useEffect(() => {
        if (resetPasswordSuccess) {
            setCurrentPage(authRoutes.SIGNIN)
        }

        // eslint-disable-next-line
    }, [resetPasswordSuccess])

    const {
        handleBlur,
        values,
        setFieldValue,
        errors,
        isValid,
        touched,
        setErrors,
    } = useFormik({
        validationSchema: schema,
        onSubmit: handleSubmit,
        initialValues: {
            password: '',
            confirmPassword: '',
        },
        initialErrors: {
            password: true,
            confirmPassword: true,
        },
        enableReinitialize: true,
    })

    const handleSignInRedirect = (e) => {
        e.preventDefault()
        setCurrentPage(authRoutes.SIGNIN)
    }

    useEffect(() => {
        setErrors({})

        // eslint-disable-next-line
    }, [show])

    return (
        <div
            className={classNames('password-reset-container', {
                show: show,
            })}
        >
            <form onSubmit={handleSubmit} className="password-reset-form">
                <LabelInput
                    labelDisplay="none"
                    inputName="password"
                    inputType={!showPassword.pwd ? 'password' : 'text'}
                    value={values.password}
                    touchedError={errors.password}
                    handleOnChange={(e) =>
                        setFieldValue('password', e.target.value)
                    }
                    touched={touched.password}
                    handleOnBlur={handleBlur}
                    orientation="vertical"
                    size="small"
                    inputPlaceholder={t('authentication.password')}
                    icon={
                        showPassword.pwd ? (
                            <AiFillEye />
                        ) : (
                            <AiFillEyeInvisible />
                        )
                    }
                    iconOnClick={() =>
                        setShowPassword((prevState) => ({
                            ...prevState,
                            pwd: !prevState.pwd,
                        }))
                    }
                />
                <LabelInput
                    labelDisplay="none"
                    inputName="confirmPassword"
                    value={values.confirmPassword}
                    inputType={!showPassword.pwd ? 'password' : 'text'}
                    touchedError={errors.confirmPassword}
                    handleOnChange={(e) =>
                        setFieldValue('confirmPassword', e.target.value)
                    }
                    touched={touched.confirmPassword}
                    handleOnBlur={handleBlur}
                    orientation="vertical"
                    size="small"
                    inputPlaceholder={t('authentication.confirmPassword')}
                    icon={
                        showPassword.confirmPwd ? (
                            <AiFillEye />
                        ) : (
                            <AiFillEyeInvisible />
                        )
                    }
                    iconOnClick={() =>
                        setShowPassword((prevState) => ({
                            ...prevState,
                            confirmPwd: !prevState.confirmPwd,
                        }))
                    }
                />
                {resetPasswordLoading ? (
                    <Button
                        size="widthHundred"
                        bgColor="grey"
                        description={<Spinner isWhite={true} />}
                    />
                ) : (
                    <Button
                        size="widthHundred"
                        bgColor="grey"
                        description={t('buttons.continue')}
                        disabled={!isValid}
                    />
                )}
            </form>
            <div className="password-reset-bottom-container">
                <div className="password-reset-or-divider">
                    {t('authentication.signInSection.or')}
                </div>
                <div className="password-reset-bottom-buttons-container">
                    <Button
                        disabled={!show}
                        size="widthHundred"
                        bgColor="grey"
                        description={t('authentication.goBackSignIn')}
                        handleButtonClick={handleSignInRedirect}
                    />
                </div>
            </div>
        </div>
    )
}

export default ResetPassword

ResetPassword.propTypes = {
    show: PropTypes.bool,
    setCurrentPage: PropTypes.func,
}

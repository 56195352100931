import './title.scss'
import { useEffect, useState } from 'react'

import { NavLink } from 'react-router-dom'
import { PropTypes } from 'prop-types'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { useFetchCompany } from 'hooks/company/useFetchCompany'
import { sortingOrder } from 'global/constants/adminConstants'
import { useHistory } from 'react-router'

const Title = ({ title, urlLink, bgColor, desc, SelectDropdown }) => {
    const { t } = useTranslation()
    const navigate = useHistory()

    const [companyOption, setCompanyOptions] = useState([])
    const filterSchema = {
        orderBy: 'company_name',
        page_size: 800,
        order: sortingOrder.ASCENDING,
    }
    const { data: companyData } = useFetchCompany(filterSchema)

    useEffect(() => {
        const newCompanyData = []
        if (companyData) {
            companyData?.payload?.map((data) => {
                return newCompanyData.push({
                    label: data.company_name,
                    value: data.symbol,
                })
            })
        }
        setCompanyOptions({ newCompanyData })
    }, [companyData])

    const handleChange = (data) => {
        navigate.push(`/company-details?companyName=${data.value}`)
    }

    return (
        <section
            className={classNames(
                `title-section ${
                    SelectDropdown ? 'title_section-select' : ''
                } `,
                {
                    'width-125': bgColor === 'grey',
                }
            )}
        >
            {SelectDropdown ? (
                <div className="select--container  ">
                    <header
                        className={classNames('title-header', {
                            'bg-grey': bgColor === 'grey',
                            desc: desc,
                        })}
                        style={{ paddingLeft: '1rem' }}
                    >
                        <h2 className="title-title ">
                            {title ? title : 'No title'}
                        </h2>
                        <p className="title-desc ">{desc ? desc : ''}</p>
                    </header>

                    <Select
                        options={companyOption.newCompanyData}
                        className="selectDropdown"
                        onChange={(e) => handleChange(e)}
                    />
                </div>
            ) : (
                <header
                    className={classNames('title-header', {
                        'bg-grey': bgColor === 'grey',
                        desc: desc,
                    })}
                >
                    <h2 className="title-title">
                        {title ? title : 'No title'}
                    </h2>
                    <p className="title-desc">{desc ? desc : ''}</p>
                    {urlLink && (
                        <NavLink to={urlLink} className="title-see-all-tag">
                            {t('seeAll')}
                        </NavLink>
                    )}
                </header>
            )}
        </section>
    )
}

export default Title

Title.propTypes = {
    title: PropTypes.string.isRequired,
    urlLink: PropTypes.string,
    bgColor: PropTypes.string,
    desc: PropTypes.string,
}

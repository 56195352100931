import { useState } from 'react'

export const GetLatLong = () => {
    const [lat, setLat] = useState()
    const [long, setLong] = useState()

    if (!navigator.geolocation) {
        return 'Geolocation is not supported by your browser'
    } else {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                setLat(position.coords.latitude)
                setLong(position.coords.longitude)
            },
            () => {
                return 'Unable to retrieve your location'
            }
        )
    }

    if (lat && long) {
        return { latitude: lat, longitude: long }
    } else {
        return {
            latitude: '',
            longitude: '',
        }
    }
}

import { endpoints } from 'global/endpoints';
import http from 'utils/http';
import { toast } from 'react-toastify';
import { useMutation } from 'react-query';

const postUserRegistration = (postData) => {
    if (postData) {
        const updatedEndpoints = endpoints.auth.users.registration
        return http().post(updatedEndpoints, postData)
    }
}

export default function usePostUserRegistration() {
    return useMutation(postUserRegistration, {
        onSuccess: () => {
            toast.success('You have registered successfully!')
        },
        onError: (err) => {
            Object.keys(err.errors).map((item, index) =>
                toast.error(err.errors[item][0])
            )
        },
    })
}

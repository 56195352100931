import { useQuery } from 'react-query'

import { endpoints } from 'global/endpoints'
import http from 'utils/http'

const fetchNewsBasedOnTag = (tag, paginateNumber) => {
    const updatedEndpoints = endpoints.news.tag.paginate
        .replace('{tagName}', tag)
        .replace('{paginateNumber}', paginateNumber)
    return http().get(updatedEndpoints)
}

export function useFetchNewsBasedOnTag(tag, paginateNumber) {
    return useQuery(
        ['fetchNewsBasedOnTag', tag, paginateNumber],
        () => fetchNewsBasedOnTag(tag, paginateNumber),
        {
            retry: 3,
        }
    )
}

import './navbarHamburgerMenu.scss'
import classNames from 'classnames'
import NavItem  from 'components/Layout/Navigation/NavItem/NavItem'
import { AiOutlineClose } from 'react-icons/ai'
import { useTranslation } from 'react-i18next'
import { lang } from 'global/constants/appConstants'
import { activeNepaliImg, activeEnglishImg } from 'assets'
import useStore from 'store/store'

const NavbarHamburgerMenu = ({ open, payload, hamburgerRef, setOpen }) => {
    const { i18n, t } = useTranslation()

    const toggleCurrentLanguage = useStore(
        (store) => store.toggleCurrentLanguage
    )
    // This is for changing the language into English or Nepali, depending upon the situation
    function handleChangeLanguage(e) {
        const currentLang = i18n.language

        if (currentLang === lang.ENGLISH) {
            i18n.changeLanguage(lang.NEPALI)
            toggleCurrentLanguage(lang.NEPALI)
        } else {
            i18n.changeLanguage(lang.ENGLISH)
            toggleCurrentLanguage(lang.ENGLISH)
        }
    }
    return (
        <div
            className={classNames('navbar-hamburger-menu-container', {
                open: open,
            })}
            open={open}
            ref={hamburgerRef}
        >
            <div className="navbar-hamburger-menu-close-button-container">
                <AiOutlineClose
                    className="navbar-hamburger-menu-close-button"
                    onClick={() => setOpen(false)}
                />
            </div>
            <div className="navbar-hamburger-menu-items-container">
                {Array.isArray(payload) &&
                    payload.length > 0 &&
                    payload.map((item, index) => (
                        <NavItem
                            key={index}
                            item={item.item}
                            type="text"
                            urlLink={item?.urlLink ? item.urlLink : null}
                            icon={item?.icon ? item.icon : null}
                            handleOnClick={() => setOpen(false)}
                        />
                    ))}
            </div>
            <div
                className="navbar-hamburger-menu-language-change-option"
                onClick={handleChangeLanguage}
            >
                <img
                    src={
                        i18n.language === lang.ENGLISH
                            ? activeEnglishImg
                            : activeNepaliImg
                    }
                    alt=""
                    className="navbar-language-icon"
                />
                <p>{t('changeLang')}</p>
            </div>

            {/* REMINDER: Add more things here if necessary */}
        </div>
    )
}

export default NavbarHamburgerMenu

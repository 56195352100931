/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { facebookImg } from 'assets'
import Button from '../Button/Button'
import { useTranslation } from 'react-i18next'
import usePostSocialLogin from 'hooks/userAuth/usePostSocialLogin'

const FacebookAuthentication = () => {
    const [name, setName] = useState()
    const [accessToken, setAccessToken] = useState()
    const [provider, setProvider] = useState()
    const [providerId, setProviderId] = useState()
    const [picture, setPicture] = useState()

    const { t } = useTranslation()

    const {
        mutate: socialAuthFn,
        data: socialAuthData,
        isSuccess: socialAuthSuccess,
    } = usePostSocialLogin()

    const APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID || ''

    const responseFacebook = (response) => {
        setName(response?.name)
        setAccessToken(response?.accessToken)
        setProvider(response?.graphDomain)
        setProviderId(response?.id)
        setPicture(response?.picture?.data?.url)
    }

    useEffect(() => {
        if (name && accessToken && provider && providerId) {
            const postData = {
                name: name,
                access_token: accessToken,
                provider: provider,
                provider_id: providerId,
            }
            socialAuthFn(postData)
        }

        // eslint-disable-next-line
    }, [name, accessToken, provider, providerId, picture])

    return (
        <FacebookLogin
            appId={APP_ID}
            autoLoad={false}
            fields="name,email,picture"
            callback={responseFacebook}
            render={(renderProps) => (
                <Button
                    handleButtonClick={renderProps.onClick}
                    description={t(
                        'authentication.signInSection.continueWithFaceBook'
                    )}
                    size="widthHundred"
                    additionalStyles="white-border-grey"
                    img={facebookImg}
                    imgAbsolute={true}
                />
            )}
        />
    )
}

export default FacebookAuthentication

import * as storage from 'utils/storage';

import {
  detailConst,
  tokenConst,
} from 'global/constants/appConstants';

import { endpoints } from 'global/endpoints';
import http from 'utils/http';
import { toast } from 'react-toastify';
import { useMutation } from 'react-query';

const postUserLogout = (postData) => {
    const updatedEndpoints = endpoints.auth.logout
    return http().post(updatedEndpoints, postData)
}

export default function usePostUserLogout() {
    return useMutation(postUserLogout, {
        onSuccess: () => {
            storage.remove(detailConst.USER_DETAILS)
            storage.remove(tokenConst.USER_UNIQUE_TOKEN)
            toast.success('Logged out successfully!')
        },
        onError: (err) => {
            toast.error('There was a problem. Please try again!')
        },
    })
}

import { endpoints } from 'global/endpoints';
import http from 'utils/http';
import { toast } from 'react-toastify';
import { useMutation } from 'react-query';

const postResendOtp = (postData) => {
    if (postData) {
        const updatedEndpoints = endpoints.auth.users.resendOtp
        return http().post(updatedEndpoints, postData)
    }
}

export default function usePostResendOtp() {
    return useMutation(postResendOtp, {
        onSuccess: () => {
            toast.success('OTP has been resent!')
        },
        onError: (err) => {
            toast.error('There was a problem. Please try again!')
        },
    })
}

import { endpoints } from 'global/endpoints';
import http from 'utils/http';
import { toast } from 'react-toastify';
import { useMutation } from 'react-query';

const postVerifyForgotPasswordOtp = (postData) => {
    if (postData) {
        const updatedEndpoints = endpoints.auth.forgotPasswordOtp
        return http().post(updatedEndpoints, postData)
    }
}

export default function usePostVerifyForgotPasswordOtp() {
    return useMutation(postVerifyForgotPasswordOtp, {
        onSuccess: () => {
            toast.success('Email has been verified!')
        },
        onError: (err) => {
            toast.error('Credentials mismatch. Please try again.')
        },
        
    })
}

import { endpoints } from 'global/endpoints';
import http from 'utils/http';

export const advertisementSlice = (set, get) => ({
    advertisement: '',
    addAdvertisement: (data) => {
        set({ advertisement: {...data} })

    },
})

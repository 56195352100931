/* eslint-disable no-unused-vars */
import './navbar.scss'

import { useState, useEffect, useRef } from 'react'

import { CgProfile } from 'react-icons/cg'
import { BiMenu, BiSearch } from 'react-icons/bi'
import { AiFillHome } from 'react-icons/ai'

import {
    logoImg,
    locationTagImg,
    weatherTagImg,
    degreeTagImg,
    activeEnglishImg,
    activeNepaliImg,
} from 'assets'

import NavItem from '../NavItem/NavItem'
import Weather  from 'components/Layout/Weather/Weather'
import useStore from 'store/store'
import classNames from 'classnames'
import useMediaQuery from 'hooks/mediaQuery/useMediaQuery'
import NavbarHamburgerMenu from '../NavbarHamburgerMenu/NavbarHamburgerMenu'
import Authentication from 'pages/Authentication/Authentication'
import useGetDateBasedOnCurrentLang from 'hooks/time/useGetDateBasedOnCurrentLang'
import convertToTwelve from 'utils/convertToTwelve'

import { useTranslation } from 'react-i18next'
import { lang } from 'global/constants/appConstants'
import { NavLink, Link, useLocation } from 'react-router-dom'
import { ClickOutside } from 'utils/helpers/clickOutside/ClickOutside'
import { drawerDummyData } from 'global/constants/dummyData'
import { getCurrentNepaliTime } from 'utils/getCurrentNepaliTime'
import { EnglishToNepaliConverter } from 'utils/helpers/englishToNepaliConverter/EnglishToNepaliConverter'
import { GetLatLong } from 'utils/GetLatLong'

const NAV_ITEM_WIDTH = 150

const NavBar = ({ dummyData }) => {
    const [totalNavItems, setTotalNavItems] = useState(9)
    const [toggleNav, setToggleNav] = useState(false)
    const [scrolledNavBar, setScrolledNavBar] = useState(false)
    const [currentDate, setCurrentDate] = useState(new Date())
    const [drawerOpened, setDrawerOpened] = useState(false)

    const navBarMenuRef = useRef(null)
    const refAuth = useRef()
    const hamburgerRef = useRef(null)

    const { i18n } = useTranslation()

    const isNavbar = useMediaQuery('(min-width: 768px)')

    const toggleCurrentLanguage = useStore(
        (store) => store.toggleCurrentLanguage
    )

    const { t } = useTranslation()

    const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    }

    const { day, weekday, month, year } = useGetDateBasedOnCurrentLang(
        currentDate,
        options
    )

    // Use location hook to get current Url
    const location = useLocation()

    useEffect(() => {
        setToggleNav(false)
    }, [isNavbar])

    ClickOutside(hamburgerRef, () => {
        setDrawerOpened(false)
    })

    const handleProfileClick = (e) => {
        refAuth.current.open()
    }

    // This is for the proper reassigning of the values in the navbar when resized
    useEffect(() => {
        if (navBarMenuRef.current) {
            const navBarMenuWidth =
                navBarMenuRef.current.getBoundingClientRect().width

            setTotalNavItems(navBarMenuWidth / NAV_ITEM_WIDTH)
        }

        const resizeListener = () => {
            if (navBarMenuRef.current) {
                const navBarMenuWidth =
                    navBarMenuRef.current.getBoundingClientRect().width

                setTotalNavItems(navBarMenuWidth / NAV_ITEM_WIDTH)
            }
        }

        window.addEventListener('resize', resizeListener)

        return () => {
            window.removeEventListener('resize', resizeListener)
        }
    }, [])

    useEffect(() => {
        // This is for the changing navbar when scrolled
        const changeBackground = () => {
            const windowScrollYValue = window.scrollY
            if (windowScrollYValue >= 92) {
                setScrolledNavBar(true)
            } else {
                setScrolledNavBar(false)
            }
        }
        changeBackground()
        window.addEventListener('scroll', changeBackground)
    }, [])

    // This is for changing the language into English or Nepali, depending upon the situation
    function handleChangeLanguage(e) {
        const currentLang = i18n.language

        if (currentLang === lang.ENGLISH) {
            i18n.changeLanguage(lang.NEPALI)
            toggleCurrentLanguage(lang.NEPALI)
        } else {
            i18n.changeLanguage(lang.ENGLISH)
            toggleCurrentLanguage(lang.ENGLISH)
        }
    }

    const { latitude, longitude } = GetLatLong()

    return (
        <>
            <Authentication authRef={refAuth} />
            <nav
                className={classNames('navbar-container', {
                    scrolled: scrolledNavBar,
                })}
            >
                <NavbarHamburgerMenu
                    open={drawerOpened}
                    payload={drawerDummyData}
                    hamburgerRef={hamburgerRef}
                    setOpen={setDrawerOpened}
                />

                <header className="navbar-top">
                    <NavLink to="/" className="navbar-logo-container">
                        <img
                            src={logoImg}
                            alt="Media Alliance Nepal"
                            className="navbar-logo"
                        />
                        <span className="navbar-logo-attached-information">
                            <div className="navbar-today-date">
                                {`${
                                    i18n.language === lang.NEPALI
                                        ? EnglishToNepaliConverter(
                                              getCurrentNepaliTime(
                                                  new Date(),
                                                  'Asia/Katmandu'
                                              )
                                                  .split(',')[1]
                                                  .trim()
                                          )
                                        : convertToTwelve(
                                              getCurrentNepaliTime(
                                                  new Date(),
                                                  'Asia/Katmandu'
                                              )
                                                  .split(',')[1]
                                                  .trim()
                                          )
                                } ${weekday}, ${day} ${month} ${year} `}
                                <p className="navbar-today-date"></p>
                            </div>
                            <div className="navbar-location-weather">
                                {latitude && longitude && (
                                    <Weather lat={latitude} long={longitude} />
                                )}
                            </div>
                        </span>
                    </NavLink>
                    <div className="navbar-settings">
                        <div
                            className="navbar-language-container"
                            onClick={handleChangeLanguage}
                        >
                            <img
                                src={
                                    i18n.language === lang.ENGLISH
                                        ? activeEnglishImg
                                        : activeNepaliImg
                                }
                                alt=""
                                className="navbar-language-icon"
                            />
                        </div>
                        <div className="navbar-profile-container">
                            <CgProfile
                                className="navbar-profile-icon"
                                onClick={handleProfileClick}
                            />
                        </div>
                        <div className="navbar-hamburger-menu-icon-container">
                            <BiMenu
                                className="navbar-hamburger-menu-icon"
                                onClick={() => setDrawerOpened(!drawerOpened)}
                            />
                        </div>
                    </div>
                </header>
                {/* This section checks whether the totalNavItems (from the ref-width calculator function above) is applicable to the array data's length. If it is, render it or else slice it and display the ones that will fit in the given width */}
                <div
                    className={classNames('navbar-menu-container', {
                        inNews: location.pathname === '/news',
                    })}
                >
                    <div
                        className={classNames('navbar-menu', {
                            'is-opened': toggleNav,
                            inNews: location.pathname === '/news',
                        })}
                        ref={navBarMenuRef}
                    >
                        {scrolledNavBar && (
                            <div
                                className="navbar-menu-logo-scrolled"
                                title={`${
                                    i18n.language === lang.NEPALI
                                        ? EnglishToNepaliConverter(
                                              getCurrentNepaliTime(
                                                  new Date(),
                                                  'Asia/Katmandu'
                                              )
                                                  .split(',')[1]
                                                  .trim()
                                          )
                                        : convertToTwelve(
                                              getCurrentNepaliTime(
                                                  new Date(),
                                                  'Asia/Katmandu'
                                              )
                                                  .split(',')[1]
                                                  .trim()
                                          )
                                } ${weekday}, ${day} ${month} ${year} `}
                            >
                                <Link
                                    to="/"
                                    className="navbar-menu-logo-icon-link"
                                >
                                    <figure className="navbar-menu-logo-figure">
                                        <img
                                            src={logoImg}
                                            alt="Media Alliance Nepal"
                                        />
                                    </figure>
                                </Link>
                            </div>
                        )}
                        {dummyData ? (
                            dummyData.length <= totalNavItems ? (
                                dummyData.map((data, index) => (
                                    <NavItem
                                        key={index}
                                        item={data.item}
                                        type="text"
                                        urlLink={
                                            data?.urlLink ? data.urlLink : null
                                        }
                                    />
                                ))
                            ) : (
                                <>
                                    {dummyData
                                        .slice(0, totalNavItems)
                                        .map((data, index) => (
                                            <NavItem
                                                key={index}
                                                item={data.item}
                                                type="text"
                                                urlLink={
                                                    data?.urlLink
                                                        ? data.urlLink
                                                        : null
                                                }
                                            />
                                        ))}
                                    <NavItem
                                        item={t('navBarOthers')}
                                        type="hasSubMenu"
                                    >
                                        {dummyData
                                            .slice(
                                                totalNavItems,
                                                dummyData.length
                                            )
                                            .map((data, index) => (
                                                <NavItem
                                                    key={index}
                                                    item={data.item}
                                                    type={'text'}
                                                    urlLink={
                                                        data?.urlLink
                                                            ? data.urlLink
                                                            : null
                                                    }
                                                />
                                            ))}
                                    </NavItem>
                                </>
                            )
                        ) : null}
                    </div>
                </div>
            </nav>
        </>
    )
}

export default NavBar

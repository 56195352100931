import React, { useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { routePaths } from 'global/constants/routePaths'
import tokenService from 'utils/token'
import { PropTypes } from 'prop-types'
import useStore from 'store/store'
import Spinner from 'components/Spinner/Spinner'
// import { useSelector } from 'react-redux'

/**
 * If the user is authenticated then the user cannot access routes related to authentication.
 * If the use is not authenticated then users cannot access routesother than the login (authentication) routes
 * Store the Bearer tokens in the session storage for added security
 */

const PrivateRoute = ({ component: Component, exact, path }) => {
    const { routeList } = useStore((store) => store)
    const routes = routeList.map((data) => data.name)

    let modifiedPath = path.replace('/:id', '')
    return (
        <Route
            path={path}
            exact={exact}
            render={(props) => {
                if (!tokenService.getAccessToken()) {
                    return <Redirect to={routePaths.admin.auth.login} />
                }
                // else if (!routes.includes(modifiedPath)) {
                //   return <Redirect to={routePaths.admin.home.base} />
                // }
                return (
                    <React.Suspense fallback={<Spinner />}>
                        <Component {...props} />
                    </React.Suspense>
                )
            }}
        />
    )
}

export default PrivateRoute

PrivateRoute.propTypes = {
    component: PropTypes.elementType,
    exact: PropTypes.bool,
    path: PropTypes.string,
}

import './button.scss'
import classNames from 'classnames'
import { PropTypes } from 'prop-types'

const Button = ({
    description,
    handleButtonClick,
    bgColor,
    size,
    color,
    disabled,
    icon,
    center,
    additionalStyles,
    img,
    imgAbsolute,
}) => {
    return (
        <div className="button-container">
            <button
                disabled={disabled}
                type="submit"
                className={classNames('button-submit', {
                    small: size === 'small',
                    medium: size === 'medium',
                    'extra-small': size === 'extra-small',
                    large: size === 'large',
                    red: bgColor === 'red',
                    grey: bgColor === 'grey',
                    blue: bgColor === 'blue',
                    'text-color-black': color === 'black',
                    disabled: disabled,
                    'width-hundred': size === 'widthHundred',
                    center: center === true,
                    'white-border-grey':
                        additionalStyles === 'white-border-grey',
                })}
                onClick={handleButtonClick}
            >
                {img && (
                    <figure
                        className={classNames('button-image-figure', {
                            absolute: imgAbsolute,
                        })}
                    >
                        <img src={img} alt="" />
                    </figure>
                )}
                <span className="button-description">{description}</span>
                {icon ? icon : null}
            </button>
        </div>
    )
}

export default Button

Button.PropType = {
    description: PropTypes.string,
    handleButtonClick: PropTypes.func,
    bgColor: PropTypes.string,
    size: PropTypes.string,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    icon: PropTypes.element,
    center: PropTypes.bool,
    additionalStyles: PropTypes.string,
    img: PropTypes.string,
    imgAbsolute: PropTypes.bool,
}

import { endpoints } from 'global/endpoints'
import { useQuery } from 'react-query'
import http from 'utils/http'

const fetchWeatherLocation = (lat, long) => {
    return http().get(
        endpoints.weather.latAndLong
            .replace('{lattitudeNum}', lat)
            .replace('{longitudeNum}', long)
    )
}

export default function useFetchWeatherLocation(lat, long) {
    return useQuery(
        ['fetchWeatherLocation'],
        () => fetchWeatherLocation(lat, long),
        {
            retry: 3,
        }
    )
}

/* eslint-disable no-unused-vars */
import GoogleLogin from 'react-google-login'
import Button from '../Button/Button'
import { useTranslation } from 'react-i18next'
import { googleImg } from 'assets'
import { useState, useEffect } from 'react'
import usePostSocialLogin from 'hooks/userAuth/usePostSocialLogin'
import { toast } from 'react-toastify'
import { PropTypes } from 'prop-types'

const GoogleAuthentication = ({ setCurrentPage, authRef }) => {
    const [name, setName] = useState()
    const [accessToken, setAccessToken] = useState()
    const [provider, setProvider] = useState()
    const [providerId, setProviderId] = useState()
    const [picture, setPicture] = useState()

    const { t } = useTranslation()

    const {
        mutate: socialAuthFn,
        data: socialAuthData,
        isSuccess: socialAuthSuccess,
    } = usePostSocialLogin()

    const APP_ID = process.env.REACT_APP_GOOGLE_APP_ID || ''

    const handleGoogleLoginSuccess = (response) => {
        setAccessToken(response?.tokenId)
        setProvider('google')
        setProviderId(response?.googleId)
        setName(
            `${response?.profileObj?.givenName} ${response?.profileObj?.familyName}`
        )
    }

    const handleGoogleLoginError = (response) => {
        console.error(response)
    }

    useEffect(() => {
        if (name && accessToken && provider && providerId) {
            const postData = {
                name: name,
                access_token: accessToken,
                provider: provider,
                provider_id: providerId,
            }
            socialAuthFn(postData)
        }

        // eslint-disable-next-line
    }, [name, accessToken, provider, providerId, picture])

    return (
        <GoogleLogin
            clientId={APP_ID}
            render={(renderProps) => (
                <Button
                    size="widthHundred"
                    description={t(
                        'authentication.signInSection.continueWithGoogle'
                    )}
                    additionalStyles="white-border-grey"
                    img={googleImg}
                    imgAbsolute={true}
                    handleButtonClick={renderProps.onClick}
                />
            )}
            onSuccess={handleGoogleLoginSuccess}
            onFailure={handleGoogleLoginError}
            cookiePolicy={'single_host_origin'}
        />
    )
}

export default GoogleAuthentication

GoogleAuthentication.propTypes = {
    setCurrentPage: PropTypes.func,
    authRef: PropTypes.object,
}

import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import NepaliDate from 'nepali-date-converter'
import { EnglishToNepaliConverter } from 'utils/helpers/englishToNepaliConverter/EnglishToNepaliConverter'
import { lang } from 'global/constants/appConstants'

import { PropTypes } from 'prop-types'
import convertToTwelve from 'utils/convertToTwelve'
import { nepaliMonths, englishToNepaliDays } from 'global/constants/dummyData'

const useGetDateBasedOnCurrentLang = (date, options, isDate = false) => {
    const { i18n } = useTranslation()

    const [finalDate, setFinalDate] = useState({
        year: '',
        month: '',
        day: '',
        weekday: '',
        time: '',
    })

    const [lastUpdatedDate, setLastUpdatedDate] = useState(
        isDate ? date : new Date(date)
    )

    const [nepaliDate, setNepaliDate] = useState()

    useEffect(() => {
        try {
            if (lastUpdatedDate) {
                setNepaliDate(new NepaliDate(new Date(lastUpdatedDate)).getBS())
            }
        } catch (err) {
            // do nothing
        }
    }, [lastUpdatedDate])

    useEffect(() => {
        setLastUpdatedDate(new Date(date))
    }, [date])

    useEffect(() => {
        try {
            if (i18n.language === lang.ENGLISH) {
                setFinalDate((prevState) => ({
                    ...prevState,
                    year: lastUpdatedDate.toLocaleDateString('default', {
                        year: options?.year,
                    }),
                    month: lastUpdatedDate.toLocaleDateString('default', {
                        month: options?.month,
                    }),
                    day: lastUpdatedDate.toLocaleDateString('default', {
                        day: options?.day,
                    }),
                    weekday: lastUpdatedDate.toLocaleDateString('default', {
                        weekday: options?.weekday,
                    }),
                    time: convertToTwelve(
                        `${lastUpdatedDate.getHours()}:${lastUpdatedDate.getMinutes()}`,
                        i18n.language
                    ),
                }))
            } else {
                setFinalDate((prevState) => ({
                    ...prevState,
                    year: EnglishToNepaliConverter(nepaliDate?.year),
                    month: nepaliMonths[nepaliDate?.month],
                    day: EnglishToNepaliConverter(nepaliDate?.date),
                    weekday:
                        englishToNepaliDays[
                            lastUpdatedDate
                                .toLocaleDateString('default', {
                                    weekday: options?.weekday,
                                })
                                .toLowerCase()
                        ],
                    time: EnglishToNepaliConverter(
                        `${lastUpdatedDate.getHours()}:${lastUpdatedDate.getMinutes()}`
                    ),
                }))
            }
        } catch (err) {
            // do nothing
        }
        // eslint-disable-next-line
    }, [i18n.language, lastUpdatedDate, nepaliDate])

    return {
        year: finalDate.year,
        month: finalDate.month,
        day: finalDate.day,
        weekday: finalDate.weekday,
        time: finalDate.time,
    }
}

export default useGetDateBasedOnCurrentLang

useGetDateBasedOnCurrentLang.propTypes = {
    date: PropTypes.string,
    options: PropTypes.object,
}

import intervalToDuration from 'date-fns/intervalToDuration'
import { EnglishToNepaliConverter } from './helpers/englishToNepaliConverter/EnglishToNepaliConverter'
import nepaliDateFormatter from './helpers/nepaliDateFormatter/nepaliDateFormatter'
import { lang } from 'global/constants/appConstants'
import i18n from 'i18n'

export default function ConvertToRelativeTime(updatedDate) {
    // Use the direct i18n import as useTranslation will result in rendering more hooks than previous render error.

    let currentDate = new Date()

    let finalDate = intervalToDuration({ start: updatedDate, end: currentDate })
    let finalDateAttachment = ''

    if (finalDate.years !== 0) {
        finalDate = finalDate.years
        finalDate > 1
            ? (finalDateAttachment = 'years ago')
            : (finalDateAttachment = 'year ago')
    } else if (finalDate.months !== 0) {
        finalDate = finalDate.months
        finalDate > 1
            ? (finalDateAttachment = 'months ago')
            : (finalDateAttachment = 'month ago')
    } else if (finalDate.days !== 0) {
        finalDate = finalDate.days
        finalDate > 1
            ? (finalDateAttachment = 'days ago')
            : (finalDateAttachment = 'day ago')
    } else if (finalDate.hours !== 0) {
        finalDate = finalDate.hours
        finalDate > 1
            ? (finalDateAttachment = 'hours ago')
            : (finalDateAttachment = 'hour ago')
    } else if (finalDate.minutes !== 0) {
        finalDate = finalDate.minutes
        finalDate > 1
            ? (finalDateAttachment = 'minutes ago')
            : (finalDateAttachment = 'minute ago')
    } else {
        finalDate = finalDate.seconds
        finalDate > 1
            ? (finalDateAttachment = 'seconds ago')
            : (finalDateAttachment = 'second ago')
    }

    if (i18n.language === lang.ENGLISH)
        return `${finalDate} ${finalDateAttachment}`

    return `${EnglishToNepaliConverter(finalDate)} ${nepaliDateFormatter(
        finalDateAttachment,
        finalDate
    )}`
}

const lang = {
    ENGLISH: 'en',
    NEPALI: 'np',
}

const authRoutes = {
    SIGNIN: 'signIn',
    FORGOT_PASSWORD: 'forgotPassword',
    PASSWORD_RESET: 'passwordReset',
    OTP: 'otp',
    SIGNUP_USERNAME: 'signUpUserName',
    SIGNUP_PASSWORD: 'signUpPwd',
    LOGOUT: 'logOut',
}

const tokenConst = {
    ACCESS_TOKEN: 'access_token',
    USER_UNIQUE_TOKEN: 'user_unique_token',
    EXPIRY_DATE: 'expiry_date',
}

const detailConst = {
    USER_DETAILS: 'user_details',
}

const share = {
    BUY: 'Buy',
    SELL: 'Sell',
}

const closePriceTypes = {
    TOP_GAIN: 'top_gainers',
    TOP_LOSS: 'top_loosers',
    TOP_TURNOVER: 'top_stock_by_tornover',
    TOP_SHARE_TRADED: 'top_stock_by_share_traded',
    TOP_NUM_TRANSACTION: 'top_stock_by_no_of_transaction',
    TOP_SECTOR: 'top_sectors',
}

const indexType = {
    NEPSE: 'nepse',
    SENSITIVE: 'sensitive',
    FLOAT: 'float',
    SENSITIVE_FLOAT: 'sensitive_float',
}

const shareGraphDuration = {
    ONE_WEEK: '1week',
    ONE_MONTH: '1m',
    THREE_MONTHS: '3m',
    SIX_MONTHS: '6m',
    ONE_YEAR: '1y',
}

export {
    lang,
    authRoutes,
    tokenConst,
    detailConst,
    share,
    closePriceTypes,
    indexType,
    shareGraphDuration,
}

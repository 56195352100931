import create from 'zustand'

import { firstTimeSlice } from './firstTimeSlice'
import { darkModeSlice } from './darkModeSlice'
import { advertisementSlice } from './advertisementSlice'
import { breakingNewsSlice } from './breakingNewsSlice'
import { signUpUserNameSlice } from './signUpUserNameSlice'
import { forgotPasswordEmailSlice } from './forgotPasswordEmailSlice'
import { userDetailsSlice } from './userDetailsSlice'
import { currentLanguageSlice } from './currentLanguageSlice'
import { roleSlice } from './roleSlice'
import { sliderSlice } from './sliderSlice'
import { routeSlice } from './routeSlice'
import { persist, createJSONStorage } from 'zustand/middleware'

const useStore = create(
    persist((set, get) => ({
        ...firstTimeSlice(set, get),
        ...darkModeSlice(set, get),
        ...advertisementSlice(set, get),
        ...breakingNewsSlice(set, get),
        ...signUpUserNameSlice(set, get),
        ...forgotPasswordEmailSlice(set, get),
        ...userDetailsSlice(set, get),
        ...currentLanguageSlice(set, get),
        ...roleSlice(set, get),
        ...sliderSlice(set, get),
        ...routeSlice(set, get),
    }),
    {
        name:'storage',
    })
)

export default useStore

import './footerItem.scss'
import { PropTypes } from 'prop-types'
import { BsFillTelephoneFill } from 'react-icons/bs'
import { MdMail } from 'react-icons/md'
import { NavLink } from 'react-router-dom'

const FooterItem = ({ payload, title, iconList, imageList, qrCode }) => {
    return (
        <div className="footer-item-container">
            {title && (
                <h3 className="footer-item-title">{title ? title : ''}</h3>
            )}
            <div className="footer-item-data-container">
                {Array.isArray(payload) && payload.length > 0 ? (
                    <div className="footer-item-text-list-container">
                        {payload.map((item, index) => (
                            <div
                                className="footer-item-text-list-data-container"
                                key={index}
                            >
                                {item.url ? (
                                    item.url.type === 'email' ? (
                                        <a
                                            href={`mailto:${item.url.link}`}
                                            className="footer-item-text-list-data"
                                        >
                                            <MdMail />
                                            {item.data}
                                        </a>
                                    ) : item.url.type === 'phoneNumber' ? (
                                        <a
                                            href={`tel:+${item.url.link}`}
                                            className="footer-item-text-list-data"
                                        >
                                            <BsFillTelephoneFill />
                                            {item.data}
                                        </a>
                                    ) : item.url.type === 'innerPageLink' ? (
                                        <NavLink
                                            to={item.url.link}
                                            className="footer-item-text-list-data"
                                        >
                                            {item.data}
                                        </NavLink>
                                    ) : (
                                        <a
                                            href={item.url.link}
                                            target="_blank"
                                            rel="noreferrer"
                                            className="footer-item-text-list-data"
                                        >
                                            {item.data}
                                        </a>
                                    )
                                ) : (
                                    item.data
                                )}
                            </div>
                        ))}
                    </div>
                ) : null}
                {Array.isArray(qrCode) && qrCode.length > 0 ? (
                    <div className="footer-qr-code-container">
                        {qrCode.map((item, index) => (
                            <figure
                                className="footer-qr-code-figure"
                                key={index}
                            >
                                <img src={item.img} alt="" />
                            </figure>
                        ))}
                    </div>
                ) : null}
                {Array.isArray(iconList) && iconList.length > 0 ? (
                    <div className="footer-item-icon-container">
                        {iconList.map((icon, index) => (
                            <div
                                className="footer-item-icon-item-container"
                                key={index}
                            >
                                <a
                                    href={icon?.url}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {icon.img && icon.img}
                                </a>
                            </div>
                        ))}
                    </div>
                ) : null}
                {Array.isArray(imageList) && imageList.length > 0 ? (
                    <div className="footer-item-image-container">
                        {imageList.map((image, index) => (
                            <div
                                className="footer-item-image-item-container"
                                key={index}
                            >
                                <a
                                    href={image?.url}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <figure className="footer-item-figure">
                                        <img
                                            src={image?.img}
                                            alt=""
                                            className="footer-item-image"
                                        />
                                    </figure>
                                </a>
                            </div>
                        ))}
                    </div>
                ) : null}
            </div>
        </div>
    )
}

export default FooterItem

FooterItem.propTypes = {
    payload: PropTypes.array,
    title: PropTypes.string,
    iconList: PropTypes.array,
    imageList: PropTypes.array,
    qrCode: PropTypes.array,
}

// payload, title, iconList, imageList

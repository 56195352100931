import { Route, Switch, useLocation } from 'react-router'
import { useState, useEffect, lazy } from 'react'

// Constants
import { routePaths } from 'global/constants/routePaths'
import { dummyData, navigationTitles } from 'global/constants/dummyData'
import { useTranslation } from 'react-i18next'
import tokenService from 'utils/token'
import PrivateRoute from './PrivateRoutes'
import routes from 'utils/routes'
import Login from 'pages/admin/Login'

// Pages
import NewsArticleTags from 'pages/NewsArticleTags/NewsArticleTags'

const AboutEknepal = lazy(() => import('pages/About/AboutEknepal'))
const AboutMediaAllianceNepal = lazy(() =>
    import('pages/About/AboutMediaAllianceNepal')
)
const TodaySharePrice = lazy(() =>
    import('pages/TodaySharePrice/TodaySharePrice')
)

const News = lazy(() => import('pages/News/News'))
const NavBar = lazy(() => import('components/Layout/Navigation/Navbar/NavBar'))
const NewsTicker = lazy(() => import('components/NewsTicker/NewsTicker'))
const Dashboard = lazy(() => import('pages/Dashboard/Dashboard'))
const InterestTopics = lazy(() => import('pages/InterestTopics/InterestTopics'))
const Forex = lazy(() => import('pages/Forex/Forex'))
const NewsSinglePage = lazy(() =>
    import('components/Layout/News/NewsSinglePage/NewsSinglePage')
)
const ShareMarket = lazy(() => import('pages/ShareMarket/ShareMarket'))
const Unicode = lazy(() => import('pages/Unicode/Unicode'))
const DateConverter = lazy(() => import('pages/DateConverter/DateConverter'))
const Calculator = lazy(() => import('pages/Calculator/Calculator'))
const NepaliCalendar = lazy(() => import('pages/NepaliCalendar/NepaliCalendar'))
const Gold = lazy(() => import('pages/Gold/Gold'))
const SingleHoroscope = lazy(() =>
    import('components/Layout/Horoscope/SingleHoroscope/SingleHoroscope')
)
const Horoscope = lazy(() => import('pages/Horoscope/Horoscope'))
const FMLists = lazy(() => import('pages/FMLists/index'))
const PrivacyEknepal = lazy(() => import('pages/Privacy/PrivacyEknepal'))
const MediahubList = lazy(() => import('pages/MediahubList/MediahubList'))
const ErrorPage = lazy(() => import('utils/helpers/error/ErrorPage'))
const Footer = lazy(() => import('components/Layout/Footer/Footer'))
const CompanyListing = lazy(() =>
    import('pages/ShareSubMenu/CompanyListing/CompanyListing')
)

const todayPrice = lazy(() =>
    import('pages/ShareSubMenu/TodayPrice/TodayPrice')
)

const Rtslisting = lazy(() => import('pages/ShareSubMenu/RTS/Rtslisting'))
const Sectorlisting = lazy(() =>
    import('pages/ShareSubMenu/Sector/Sectorlisting')
)
const CompanyDetail = lazy(() => import('pages/CompanyDetail/CompanyDetail'))
const Indices = lazy(() => import('pages/ShareSubMenu/Indices/Indices'))
const PublicRoutes = () => {
    const [currentRoute, setCurrentRoute] = useState()
    const { t } = useTranslation()

    const location = useLocation()

    useEffect(() => {
        setCurrentRoute(navigationTitles[location.pathname])
    }, [location.pathname])

    document.title = `Eknepal - ${t(currentRoute)}`

    return (
        <Switch>
            <Route exact path={routePaths.dashboard} component={Dashboard} />
            <Route
                path={routePaths.interestTopics}
                component={InterestTopics}
            />
            <Route
                exact
                path={routePaths.news.tagRouting}
                component={NewsArticleTags}
            />
            <Route
                path={routePaths.news.detailRouting}
                component={NewsSinglePage}
            />
            <Route path={routePaths.news.base} component={News} />
            <Route path={routePaths.forex} component={Forex} />
            <Route path={routePaths.shareMarket} component={ShareMarket} />
            <Route
                path={routePaths.todaySharePrice}
                component={TodaySharePrice}
            />
            <Route path={routePaths.unicode} component={Unicode} />
            <Route path={routePaths.dateConverter} component={DateConverter} />
            <Route path={routePaths.calculator} component={Calculator} />
            <Route path={routePaths.calendar} component={NepaliCalendar} />
            <Route path={routePaths.gold} component={Gold} />
            <Route
                path={routePaths.horoscope.detailRouting}
                component={SingleHoroscope}
            />
            <Route path={routePaths.horoscope.base} component={Horoscope} />
            <Route path={routePaths.fm} component={FMLists} />
            <Route path={routePaths.about.eknepal} component={AboutEknepal} />
            <Route
                path={routePaths.about.mediaAllianceNepal}
                component={AboutMediaAllianceNepal}
            />
            <Route path={routePaths.privacy.base} component={PrivacyEknepal} />
            <Route path={routePaths.mediahub} component={MediahubList} />
            <Route
                path={routePaths.shareSubMenu.companyListing}
                component={CompanyListing}
            />
            <Route
                path={routePaths.shareSubMenu.rtslisting}
                component={Rtslisting}
            />
            <Route
                path={routePaths.shareSubMenu.sectorlisting}
                component={Sectorlisting}
            />
            <Route path={routePaths.shareSubMenu.Indices} component={Indices} />
            <Route
                path={routePaths.shareSubMenu.todayPrice}
                component={todayPrice}
            />
            <Route path={routePaths.companyDetails} component={CompanyDetail} />
            <Route path={routePaths.admin.auth.login} component={Login} />

            {routes.map((route, index) => (
                <PrivateRoute
                    exact
                    key={index}
                    path={route.path}
                    component={route.component}
                />
            ))}

            <Route path={'*'} component={ErrorPage} />
        </Switch>
    )
}

export default PublicRoutes

// Constants
import {
  dummyData,
  navigationTitles,
} from 'global/constants/dummyData';
import {
  useEffect,
  useState,
} from 'react';

import Footer from 'components/Layout/Footer/Footer';
// Pages
import NavBar from 'components/Layout/Navigation/Navbar/NavBar';
// Components
import NewsTicker from 'components/NewsTicker/NewsTicker';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import useStore from 'store/store';
import { useFetchAdvertisement } from 'hooks/advertisement/useFetchAdvertisement'


const advertisementLists = {
  home_page_banner_top: '',
  home_page_banner_middle: '',
  home_page_banner_bottom: '',
  home_page_block_first: '',
  home_page_block_second: '',
  home_page_popup: '',
  inner_page_banner_top: '',
  inner_page_banner_bottom: '',
  inner_page_block_first: '',
  inner_page_block_second: '',
  mobile_home_page_popup: '',
  mobile_home_page_banner_top: '',
  mobile_home_page_banner_middle: '',
  mobile_home_page_banner_bottom: '',
  mobile_home_page_block_first: '',
  mobile_home_page_block_block: '',
  mobile_inner_page_banner_first: '',
  mobile_inner_page_banner_second: '',
}

const PublicLayout = ({children}) => {
    const [currentRoute, setCurrentRoute] = useState()
    const { t } = useTranslation()
    const addAdvertisement =useStore((state)=>state.addAdvertisement)

    const location = useLocation()
        const { data: advertisementDataLists } = useFetchAdvertisement()

    useEffect(() => {
        if (advertisementDataLists) {
            let adListCopy = { ...advertisementLists }

            advertisementDataLists.payload.forEach((item, index) => {
                if(item.status != 0){

                    if (
                        item.type.id == 1 &&
                        item.page_type === 'home_page' &&
                        item.page_position === 'top'
                    ) {
                        adListCopy = { ...adListCopy, home_page_banner_top: item }
                    } else if (
                        item.type.id == 1 &&
                        item.page_type === 'home_page' &&
                        item.page_position === 'middle'
                    ) {
                        adListCopy = {
                            ...adListCopy,
                            home_page_banner_middle: item,
                        }
                    } else if (
                        item.type.id == 1 &&
                        item.page_type === 'home_page' &&
                        item.page_position === 'bottom'
                    ) {
                        adListCopy = {
                            ...adListCopy,
                            home_page_banner_bottom: item,
                        }
                    } else if (
                        item.type.id == 3 &&
                        item.page_type === 'home_page' &&
                        item.page_position === 'first'
                    ) {
                        adListCopy = { ...adListCopy, home_page_block_first: item }
                    } else if (
                        item.type.id == 3 &&
                        item.page_type === 'home_page' &&
                        item.page_position === 'second'
                    ) {
                        adListCopy = { ...adListCopy, home_page_block_second: item }
                    } else if (
                        item.type.id == 4 &&
                        item.page_type === 'home_page' &&
                        item.page_position === null
                    ) {
                        adListCopy = { ...adListCopy, home_page_popup: item }
                    } else if (
                        item.type.id == 1 &&
                        item.page_type === 'inner_page' &&
                        item.page_position === 'top'
                    ) {
                        adListCopy = { ...adListCopy, inner_page_banner_top: item }
                    } else if (
                        item.type.id == 1 &&
                        item.page_type === 'inner_page' &&
                        item.page_position === 'bottom'
                    ) {
                        adListCopy = {
                            ...adListCopy,
                            inner_page_banner_bottom: item,
                        }
                    } else if (
                        item.type.id == 3 &&
                        item.page_type === 'inner_page' &&
                        item.page_position === 'first'
                    ) {
                        adListCopy = { ...adListCopy, inner_page_block_first: item }
                    } else if (
                        item.type.id == 3 &&
                        item.page_type === 'inner_page' &&
                        item.page_position === 'second'
                    ) {
                        adListCopy = {
                            ...adListCopy,
                            inner_page_block_second: item,
                        }
                    } else if (
                        item.type.id == 9 &&
                        item.page_type === 'mobile_home_page' &&
                        item.page_position === null
                    ) {
                        adListCopy = { ...adListCopy, mobile_home_page_popup: item }
                    } else if (
                        item.type.id == 8 &&
                        item.page_type === 'mobile_home_page' &&
                        item.page_position === 'second'
                    ) {
                        adListCopy = {
                            ...adListCopy,
                            mobile_home_page_banner_top: item,
                        }
                    } else if (
                        item.type.id == 8 &&
                        item.page_type === 'mobile_home_page' &&
                        item.page_position === 'top'
                    ) {
                        adListCopy = {
                            ...adListCopy,
                            mobile_home_page_banner_top: item,
                        }
                    } else if (
                        item.type.id == 8 &&
                        item.page_type === 'mobile_home_page' &&
                        item.page_position === 'middle'
                    ) {
                        adListCopy = {
                            ...adListCopy,
                            mobile_home_page_banner_middle: item,
                        }
                    } else if (
                        item.type.id == 8 &&
                        item.page_type === 'mobile_inner_page' &&
                        item.page_position === 'first'
                    ) {
                        adListCopy = {
                            ...adListCopy,
                            mobile_inner_page_banner_first: item,
                        }
                    } else if (
                        item.type.id == 8 &&
                        item.page_type === 'mobile_inner_page' &&
                        item.page_position === 'second'
                    ) {
                        adListCopy = {
                            ...adListCopy,
                            mobile_inner_page_banner_second: item,
                        }
                    }
                }
            })

            addAdvertisement(adListCopy)
        }

    }, [advertisementDataLists])




    useEffect(() => {
        setCurrentRoute(navigationTitles[location.pathname])
    }, [location.pathname])

    document.title = `Eknepal - ${t(currentRoute)}`

    return (
        
                <>
                  
                    <NavBar dummyData={dummyData} />
                    <NewsTicker />
                         {children}
                    <Footer />
                </>
          
    )
}

export default PublicLayout

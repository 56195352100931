import { endpoints } from 'global/endpoints';
import http from 'utils/http';
import { useQuery } from 'react-query';

const fetchAdvertisement = () => {
    const updatedEndpoints = endpoints.advertisement.base
    return http().get(updatedEndpoints)
}

export function useFetchAdvertisement() {
    return useQuery(['fetchAdvertisement'], () =>
        fetchAdvertisement()
    )
}

import { endpoints } from 'global/endpoints'
import http from 'utils/http'
import { stringifyQuery } from 'utils/helpers/stringifyQuery'
import { useQuery } from 'react-query'

export const fetchCompanyValue = (searchQuery) => {
    const stringifiedQuery = stringifyQuery(searchQuery)
    const newEndpoints = endpoints.company.base + '?' + stringifiedQuery
    return http().get(newEndpoints)
}

export function useFetchCompany(searchQuery, options) {
    return useQuery(
        ['fetchUser', searchQuery],
        () => fetchCompanyValue(searchQuery),
        options
    )
}

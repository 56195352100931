export function stringifyQuery(data) {
    let query = ''
    for (let d in data) {
        if (data[d]) {
            query +=
                encodeURIComponent(d) +
                '=' +
                encodeURIComponent(data[d] || '') +
                '&'
        }
    }
    return query.slice(0, -1)
}

export const endpoints = {
    advertisement: {
        base: 'v1/advertisements',
        type: 'v1/advertisements?type={typeId}',
        listType: 'v1/advertisements/types',
        status: 'v1/advertisements/{id}/change-status',
        specific: 'v1/advertisements/{id}',
    },
    user: {
        base: 'v1/users',
        userById: 'v1/users/{id}',
    },
    auth: {
        base: 'v1/auth/tokens',
        forgotPassword: 'v1/auth/forgot-passwords',
        forgotPasswordOtp: 'v1/auth/forgot-passwords/otp',
        logout: 'v1/auth/logout',
        socialLogin: 'v1/auth/social/login',
        resetPassword: 'v1/auth/reset-passwords',
        users: {
            base: 'v1/users',
            registration: 'v1/users/registration',
            resendOtp: 'v1/users/resend-otp',
            verifyEmail: 'v1/users/verify-email',
        },
    },
    category: {
        base: 'v1/categories',
        adminList: 'v1/categories/list',
        byId: 'v1/categories/{id}',
    },
    news: {
        base: 'v1/news',
        details: 'v1/news/{id}',
        newsById: 'v1/news/{id}',
        paginate: 'v1/news?page_size={pageSize}',
        changeStatus: '/v1/news/{id}/change-status',
        catPaginate: 'v1/news?category={catName}&page_size={pageSize}',
        featuredNews: 'v1/news/featured-news',
        comment: 'v1/news/{id}/comment',
        like: 'v1/news/{id}/like',
        popularNews: {
            base: 'v1/news/popular-news/list',
            paginate: 'v1/news/popular-news/list?page_size={number}',
        },
        trendingNews: {
            base: 'v1/news/trending/list',
            paginate: 'v1/news/trending/list?page_size={number}',
        },
        breakingNews: {
            base: 'v1/news/breaking/list',
        },
        tag: {
            base: 'v1/news?tag={tagName}',
            paginate: 'v1/news?tag={tagName}&page_size={paginateNumber}',
        },
        channel: {
            base: 'v1/news/channel/{slug}?page_size={paginateNumber}',
        },
        channelList: {
            base: 'v1/news/channel-list',
        },
    },
    shareMarket: {
        index: {
            base: 'v1/index',
            graph: {
                base: 'v1/index/graph-list',
                typeDuration:
                    'v1/index/graph-list?type={typeName}&duration={durationTime}',
            },
        },
        closePrice: {
            base: 'v1/close-price',
            bySymbol:
                'v1/close-price?search={symbol}&page_size={paginateNumber}',
            byDate: 'v1/close-price?date={date}&page_size={paginateNumber}',
            byType: 'v1/close-price?type={type}',
            byDateSymbolPaginate:
                'v1/close-price?date={date}&search={symbol}&page_size={paginateNumber}',
            importFile: 'v1/close-price/import-excel',
            details: 'v1/close-price/{symbol}',
        },
        marketSummary: 'v1/index/market-summery',
        rts: {
            base: 'v1/registrar-to-shares',
            byId: 'v1/registrar-to-shares/{id}',
        },
    },
    videos: {
        base: 'v1/videos',
        details: 'v1/videos/{id}',
        paginate: 'v1/videos?page_size={paginateNumber}',
    },
    horoscopes: {
        base: 'v1/horoscopes',
        detail: 'v1/horoscopes/detail?sign={horoscopeSign}&type={currentLanguage}',
    },
    weather: {
        base: '/v1/weather',
        latAndLong: 'v1/weather?lat={lattitudeNum}&lon={longitudeNum}',
    },
    forex: {
        base: 'v1/rate?per_page={perPage}&from={fromDate}&to={toDate}',
    },
    fmAndTv: {
        base: 'v1/fmandtvs',
        fm: 'v1/fmandtvs?type=fm&lang={language}',
        tv: 'v1/fmandtvs?type=tv&lang={language}',
        fmandtvsById: 'v1/fmandtvs/{id}',
    },
    ePaper: {
        base: 'v1/magazines?lang={language}',
        baseAdmin: 'v1/magazines/list?lang={language}',
        baseSend: 'v1/magazines',
        epaperById: 'v1/magazines/{id}',
        update: 'v1/magazines/{id}/update',
    },
    goldSilver: {
        base: 'v1/gold-or-silvers',
        goldorsilverById: 'v1/gold-or-silvers/{id}',
        graph: 'v1/gold-or-silvers/graph/list',
        latest: 'v1/gold-or-silvers/latest-list',
    },
    role: {
        base: 'v1/roles',
        specific: 'v1/roles/{id}',
        list: 'v1/roles/list',
    },
    calendar: {
        dob: {
            base: 'v1/dobs',
            basedOnDate: 'v1/dobs?dob={queryDate}',
        },
        holidays: {
            base: 'v1/holidays',
            basedOnLanguage: 'v1/holidays?type={currentLang}',
        },
        events: {
            base: 'v1/events',
            basedOnNepaliMonthAndLang:
                'v1/events?type={currentLang}&month={monthNumber}',
        },
    },
    calculator: {
        share: {
            base: 'v1/sharecalcultors/calculation-data',
        },
    },

    permissions: {
        base: 'v1/permissions/list',
        permissionsById: 'v1/permissions/{id}',
    },
    sectors: {
        base: 'v1/sectors',
        sectorsById: 'v1/sectors/{id}',
    },

    login: {
        base: 'v1/admin/login',
    },
    logout: {
        base: 'v1/admin/logout',
    },
    nepseindex: {
        base: 'v1/nepseindices',
        nepseindexById: 'v1/nepseindices/{uuid}',
        changeStatus: 'v1/nepseindices/{uuid}/change-status',
    },
    nepseIndexValue: {
        base: 'v1/nepse-index-values',
        nepseValueById: 'v1/nepse-index-values/detail?date={date}',
        changeStatus: 'v1/nepse-index-values/{uuid}/chnage-status',
    },
    company: {
        base: 'v1/companies',
        companyById: 'v1/companies/{id}',
    },
    images: {
        base: 'v1/images',
        byId: 'v1/images/{id}',
    },
}

/* eslint-disable no-unused-vars */
import './singleNewsLeftBlock.scss'
import ConvertToRelativeTime from 'utils/ConvertToRelativeTime'
import { AiFillTags } from 'react-icons/ai'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { routePaths } from 'global/constants/routePaths'
// import { HiArrowSmRight } from 'react-icons/hi'
import { eknepalLargeImg } from 'assets'
import { useState, useEffect } from 'react'
import useMediaQuery from 'hooks/mediaQuery/useMediaQuery'
import {replaceHttpWithHttps} from "utils/replaceHttpWithHttps";

const SingleNewsLeftBlock = ({ payload }) => {
    const { t } = useTranslation()

    const isMedium = useMediaQuery('(max-width: 768px)')
    const isMediumLarge = useMediaQuery('(max-width: 1024px)')
    const isLarge = useMediaQuery('(max-width: 1440px)')
    const isSmall = useMediaQuery('(max-width: 576px)')

    const [numberOfItems, setNumberOfItems] = useState(4)

    useEffect(() => {
        if (isSmall) {
            setNumberOfItems(3)
        } else if (isMedium) {
            setNumberOfItems(4)
        } else if (isMediumLarge) {
            setNumberOfItems(5)
        } else {
            setNumberOfItems(3)
        }
    }, [isMedium, isMediumLarge, isLarge, isSmall])

    return (
        <div className="single-left-news-block-container">
            <NavLink
                exact
                to={routePaths.news.details.replace('{idNumber}', payload.id)}
                className="single-left-news-block-image-link"
            >
                <div className="single-left-news-block-image-container">
                    <figure className="single-left-news-block-image-figure">
                        <img
                            src={
                                payload?.image ? replaceHttpWithHttps(payload.image) : eknepalLargeImg
                            }
                            className="single-left-news-block-image"
                            alt=""
                        />
                    </figure>
                </div>
            </NavLink>
            <div className="single-left-news-text-container">
                <div className="single-left-news-agency-date-container">
                    <div className="single-left-news-agency-container">
                        <img
                            src={
                                replaceHttpWithHttps(payload?.channel_icon)
                            }
                            alt=""
                            className="single-left-news-agency-icon"
                        />
                        <span className="single-left-news-agency-name">
                            {payload?.channel_name
                                ? payload.channel_name
                                : null}
                        </span>
                    </div>
                    <div className="single-left-news-agency-date-seperator">
                        .
                    </div>
                    <p className="single-left-news-date">
                        {ConvertToRelativeTime(
                            new Date(
                                payload?.created_at ? payload.created_at : null
                            )
                        )}
                    </p>
                </div>
                <NavLink
                    exact
                    to={routePaths.news.details.replace(
                        '{idNumber}',
                        payload.id
                    )}
                    className="single-left-news-heading-link"
                >
                    <h3 className="single-left-news-heading">
                        {payload?.title ? payload.title : null}
                    </h3>
                </NavLink>
                <div className="single-left-news-content">
                    {payload.description}
                </div>
                <div className="single-left-news-tags-see-more-container">
                    <div className="single-left-news-tags-container">
                        {payload?.news_tags &&
                            Array.isArray(payload?.news_tags) &&
                            payload?.news_tags?.length > 0 && <AiFillTags />}
                        {payload?.news_tags && Array.isArray(payload.news_tags)
                            ? payload.news_tags.map((tag, index) =>
                                  index < numberOfItems ? (
                                      <NavLink
                                          to={routePaths.news.tag.replace(
                                              '{tagName}',
                                              tag
                                          )}
                                          className="single-left-news-tag"
                                          key={index}
                                      >
                                          {tag}
                                      </NavLink>
                                  ) : (
                                      ''
                                  )
                              )
                            : null}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SingleNewsLeftBlock

import { QueryClient } from 'react-query'

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: 2,
            refetchOnWindowFocus: false,
            onError: (errorData) => {
                // toast.error('There was a problem. Please try again!')
            },
        },
        mutations: {
            onError: (errorData) => {
                // toast.error('There was a problem. Please try again!')
            },
            onSuccess: (data) => {
                // do nothing for now.
            },
        },
    },
})

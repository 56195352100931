import { endpoints } from 'global/endpoints';
import http from 'utils/http';
import { toast } from 'react-toastify';
import { useMutation } from 'react-query';

const postForgotPassword = (postData) => {
    if (postData) {
        const updatedEndpoints = endpoints.auth.forgotPassword
        return http().post(updatedEndpoints, postData)
    }
}

export default function usePostForgotPassword() {
    return useMutation(postForgotPassword, {
        onSuccess: () => {
            toast.success('Email has been sent successfully!')
        },
        onError: (err) => {
            toast.error(err.message)
        },
    })
}

import './spinner.scss'
import { PropTypes } from 'prop-types'
import classNames from 'classnames'

const Spinner = ({ isWhite = false, isSmall }) => {
    return (
        <div
            className={classNames('loader', { white: isWhite, small: isSmall })}
        ></div>
    )
}

export default Spinner

Spinner.propTypes = {
    white: PropTypes.bool,
}

import React from 'react'
import Button  from 'components/Layout/Button/Button'
import token from 'utils/token'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import './logOut.scss'
import { PropTypes } from 'prop-types'
import { authRoutes } from 'global/constants/appConstants'
import usePostUserLogout from 'hooks/userAuth/usePostUserLogout'
import Spinner  from 'components/Spinner/Spinner'
// import { useEffect } from 'react'
// import useStore from 'store/store'
import * as storage from 'utils/storage'
import { detailConst } from 'global/constants/appConstants'
import { toast } from 'react-toastify'

const LogOut = ({ show, setCurrentPage, authRef }) => {
    const { t } = useTranslation()
    // const userDetails = useStore((store) => store.userDetails)
    const {
        // mutate: logOutFn,
        // isSuccess: logOutSuccess,
        isLoading: logOutLoading,
    } = usePostUserLogout()

    const handleYesButtonClick = (e) => {
        e.preventDefault()
        // const postData = {
        //     id: userDetails.id,
        // }
        // logOutFn(postData)
        token.clearToken()
        storage.remove(detailConst.USER_DETAILS)
        setCurrentPage(authRoutes.SIGNIN)
        authRef.current.close()
        toast.success('You have logged out successfully!')
    }

    // useEffect(() => {
    //     if (logOutSuccess) {
    //         token.clearToken()
    //         setCurrentPage(authRoutes.SIGNIN)
    //         authRef.current.close()
    //     }

    //     // eslint-disable-next-line
    // }, [logOutSuccess])

    const handleNoButtonClick = (e) => {
        authRef.current.close()
    }

    return (
        <div className={classNames('log-out-container', { show: show })}>
            <h4 className="log-out-heading">{t('authentication.logOut')}</h4>
            <div className="log-out-button-container">
                {logOutLoading ? (
                    <Button
                        size="widthHundred"
                        bgColor="grey"
                        description={<Spinner isWhite={true} />}
                    />
                ) : (
                    <Button
                        handleButtonClick={handleYesButtonClick}
                        size="widthHundred"
                        bgColor="grey"
                        description={t('buttons.yes')}
                    />
                )}
                <Button
                    size="widthHundred"
                    bgColor="grey"
                    description={t('buttons.no')}
                    handleButtonClick={handleNoButtonClick}
                />
            </div>
        </div>
    )
}

export default LogOut

LogOut.propTypes = {
    show: PropTypes.bool,
    setCurrentPage: PropTypes.func,
    authRef: PropTypes.object,
}

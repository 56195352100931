import { PropTypes } from 'prop-types'
import classNames from 'classnames'
import './labelInput.scss'
import { useTranslation } from 'react-i18next'

const LabelInput = ({
    labelName,
    labelClassName,
    labelHtmlFor,
    inputType,
    inputName,
    inputValue,
    inputClassName,
    size,
    handleOnChange,
    handleOnFocus,
    inputPlaceholder,
    labelDisplay,
    error,
    handleOnBlur,
    touched,
    orientation,
    noStyles,
    icon,
    iconOnClick,
    touchedError,
    inputNoBorder,
}) => {
    const { t } = useTranslation()
    return (
        <div
            className={classNames('label-input-container', {
                vertical: orientation === 'vertical',
            })}
        >
            <label
                htmlFor={labelHtmlFor}
                className={classNames(
                    `label-input-label ${labelClassName ? labelClassName : ''}`,
                    {
                        labelNone: labelDisplay === 'none',
                    }
                )}
            >
                {labelName}
            </label>
            <div className="label-input-input-error-container">
                <div className="label-input-input-icon-container">
                    <input
                        type={inputType ? inputType : 'text'}
                        name={inputName}
                        value={inputValue}
                        className={classNames(
                            `label-input-field ${
                                inputClassName ? inputClassName : ''
                            }`,
                            {
                                small: size === 'small' || size === undefined,
                                medium: size === 'medium',
                                large: size === 'large',
                                'no-styles': noStyles,
                                'no-border': inputNoBorder,
                            }
                        )}
                        id={labelHtmlFor}
                        onChange={handleOnChange}
                        onBlur={handleOnBlur}
                        onFocus={handleOnFocus}
                        placeholder={inputPlaceholder}
                    />
                    {icon && (
                        <div
                            className="label-input-icon-container"
                            onClick={iconOnClick}
                        >
                            {icon}
                        </div>
                    )}
                </div>
                {error && <p className="label-input-error">{t(error)}</p>}
                {touched && touchedError && (
                    <p className="label-input-error">{t(touchedError)}</p>
                )}
            </div>
        </div>
    )
}

export default LabelInput

LabelInput.propTypes = {
    labelName: PropTypes.string,
    labelHtmlFor: PropTypes.string,
    inputType: PropTypes.string,
    labelClassName: PropTypes.string,
    inputPlaceholder: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
    ]),
    inputClassName: PropTypes.string,
    size: PropTypes.string,
    inputValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
    ]),
    inputName: PropTypes.string,
    handleOnChange: PropTypes.func,
    labelDisplay: PropTypes.string,
    error: PropTypes.string,
    handleOnBlur: PropTypes.func,
    touched: PropTypes.bool,
    noStyles: PropTypes.bool,
    icon: PropTypes.element,
    iconOnClick: PropTypes.func,
}

import {
    CButton,
    CCard,
    CCardBody,
    CCardGroup,
    CCol,
    CContainer,
    CFormInput,
    CInputGroup,
    CInputGroupText,
    CRow,
} from '@coreui/react'
import { NavLink, useHistory } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { cilLockLocked, cilUser } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { endpoints } from 'global/endpoints'
import http from 'utils/http'
import { routePaths } from 'global/constants/routePaths'
import { toast } from 'react-toastify'
import token from 'utils/adminToken'
import usePostAdminLogin from 'hooks/log/usePostAdminLogin'
import useStore from 'store/store'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'

const Login = () => {
    const { updateUserRole, updateUserId, updateRoleId } = useStore(
        (store) => store
    )
    const history = useHistory()

    const [showPassword, setShowPassword] = useState(false)
    const [adminCredentials, setAdminCredentials] = useState({
        email: '',
        password: '',
    })
    const [roleId, setRoleId] = useState('')
    // eslint-disable-next-line no-unused-vars
    const { data: loginPayload, mutate } = usePostAdminLogin()
    const handleLoginButtonClick = (e) => {
        e.preventDefault()
        mutate(adminCredentials, {
            onSuccess: ({ payload }) => {
                updateUserId(payload.user.id)
                updateRoleId(payload.user.roles[0].id)
                setRoleId(payload.user.roles[0].id)
                toast.success('Logged in successfully!')
                token.setToken(`Bearer ${payload.token}`)
                token.setExpiryDate(20000)
                localStorage.setItem('roleId', payload.user.roles[0].id)
                history.push({ pathname: routePaths.admin.home.base })
                return payload
            },
            onError: (errorData) => {
                console.log('errorData', errorData)
                toast.error('Unsuccessful login. Please try again.')
            },
        })
    }

    useEffect(() => {
        if (roleId) {
            http('json')
                .get(endpoints.role.specific.replace('{id}', roleId))
                .then((res) => {
                    updateUserRole(res.payload.rolePermissions)
                })
                .catch((err) => console.log(err))
        }
    }, [roleId])

    return (
        <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
            <CContainer>
                <CRow className="justify-content-center">
                    <CCol md={8}>
                        <CCardGroup>
                            <CCard className="p-4">
                                <CCardBody>
                                    <form>
                                        <h1>Login</h1>
                                        <p className="text-medium-emphasis">
                                            Sign In to your account
                                        </p>
                                        <CInputGroup className="mb-3">
                                            <CInputGroupText>
                                                <CIcon icon={cilUser} />
                                            </CInputGroupText>
                                            <CFormInput
                                                type="email"
                                                placeholder="Email Address"
                                                autoComplete="false"
                                                onChange={(e) =>
                                                    setAdminCredentials({
                                                        ...adminCredentials,
                                                        email: e.target.value,
                                                    })
                                                }
                                            />
                                        </CInputGroup>
                                        <CInputGroup className="mb-4">
                                            <CInputGroupText>
                                                <CIcon icon={cilLockLocked} />
                                            </CInputGroupText>
                                            <CFormInput
                                                type={
                                                    showPassword
                                                        ? 'text'
                                                        : 'password'
                                                }
                                                placeholder="Password"
                                                autoComplete="false"
                                                onChange={(e) =>
                                                    setAdminCredentials({
                                                        ...adminCredentials,
                                                        password:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                            <CInputGroupText
                                                onClick={() =>
                                                    setShowPassword(
                                                        !showPassword
                                                    )
                                                }
                                            >
                                                {showPassword ? (
                                                    <AiFillEye />
                                                ) : (
                                                    <AiFillEyeInvisible />
                                                )}
                                            </CInputGroupText>
                                        </CInputGroup>
                                        <CRow>
                                            <CCol xs={6}>
                                                <CButton
                                                    color="danger"
                                                    variant="outline"
                                                    className="px-4"
                                                    onClick={
                                                        handleLoginButtonClick
                                                    }
                                                    type="submit"
                                                >
                                                    Login
                                                </CButton>
                                            </CCol>
                                            <CCol xs={6} className="text-right">
                                                <NavLink
                                                    to="/"
                                                    className="login-forgot-password"
                                                >
                                                    Forgot Password?
                                                </NavLink>
                                            </CCol>
                                        </CRow>
                                    </form>
                                </CCardBody>
                            </CCard>
                        </CCardGroup>
                    </CCol>
                </CRow>
            </CContainer>
        </div>
    )
}
export default Login

import { detailConst } from 'global/constants/appConstants'
import * as storage from 'utils/storage'

export const userDetailsSlice = (set, get) => ({
    userDetails: storage.get(detailConst.USER_DETAILS) || {},
    addUserDetails: (data) => {
        set({ userDetails: { ...data } })
    },
    resetUserDetails: () => {
        set({ userDetails: {} })
    },
})

/**
 *Get value from storage for given key:
 * @param {string} key
 */

 export function get_local(key) {
    const value = localStorage.getItem(key)
  
    if (!value) {
      return null
    }
  
    return JSON.parse(value)
  }
  
  /**
   * Set key value pair in storage
   *
   * @param {string} key
   * @param {string} value
   */
  
  export function set_local(key, value) {
    localStorage.setItem(key, JSON.stringify(value))
  
  }
  
  /**
   * Remove key value pair in storage
   * @param {string} key
   */
  
  export function remove_local(key) {
    localStorage.removeItem(key)
  }
  
  /**
   * Clear storage
   *
   * @return {string}
   */
  
  export function clear_local() {
    return localStorage.clear()
  }
  
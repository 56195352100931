import Ticker from 'react-ticker'
import PropTypes from 'prop-types'
import { usePageVisibility } from 'react-page-visibility'
import './newsTicker.scss'
import classNames from 'classnames'
import { useEffect } from 'react'
import useStore from 'store/store'
import { NavLink } from 'react-router-dom'
import { routePaths } from 'global/constants/routePaths'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import {replaceHttpWithHttps} from "utils/replaceHttpWithHttps";

const NewsTicker = () => {
    const [move, setMove] = useState(true)
    const isVisible = usePageVisibility()

    const breakingNewsData = useStore((store) => store.breakingNewsData)
    const setBreakingNewsData = useStore((store) => store.setBreakingNewsData)
    const checkBreakingNews = useStore((store) => store.checkBreakingNews)
    const resetBreakingNews = useStore((store) => store.resetBreakingNews)

    useEffect(() => {
        setBreakingNewsData()

        // eslint-disable-next-line
    }, [])

    if (checkBreakingNews) {
        setBreakingNewsData()
        resetBreakingNews()
    }

    const { t } = useTranslation()

    return (
        <section
            className={classNames('news-ticker-section', {
                visible: breakingNewsData.length > 0,
            })}
        >
            <div className="news-ticker-container">
                <p className="news-ticker-breaking-news">{`${t(
                    'breakingNews'
                )}:`}</p>
                {isVisible && breakingNewsData !== '' && (
                    <Ticker mode={'smooth'} speed={4} move={move}>
                        {({ index }) => (
                            <div
                                className="news-ticker-news-container"
                                key={index}
                            >
                                {breakingNewsData.map((item, index) => (
                                    <div
                                        className="news-ticker-single-news-container"
                                        key={index}
                                    >
                                        <figure
                                            className="news-ticker-figure"
                                            onMouseOver={() => setMove(false)}
                                            onMouseOut={() => setMove(true)}
                                        >
                                            <img
                                                src={replaceHttpWithHttps(item.channel_icon)}
                                                alt=""
                                            />
                                        </figure>
                                        <NavLink
                                            exact
                                            to={routePaths.news.details.replace(
                                                '{idNumber}',
                                                item.id
                                            )}
                                            className="news-ticker-news-text"
                                            onMouseOver={() => setMove(false)}
                                            onMouseOut={() => setMove(true)}
                                        >
                                            {item.title}
                                        </NavLink>
                                    </div>
                                ))}
                            </div>
                        )}
                    </Ticker>
                )}
            </div>
        </section>
    )
}

export default NewsTicker

NewsTicker.propTypes = {
    news: PropTypes.string,
}

export const roleSlice = (set, get) => ({
    roleList: [],
    permissionList: [],
    userPermissionList: [],
    userId:[],
    roleId:[],

   // List of all login user roles. eg [1,3,4,5] here each indicates the permission id 
     updateUserRole: (updatedRoleList) => {
        set({roleList :updatedRoleList
      })},

      // List of all permission in the system
      updatePermissionList: (updatedPermissionList) => { 
        set({permissionList :updatedPermissionList
      })},

      // Login user permission list
      updateUserPermissionList: (list) => {
        set({userPermissionList :list
      })},

      updateUserId: (updatedUserIdList) => {
        set({userId :updatedUserIdList
      })},

      updateRoleId: (updatedRoleIdList) => {
        set({roleId :updatedRoleIdList
      })},
})

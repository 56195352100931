import './footer.scss'
import {
    downloadGooglePlayImg,
    logoImg,
    downloadAppleStoreImg,
    mediaAllianceLogoImg,
    footerQrCodeImg,
    mediaAllianceLogoImgPng,
} from 'assets'
import FooterItem from './FooterItem/FooterItem'
import {
    BsFacebook,
    BsInstagram,
    BsTwitter,
    BsYoutube,
    BsLinkedin,
} from 'react-icons/bs'
import { routePaths } from 'global/constants/routePaths'
import { Link } from 'react-router-dom'
import FooterQR from './FooterQR/FooterQR'

// REMINDER: If the data for the footer should come from the backend API, refactor the code accordingly and take in props.
// REMINDER: Delete all of the dummy data which you will use in this section

const Footer = () => {
    const eknepalPayload = [
        [
            {
                data: 'About Ek Nepal',
                url: {
                    type: 'innerPageLink',
                    link: '/about/eknepal',
                },
            },
        ],

        // [
        //     {
        //         data: 'Advertise with us',
        //         url: {
        //             type: 'innerPageLink',
        //             link: '/advertise-with-us',
        //         },
        //     },
        // ],

        [{ title: 'CONNECT WITH EK NEPAL' }],

        [
            {
                data: 'eknepalmedia@gmail.com',
                url: {
                    type: 'email',
                    link: 'eknepalmedia@gmail.com',
                },
            },
        ],

        // [
        //     {
        //         data: 'Phone: 01-562480',
        //         url: {
        //             type: 'phoneNumber',
        //             link: '01562480',
        //         },
        //     },
        // ],
    ]

    const mediaAllianceNepalPayload = [
        [
            {
                data: 'About Media Alliance Nepal',
                url: {
                    type: 'innerPageLink',
                    link: '/about/media-alliance-nepal',
                },
            },
        ],
        // [
        //     {
        //         data: 'Advertise with us',
        //         url: {
        //             type: 'innerPageLink',
        //             link: '/advertise-with-us',
        //         },
        //     },
        // ],

        [{ title: 'CONNECT WITH MAN' }],

        [
            {
                data: 'mediaallianceapp@gmail.com',
                url: {
                    type: 'email',
                    link: 'mediaallianceapp@gmail.com',
                },
            },
        ],

        // [
        //     {
        //         data: 'Phone: 01-562480',
        //         url: {
        //             type: 'phoneNumber',
        //             link: '01562480',
        //         },
        //     },
        // ],
    ]

    const followUs = [
        [{ title: 'Follow Us' }],
        {
            url: 'https://facebook.com',
            img: <BsFacebook className="facebook-icon" />,
        },
        {
            url: 'https://instagram.com',
            img: <BsInstagram className="instagram-icon" />,
        },
        {
            url: 'https://twitter.com',
            img: <BsTwitter className="twitter-icon" />,
        },
        {
            url: 'https://youtube.com',
            img: <BsYoutube className="youtube-icon" />,
        },
        {
            url: 'https://linkedin.com',
            img: <BsLinkedin className="linkedin-icon" />,
        },
    ]

    const imageList = [
        [{ title: 'Download our App' }],
        {
            url: 'https://play.google.com/store/movies',
            img: downloadGooglePlayImg,
        },
        { url: 'https://www.apple.com/store', img: downloadAppleStoreImg },
    ]

    const qrCode = [
        [{ title: 'Scan our QR code' }],
        {
            url: 'https://play.google.com/store/movies',
            img: footerQrCodeImg,
        },
    ]

    return (
        <footer className="footer-container">
            <div className="footer-logo-header-container">
                <div className="footer-details-one">
                    <figure className="footer-logo-figure">
                        <img src={logoImg} alt="" className="footer-logo-img" />
                    </figure>
                    <div className="footer-details-one">
                        <div>
                            {eknepalPayload.map((item, index) => (
                                <FooterItem
                                    payload={item}
                                    title={item[0]?.title}
                                    key={index}
                                />
                            ))}
                        </div>
                    </div>
                </div>
                <div className="footer-details-two">
                    <figure className="footer-logo-figure">
                        <img
                            src={mediaAllianceLogoImg}
                            alt=""
                            className="footer-logo-img"
                        />
                    </figure>
                    <div className="footer-details-two">
                        <div>
                            {mediaAllianceNepalPayload.map((item, index) => (
                                <FooterItem
                                    payload={item}
                                    title={item[0]?.title}
                                    key={index}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-download-app-container">
                {/*<div className="footer-download-our-app-img-container">*/}
                {/*    <FooterItem*/}
                {/*        title={imageList[0][0]?.title}*/}
                {/*        imageList={imageList.slice(1, imageList.length)}*/}
                {/*    />*/}
                {/*</div>*/}
                {/*<p className="footer-or">OR</p>*/}
                {/*<div className="footer-scan-qr-code">*/}
                {/*    <FooterItem*/}
                {/*        title={qrCode[0][0]?.title}*/}
                {/*        qrCode={qrCode.slice(1, 2)}*/}
                {/*    />*/}
                {/*</div>*/}
            </div>
            <div className="footer-follow-us-container">
                <FooterQR />
                <FooterItem
                    title={followUs[0][0]?.title}
                    iconList={followUs.slice(1, followUs.length)}
                />
            </div>
            <div className="footer-bottom-container">
                <p className="footer-bottom-text">
                    Copyright &copy; 2022 MAN App Pvt. Ltd. All Right Reserved |{' '}
                    <Link to={routePaths.privacy.base}>Privacy</Link>
                </p>
                <p className="footer-bottom-text">
                    Designed and Developed by{' '}
                    <a
                        href="https:soanitech.com/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        SoAni Tech
                    </a>
                </p>
            </div>
        </footer>
    )
}

export default Footer

import * as localStorage from './storage'
import { tokenConst } from 'global/constants/appConstants'

const token = () => {
    function _setToken(tokenObj) {
        localStorage.set(tokenConst.ACCESS_TOKEN, tokenObj.accessToken)

        if (tokenObj.expiryDate) {
            localStorage.set(tokenConst.EXPIRY_DATE, tokenObj.expiryDate)
        }
    }

    function _setExpiryDate(expiryDate) {
        localStorage.set(tokenConst.EXPIRY_DATE, expiryDate)
    }

    function _setUserToken(tokenObj) {
        localStorage.set(tokenConst.USER_UNIQUE_TOKEN, tokenObj.userToken)

        if (tokenObj.expiryDate) {
            localStorage.set(tokenConst.EXPIRY_DATE, tokenObj.expiryDate)
        }
    }

    function _getAccessToken() {
        return localStorage.get(tokenConst.ACCESS_TOKEN)
    }

    function _getUserToken() {
        return localStorage.get(tokenConst.USER_UNIQUE_TOKEN)
    }

    function _getTokenExpiryDate() {
        return localStorage.get(tokenConst.EXPIRY_DATE)
    }

    function _clearToken() {
        localStorage.remove(tokenConst.ACCESS_TOKEN)
        localStorage.remove(tokenConst.USER_UNIQUE_TOKEN)
    }

    return {
        setToken: _setToken,
        setExpiryDate: _setExpiryDate,
        getAccessToken: _getAccessToken,
        setUserToken: _setUserToken,
        getUserToken: _getUserToken,
        getTokenExpiryDate: _getTokenExpiryDate,
        clearToken: _clearToken,
    }
}

export default token()

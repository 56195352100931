import React from 'react'
import './FooterQR.scss'
import { andriodQr, iosQr } from 'assets'
const FooterQR = () => {
    return (
        <div className="footer-item-QR">
            {/* <h3 className="footer-item-title">Download Our application</h3> */}
            <div className="footer-item-qr-images">
                <div className="footer-item-qr-image">
                    <h5 className="footer-item-title">Get On Google Play</h5>
                    <img
                        src={andriodQr}
                        alt="andriod-Qr"
                        className="andrid-Qr"
                    />
                </div>
                <div className="footer-item-qr-image">
                    <h5 className="footer-item-title">Get On App Store</h5>
                    <img src={iosQr} alt="ios-Qr" className="andrid-Qr" />
                </div>
            </div>
        </div>
    )
}

export default FooterQR

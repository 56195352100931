export const signUpUserNameSlice = (set, get) => ({
    userCredentials: {},
    addUserCredentials: (userName, email) => {
        const newObj = { username: userName, email: email }
        set({ userCredentials: newObj })
    },
    resetUserCredentials: () => {
        set({ userCredentials: {} })
    },
})

export const permissionRoutes = [
  { name: '/user', value: 'user-view' },
  { name: '/user/add', value: 'user-add' },
  { name: '/user/edit', value: 'user-edit' },
  { name: '/user/{id}', value: 'user-detail' },
  { name: '', value: 'user-delete' },
  { name: '', value: 'user-interest-list' },
  { name: '', value: 'user-interest-add' },
  { name: '', value: 'user-change-password' },

  { name: '/gold&silver', value: 'gold-silver-view' },
  { name: '/gold&silver/add', value: 'gold-silver-detail' },
  { name: '/gold&silver/edit', value: 'gold-silver-add-edit' },
  { name: '/gold&silver/delete', value: 'gold-silver-delete' },


  { name: '/advertisement/add', value: 'advertisement-add' },
  { name: '/advertisement/edit/{id}', value: 'advertisement-edit' },
  { name: '/advertisement', value: 'advertisement-status' },
  { name: '/advertisement/delete', value: 'advertisement-delete' },


  { name: '/role', value: 'role-view' },
  { name: '/role/add', value: 'role-add' },
  { name: '/role/edit', value: 'role-edit' },
  { name: '/role/edit', value: 'role-delete' },
  { name: '/role/{id}', value: 'role-detail' },
]

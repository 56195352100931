import classNames from 'classnames'

import { useState, useRef } from 'react'

import { NavLink } from 'react-router-dom'

import './navitem.scss'

import { GoTriangleDown } from 'react-icons/go'
import { ClickOutside } from 'utils/helpers/clickOutside/ClickOutside'
import { useTranslation } from 'react-i18next'

const NavItem = (props) => {
    const [open, setOpen] = useState(false)

    const wrapperRef = useRef()

    ClickOutside(wrapperRef, () => setOpen(false))

    const { t } = useTranslation()

    if (props?.type && props.type === 'hasSubMenu') {
        return (
            <div
                className={classNames('item', 'has-submenu', {
                    open: open,
                })}
                onClick={() => setOpen(!open)}
                ref={wrapperRef}
            >
                <p className="item-desc">
                    {props.item}
                    <GoTriangleDown
                        className={classNames('submenu-icon', { open: open })}
                    />
                </p>
                <div
                    className={classNames('sub-content', {
                        'sub-content-open': open,
                    })}
                >
                    {props.children?.map((data, index) => (
                        <NavLink
                            to={data.props.urlLink}
                            className="sub-item"
                            key={index}
                        >
                            <div className="sub-item-text-container">
                                <span className="sub-item-text">
                                    {t(data.props.item)}
                                </span>
                            </div>
                        </NavLink>
                    ))}
                </div>
            </div>
        )
    } else {
        return props.urlLink ? (
            <NavLink
                exact
                to={props.urlLink}
                className={classNames('item-desc-container', {
                    item: props.type === 'text',
                })}
                activeClassName="is-active"
                onClick={props.handleOnClick}
            >
                {props.icon}
                <p className="item-desc">{t(props.item)}</p>
            </NavLink>
        ) : (
            <div className="item-desc-container">
                <p className="item-desc">{t(props.item)}</p>
            </div>
        )
    }
}

export default NavItem

import React, { memo, useRef } from 'react'
import { PropTypes } from 'prop-types'
import usePrevious from 'hooks/usePrevious/usePrevious'
import { useLayoutEffect } from 'react'
import './singleOtpInput.scss'

const SingleOtpInputComponent = (props) => {
    const { focus, autoFocus, ...rest } = props

    const inputRef = useRef(null)
    const prevFocus = usePrevious(!!focus)
    useLayoutEffect(() => {
        if (inputRef.current) {
            if (focus && autoFocus) {
                inputRef.current.focus()
            }
            if (focus && autoFocus && focus !== prevFocus) {
                inputRef.current.focus()
                inputRef.current.select()
            }
        }
    }, [autoFocus, focus, prevFocus])
    return <input ref={inputRef} {...rest} />
}

const SingleOtpInput = memo(SingleOtpInputComponent)
export default SingleOtpInput

SingleOtpInputComponent.propTypes = {
    focus: PropTypes.bool,
    autoFocus: PropTypes.bool,
}

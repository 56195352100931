import React, {
    useState,
    useImperativeHandle,
    forwardRef,
    useCallback,
    useEffect,
} from 'react'
import { createPortal } from 'react-dom'
import classNames from 'classnames'
import './modal.scss'
import { PropTypes } from 'prop-types'

const modalElement = document.getElementById('modal-root')

// REMINDER: Use this component as a sort of wrapper to wrap your other components like the video sections in it. Just make a normal child component and put it in the children prop of the Modal.

const Modal = ({ children, fade = false, defaultOpened = false }, ref) => {
    const [isOpen, setIsOpen] = useState(defaultOpened)

    const close = useCallback(() => setIsOpen(false), [])

    useImperativeHandle(
        ref,
        () => ({
            open: () => setIsOpen(true),
            close,
        }),
        [close]
    )

    const handleEscape = useCallback((event) => {
        if (event.keyCode === 27) setIsOpen(false)
    }, [])

    useEffect(() => {
        if (isOpen) document.addEventListener('keydown', handleEscape, false)
        return () => {
            document.removeEventListener('keydown', handleEscape, false)
        }
    }, [handleEscape, isOpen])

    return createPortal(
        isOpen ? (
            <div
                className={classNames('modal-container', {
                    'modal-fade': fade,
                })}
            >
                <div className="modal-body">{children}</div>
            </div>
        ) : null,
        modalElement
    )
}

export default forwardRef(Modal)

Modal.propTypes = {
    children: PropTypes.element,
    fade: PropTypes.bool,
    defaultOpened: PropTypes.bool,
    ref: PropTypes.func,
}

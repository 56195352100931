import { nepaliEnglishNumerals } from 'global/constants/dummyData'
import { PropTypes } from 'prop-types'

export function EnglishToNepaliConverter(number) {
    const result = []

    number = String(number)

    let extractedChar = ''

    // Please do note that nepaliEnglishNumerals is an object which contains keys of English numbers and values of their nepali equivalent strings
    if (number.length > 1) {
        for (let i of number) {
            extractedChar = nepaliEnglishNumerals[parseInt(i)]

            if (typeof extractedChar !== 'undefined') {
                result.push(extractedChar)
            } else {
                result.push(i)
            }
        }
        return result.join('')
    }
    if (nepaliEnglishNumerals[parseInt(number)])
        return nepaliEnglishNumerals[parseInt(number)]

    return number
}

EnglishToNepaliConverter.propTypes = {
    number: PropTypes.number,
}

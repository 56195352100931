/* eslint-disable no-unused-vars */
import React from 'react'
import './signIn.scss'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import LabelInput  from 'components/Layout/LabelInput/LabelInput'

import { useFormik } from 'formik'
import { object, string } from 'yup'
import Button  from 'components/Layout/Button/Button'
import { PropTypes } from 'prop-types'
import classNames from 'classnames'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import usePostUserLogin from 'hooks/userAuth/usePostUserLogin'
import { authRoutes } from 'global/constants/appConstants'
import Spinner  from 'components/Spinner/Spinner'
import FacebookAuthentication from '../FacebookAuthentication/FacebookAuthentication'
import GoogleAuthentication from '../GoogleAuthentication/GoogleAuthentication'

const SignIn = ({ show, setCurrentPage, authRef }) => {
    const { t } = useTranslation()

    const [showPassword, setShowPassword] = useState(false)

    const {
        mutate: logInFn,
        isLoading: logInLoading,
        isSuccess: logInSuccess,
    } = usePostUserLogin()

    const schema = object().shape({
        email: string()
            .email(t('errors.email'))
            .required(t('errors.fieldRequired')),
        password: string().required(t('errors.fieldRequired')),
    })

    const handleSubmit = (e) => {
        e.preventDefault()

        if (isValid) {
            logInFn({
                email: values.email,
                password: values.password,
            })
        }
    }

    if (logInSuccess) {
        setCurrentPage(authRoutes.LOGOUT)
        authRef.current.close()
    }

    const handleForgotPasswordClick = (e) => {
        setCurrentPage(authRoutes.FORGOT_PASSWORD)
    }

    const handleGoToSignUp = (e) => {
        setCurrentPage(authRoutes.SIGNUP_USERNAME)
    }

    const {
        handleBlur,
        values,
        setFieldValue,
        errors,
        isValid,
        touched,
        setErrors,
    } = useFormik({
        validationSchema: schema,
        onSubmit: handleSubmit,
        initialValues: {
            email: '',
            password: '',
        },
        initialErrors: {
            email: true,
            password: true,
        },
        enableReinitialize: true,
    })

    useEffect(() => {
        setErrors({})

        // eslint-disable-next-line
    }, [show])

    return (
        <div
            className={classNames('sign-in-container', {
                show: show,
            })}
        >
            <h4 className="sign-in-heading">
                {t('authentication.welcomeToEknepal')}
            </h4>
            <form onSubmit={handleSubmit} className="sign-in-form">
                <LabelInput
                    labelDisplay="none"
                    inputName="email"
                    value={values.email}
                    touchedError={errors.email}
                    handleOnChange={(e) =>
                        setFieldValue('email', e.target.value)
                    }
                    touched={touched.email}
                    handleOnBlur={handleBlur}
                    orientation="vertical"
                    size="small"
                    inputPlaceholder={t('authentication.email')}
                />
                <LabelInput
                    labelDisplay="none"
                    inputType={showPassword ? 'text' : 'password'}
                    inputName="password"
                    value={values.password}
                    touchedError={errors.password}
                    handleOnChange={(e) =>
                        setFieldValue('password', e.target.value)
                    }
                    touched={touched.password}
                    handleOnBlur={handleBlur}
                    orientation="vertical"
                    size="small"
                    inputPlaceholder={t('authentication.password')}
                    icon={showPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                    iconOnClick={() => setShowPassword(!showPassword)}
                />
                <p
                    className="sign-in-form-forgot-password"
                    onClick={handleForgotPasswordClick}
                >
                    {t('authentication.forgotPassword')}
                </p>
                {logInLoading ? (
                    <Button
                        size="widthHundred"
                        bgColor="grey"
                        description={<Spinner isWhite={true} />}
                    />
                ) : (
                    <Button
                        size="widthHundred"
                        bgColor="grey"
                        description={t('buttons.continue')}
                        disabled={!isValid || !show}
                    />
                )}
            </form>
            <div className="sign-in-bottom-container">
                <div className="sign-in-or-divider">
                    {t('authentication.signInSection.or')}
                </div>
                <div className="sign-in-bottom-buttons-container">
                    <FacebookAuthentication />
                    {/* <Button
                        size="widthHundred"
                        description={t(
                            'authentication.signInSection.continueWithGoogle'
                        )}
                        additionalStyles="white-border-grey"
                        img={googleImg}
                        imgAbsolute={true}
                        handleButtonClick={googleButtonClick}
                        disabled={!show}
                    /> */}
                    <GoogleAuthentication
                        setCurrentPage={setCurrentPage}
                        authRef={authRef}
                    />
                </div>
                <div className="sign-in-bottom-sign-up-container">
                    <p>{t('authentication.signInSection.noAccountSignUp')}</p>
                    <p
                        className="sign-in-bottom-sign-up"
                        onClick={handleGoToSignUp}
                    >
                        {t('authentication.signUp')}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default SignIn

SignIn.propTypes = {
    show: PropTypes.bool,
    setCurrentPage: PropTypes.func,
    authRef: PropTypes.object,
}

import {
    AEDImg,
    AUDImg,
    BHDImg,
    CADImg,
    CHFImg,
    CNYImg,
    DKKImg,
    EURImg,
    GBPImg,
    HKDImg,
    INRImg,
    JPYImg,
    KRWImg,
    KWDImg,
    MYRImg,
    NEPImg,
    QARImg,
    SARImg,
    SEKImg,
    SGDImg,
    THBImg,
    USDImg,
    artImg,
    businessImg,
    educationImg,
    entertainmentImg,
    fashionImg,
    healthImg,
    politicsImg,
    realEstateImg,
    scienceImg,
    sportsImg,
    technologyImg,
    travelImg,
    annapurnaTimesImg,
    commentIconImg,
    shareIconImg,
    bookmarkIconImg,
    likeIconDarkImg,
    personImg,
    dashboardListTemplateRadioImg,
    dashboardListTemplateTvImg,
    ap1Img,
    dashboardListTemplateEPaperImg,
    annapurnaMediaNetworkImg,
    nagarikImg,
    nepalSamacharPatraImg,
    rajdhaniImg,
    theHimalayanTimesImg,
    himalayaTimes,
    image97Img,
    image103Img,
    nepalFMImg,
    radioAnnapurnaImg,
    radioNagarikImg,
    abNewsImg,
    imageChannelImg,
    janataNewsImg,
    mountainTvImg,
    primeHdImg,
    sagarmathaTvImg,
    yohoTvImg,
} from 'assets'

import {
    AiOutlineHome,
    AiOutlineDollarCircle,
    AiOutlineGold,
    AiOutlineCalendar,
    AiOutlineCalculator,
} from 'react-icons/ai'
import { HiOutlineNewspaper } from 'react-icons/hi'
import { BsGraphUp, BsCalendarDate } from 'react-icons/bs'
import { GiAries } from 'react-icons/gi'
import { SiUnicode } from 'react-icons/si'

const dummyData = [
    {
        item: 'navbar.home',
        type: 'text',
        urlLink: '/',
        picture: '',
    },
    {
        item: 'navbar.news',
        type: 'text',
        urlLink: '/news',
        picture: '',
    },
    {
        item: 'navbar.forex',
        type: 'text',
        urlLink: '/forex',
        picture: '',
    },
    {
        item: 'navbar.shareMarket',
        type: 'text',
        urlLink: '/share-market',
        picture: '',
    },
    {
        item: 'navbar.horoscope',
        type: 'text',
        urlLink: '/horoscope',
        picture: '',
    },
    {
        item: 'navbar.calculator',
        type: 'text',
        urlLink: '/calculator',
        picture: '',
    },
    {
        item: 'navbar.dateConverter',
        type: 'text',
        urlLink: '/date-converter',
        picture: '',
    },
    {
        item: 'navbar.unicode',
        type: 'text',
        urlLink: '/unicode',
        picture: '',
    },
    // {
    //     item: 'navbar.calendar',
    //     type: 'text',
    //     urlLink: '/calendar',
    //     picture: '',
    // },
    {
        item: 'navbar.goldSilver',
        type: 'text',
        urlLink: '/gold',
        picture: '',
    },
]

const navigationTitles = {
    '/': 'navbar.home',
    '/news': 'navbar.news',
    '/forex': 'navbar.forex',
    '/share-market': 'navbar.shareMarket',
    '/horoscope': 'navbar.horoscope',
    '/calculator': 'navbar.calculator',
    '/date-converter': 'navbar.dateConverter',
    '/unicode': 'navbar.unicode',
    '/calendar': 'navbar.calendar',
    '/gold': 'navbar.goldSilver',
    '/today-share-price': 'shareMarket.todaySharePrice',
}

const drawerDummyData = [
    {
        item: 'navbar.home',
        type: 'text',
        urlLink: '/',
        picture: '',
        icon: <AiOutlineHome />,
    },
    {
        item: 'navbar.news',
        type: 'text',
        urlLink: '/news',
        picture: '',
        icon: <HiOutlineNewspaper />,
    },
    {
        item: 'navbar.forex',
        type: 'text',
        urlLink: '/forex',
        picture: '',
        icon: <AiOutlineDollarCircle />,
    },
    {
        item: 'navbar.shareMarket',
        type: 'text',
        urlLink: '/share-market',
        picture: '',
        icon: <BsGraphUp />,
    },
    {
        item: 'navbar.horoscope',
        type: 'text',
        urlLink: '/horoscope',
        picture: '',
        icon: <GiAries />,
    },
    {
        item: 'navbar.calculator',
        type: 'text',
        urlLink: '/calculator',
        picture: '',
        icon: <AiOutlineCalculator />,
    },
    {
        item: 'navbar.dateConverter',
        type: 'text',
        urlLink: '/date-converter',
        picture: '',
        icon: <BsCalendarDate />,
    },
    {
        item: 'navbar.unicode',
        type: 'text',
        urlLink: '/unicode',
        picture: '',
        icon: <SiUnicode />,
    },
    {
        item: 'navbar.calendar',
        type: 'text',
        urlLink: '/calendar',
        picture: '',
        icon: <AiOutlineCalendar />,
    },
    {
        item: 'navbar.goldSilver',
        type: 'text',
        urlLink: '/gold',
        picture: '',
        icon: <AiOutlineGold />,
    },
]
const countryDetails = [
    {
        ISO3: 'AED',
        unit: '',
        fullName: 'संयुक्त राज्य डलर',
        buyAmount: '११७.१८',
        sellAmount: '११७.७८',
    },
    {
        ISO3: 'AUD',
        unit: '',
        fullName: 'संयुक्त राज्य डलर',
        buyAmount: '११७.१८',
        sellAmount: '११७.७८',
    },
    {
        ISO3: 'BHD',
        unit: '',
        fullName: 'संयुक्त राज्य डलर',
        buyAmount: '११७.१८',
        sellAmount: '११७.७८',
    },
    {
        ISO3: 'CAD',
        unit: '',
        fullName: 'संयुक्त राज्य डलर',
        buyAmount: '११७.१८',
        sellAmount: '११७.७८',
    },
    {
        ISO3: 'CHF',
        unit: '',
        fullName: 'संयुक्त राज्य डलर',
        buyAmount: '११७.१८',
        sellAmount: '११७.७८',
    },
    {
        ISO3: 'CNY',
        unit: '',
        fullName: 'संयुक्त राज्य डलर',
        buyAmount: '११७.१८',
        sellAmount: '११७.७८',
    },
    {
        ISO3: 'DKK',
        unit: '',
        fullName: 'संयुक्त राज्य डलर',
        buyAmount: '११७.१८',
        sellAmount: '११७.७८',
    },
    {
        ISO3: 'EUR',
        unit: 'प्रति १० एकाइ',
        fullName: 'संयुक्त राज्य डलर',
        buyAmount: '११७.१८',
        sellAmount: '११७.७८',
    },
    {
        ISO3: 'GBP',
        unit: '',
        fullName: 'संयुक्त राज्य डलर',
        buyAmount: '११७.१८',
        sellAmount: '११७.७८',
    },
    {
        ISO3: 'HKD',
        unit: '',
        fullName: 'संयुक्त राज्य डलर',
        buyAmount: '११७.१८',
        sellAmount: '११७.७८',
    },
    {
        ISO3: 'INR',
        unit: '',
        fullName: 'संयुक्त राज्य डलर',
        buyAmount: '११७.१८',
        sellAmount: '११७.७८',
    },
    {
        ISO3: 'JPY',
        unit: '',
        fullName: 'संयुक्त राज्य डलर',
        buyAmount: '११७.१८',
        sellAmount: '११७.७८',
    },
    {
        ISO3: 'KRW',
        unit: '',
        fullName: 'संयुक्त राज्य डलर',
        buyAmount: '११७.१८',
        sellAmount: '११७.७८',
    },
    {
        ISO3: 'KWD',
        unit: 'प्रति १०० एकाइ',
        fullName: 'संयुक्त राज्य डलर',
        buyAmount: '११७.१८',
        sellAmount: '११७.७८',
    },
    {
        ISO3: 'MYR',
        unit: '',
        fullName: 'संयुक्त राज्य डलर',
        buyAmount: '११७.१८',
        sellAmount: '११७.७८',
    },
    {
        ISO3: 'NEP',
        unit: '',
        fullName: 'संयुक्त राज्य डलर',
        buyAmount: '११७.१८',
        sellAmount: '११७.७८',
    },
    {
        ISO3: 'QAR',
        unit: '',
        fullName: 'संयुक्त राज्य डलर',
        buyAmount: '११७.१८',
        sellAmount: '११७.७८',
    },
    {
        ISO3: 'SAR',
        unit: '',
        fullName: 'संयुक्त राज्य डलर',
        buyAmount: '११७.१८',
        sellAmount: '११७.७८',
    },
    {
        ISO3: 'SEK',
        unit: '',
        fullName: 'संयुक्त राज्य डलर',
        buyAmount: '११७.१८',
        sellAmount: '११७.७८',
    },
    {
        ISO3: 'SGD',
        unit: '',
        fullName: 'संयुक्त राज्य डलर',
        buyAmount: '११७.१८',
        sellAmount: '११७.७८',
    },
    {
        ISO3: 'THB',
        unit: '',
        fullName: 'संयुक्त राज्य डलर',
        buyAmount: '११७.१८',
        sellAmount: '११७.७८',
    },
    {
        ISO3: 'USD',
        unit: '',
        fullName: 'संयुक्त राज्य डलर',
        buyAmount: '११७.१८',
        sellAmount: '११७.७८',
    },
]
const countryFlags = {
    AED: AEDImg,
    AUD: AUDImg,
    BHD: BHDImg,
    CAD: CADImg,
    CHF: CHFImg,
    CNY: CNYImg,
    DKK: DKKImg,
    EUR: EURImg,
    GBP: GBPImg,
    HKD: HKDImg,
    INR: INRImg,
    JPY: JPYImg,
    KRW: KRWImg,
    KWD: KWDImg,
    MYR: MYRImg,
    NEP: NEPImg,
    QAR: QARImg,
    SAR: SARImg,
    SEK: SEKImg,
    SGD: SGDImg,
    THB: THBImg,
    USD: USDImg,
}

const interestTopicsImages = [
    artImg,
    businessImg,
    educationImg,
    entertainmentImg,
    fashionImg,
    healthImg,
    politicsImg,
    realEstateImg,
    scienceImg,
    sportsImg,
    technologyImg,
    travelImg,
]

const interestTopicsHeadings = [
    'Art',
    'Business',
    'Education',
    'Entertainment',
    'Fashion',
    'Health',
    'Politics',
    'Real Estate',
    'Science',
    'Sports',
    'Technology',
    'Travel',
]

const newsTopics = [
    {
        path: 'all',
        label: 'news.selection.all',
    },
    {
        path: 'politics',
        label: 'news.selection.politics',
    },
    {
        path: 'business',
        label: 'news.selection.business',
    },
    {
        path: 'entertainment',
        label: 'news.selection.entertainment',
    },
    {
        path: 'health',
        label: 'news.selection.health',
    },
    {
        path: 'sports',
        label: 'news.selection.sports',
    },
    {
        path: 'technology',
        label: 'news.selection.technology',
    },
    {
        path: 'education',
        label: 'news.selection.education',
    },
    {
        path: 'art',
        label: 'news.selection.art',
    },
    {
        path: 'migration',
        label: 'news.selection.migration',
    },
    {
        path: 'fashion',
        label: 'news.selection.fashion',
    },
    {
        path: 'science',
        label: 'news.selection.science',
    },
    {
        path: 'real-state',
        label: 'news.selection.realEstate',
    },
]

const ellipseMenuItems = [
    {
        icon: likeIconDarkImg,
        desc: 'Like this news',
    },
    {
        icon: shareIconImg,
        desc: 'Share this news',
    },
    {
        icon: bookmarkIconImg,
        desc: 'Mark as Favorite',
    },
    {
        icon: commentIconImg,
        desc: 'Add Comment',
    },
]

const nepaliEnglishNumerals = {
    1: '१',
    2: '२',
    3: '३',
    4: '४',
    5: '५',
    6: '६',
    7: '७',
    8: '८',
    9: '९',
    0: '०',
}

const englishNepaliNumerals = {
    '१': 1,
    '२': 2,
    '३': 3,
    '४': 4,
    '५': 5,
    '६': 6,
    '७': 7,
    '८': 8,
    '९': 9,
    '०': 0,
}

const commentData = [
    {
        img: personImg,
        userName: 'Joanne Doe',
        commentContent:
            'अर्बौं लुटेकालाई ५० लाख के नै हो र?त्यो पनि कामै नलाग्ने भिर ,पखेरा र खोला दौडेको जग्गा धितो राखे होलान्।थुनामै राखेर पुर्ण फैसला गर्नु पर्ने थियो।दुर्भाग्य !!',
    },
    {
        img: personImg,
        userName: 'Joanne Doe',
        commentContent:
            'अर्बौं लुटेकालाई ५० लाख के नै हो र?त्यो पनि कामै नलाग्ने भिर ,पखेरा र खोला दौडेको जग्गा धितो राखे होलान्।थुनामै राखेर पुर्ण फैसला गर्नु पर्ने थियो।दुर्भाग्य !!',
    },
    {
        img: personImg,
        userName: 'Joanne Doe',
        commentContent:
            'अर्बौं लुटेकालाई ५० लाख के नै हो र?त्यो पनि कामै नलाग्ने भिर ,पखेरा र खोला दौडेको जग्गा धितो राखे होलान्।थुनामै राखेर पुर्ण फैसला गर्नु पर्ने थियो।दुर्भाग्य !!',
    },
    {
        img: personImg,
        userName: 'Joanne Doe',
        commentContent:
            'अर्बौं लुटेकालाई ५० लाख के नै हो र?त्यो पनि कामै नलाग्ने भिर ,पखेरा र खोला दौडेको जग्गा धितो राखे होलान्।थुनामै राखेर पुर्ण फैसला गर्नु पर्ने थियो।दुर्भाग्य !!',
    },
    {
        img: personImg,
        userName: 'Joanne Doe',
        commentContent:
            'अर्बौं लुटेकालाई ५० लाख के नै हो र?त्यो पनि कामै नलाग्ने भिर ,पखेरा र खोला दौडेको जग्गा धितो राखे होलान्।थुनामै राखेर पुर्ण फैसला गर्नु पर्ने थियो।दुर्भाग्य !!',
    },
    {
        img: personImg,
        userName: 'Joanne Doe',
        commentContent:
            'अर्बौं लुटेकालाई ५० लाख के नै हो र?त्यो पनि कामै नलाग्ने भिर ,पखेरा र खोला दौडेको जग्गा धितो राखे होलान्।थुनामै राखेर पुर्ण फैसला गर्नु पर्ने थियो।दुर्भाग्य !!',
    },
    {
        img: personImg,
        userName: 'Joanne Doe',
        commentContent:
            'अर्बौं लुटेकालाई ५० लाख के नै हो र?त्यो पनि कामै नलाग्ने भिर ,पखेरा र खोला दौडेको जग्गा धितो राखे होलान्।थुनामै राखेर पुर्ण फैसला गर्नु पर्ने थियो।दुर्भाग्य !!',
    },
    {
        img: personImg,
        userName: 'Joanne Doe',
        commentContent:
            'अर्बौं लुटेकालाई ५० लाख के नै हो र?त्यो पनि कामै नलाग्ने भिर ,पखेरा र खोला दौडेको जग्गा धितो राखे होलान्।थुनामै राखेर पुर्ण फैसला गर्नु पर्ने थियो।दुर्भाग्य !!',
    },
    {
        img: personImg,
        userName: 'Joanne Doe',
        commentContent:
            'अर्बौं लुटेकालाई ५० लाख के नै हो र?त्यो पनि कामै नलाग्ने भिर ,पखेरा र खोला दौडेको जग्गा धितो राखे होलान्।थुनामै राखेर पुर्ण फैसला गर्नु पर्ने थियो।दुर्भाग्य !!',
    },
    {
        img: personImg,
        userName: 'Joanne Doe',
        commentContent:
            'अर्बौं लुटेकालाई ५० लाख के नै हो र?त्यो पनि कामै नलाग्ने भिर ,पखेरा र खोला दौडेको जग्गा धितो राखे होलान्।थुनामै राखेर पुर्ण फैसला गर्नु पर्ने थियो।दुर्भाग्य !!',
    },
    {
        img: personImg,
        userName: 'Joanne Doe',
        commentContent:
            'अर्बौं लुटेकालाई ५० लाख के नै हो र?त्यो पनि कामै नलाग्ने भिर ,पखेरा र खोला दौडेको जग्गा धितो राखे होलान्।थुनामै राखेर पुर्ण फैसला गर्नु पर्ने थियो।दुर्भाग्य !!',
    },
    {
        img: personImg,
        userName: 'Joanne Doe',
        commentContent:
            'अर्बौं लुटेकालाई ५० लाख के नै हो र?त्यो पनि कामै नलाग्ने भिर ,पखेरा र खोला दौडेको जग्गा धितो राखे होलान्।थुनामै राखेर पुर्ण फैसला गर्नु पर्ने थियो।दुर्भाग्य !!',
    },
    {
        img: personImg,
        userName: 'Joanne Doe',
        commentContent:
            'अर्बौं लुटेकालाई ५० लाख के नै हो र?त्यो पनि कामै नलाग्ने भिर ,पखेरा र खोला दौडेको जग्गा धितो राखे होलान्।थुनामै राखेर पुर्ण फैसला गर्नु पर्ने थियो।दुर्भाग्य !!',
    },
    {
        img: personImg,
        userName: 'Joanne Doe',
        commentContent:
            'अर्बौं लुटेकालाई ५० लाख के नै हो र?त्यो पनि कामै नलाग्ने भिर ,पखेरा र खोला दौडेको जग्गा धितो राखे होलान्।थुनामै राखेर पुर्ण फैसला गर्नु पर्ने थियो।दुर्भाग्य !!',
    },
    {
        img: personImg,
        userName: 'Joanne Doe',
        commentContent:
            'अर्बौं लुटेकालाई ५० लाख के नै हो र?त्यो पनि कामै नलाग्ने भिर ,पखेरा र खोला दौडेको जग्गा धितो राखे होलान्।थुनामै राखेर पुर्ण फैसला गर्नु पर्ने थियो।दुर्भाग्य !!',
    },
]

const widgetData = [
    {
        heading: ['Symbol', 'LTP', '%Change', 'Qty'],
        payload: [
            {
                symbol: 'UPCL',
                first: '300.28',
                second: '9.36',
                third: '9,500',
            },
            {
                symbol: 'UPCL',
                first: '300.28',
                second: '9.36',
                third: '9,500',
            },
            {
                symbol: 'UPCL',
                first: '300.28',
                second: '9.36',
                third: '9,500',
            },
            {
                symbol: 'UPCL',
                first: '300.28',
                second: '9.36',
                third: '9,500',
            },
            {
                symbol: 'UPCL',
                first: '300.28',
                second: '9.36',
                third: '9,500',
            },
            {
                symbol: 'UPCL',
                first: '300.28',
                second: '9.36',
                third: '9,500',
            },
            {
                symbol: 'UPCL',
                first: '300.28',
                second: '9.36',
                third: '9,500',
            },
            {
                symbol: 'UPCL',
                first: '300.28',
                second: '9.36',
                third: '9,500',
            },
            {
                symbol: 'UPCL',
                first: '300.28',
                second: '9.36',
                third: '9,500',
            },
        ],
    },
    {
        heading: ['मुद्रा', 'कित्ता', 'खरिद दर', 'बिक्री दर'],
        payload: [
            {
                symbol: 'AED',
                first: '1',
                second: '११७.१८',
                third: '११७.७८',
            },
            {
                symbol: 'AUD',
                first: '1',
                second: '११७.१८',
                third: '११७.७८',
            },
            {
                symbol: 'BHD',
                first: '1',
                second: '११७.१८',
                third: '११७.७८',
            },
            {
                symbol: 'CAD',
                first: '1',
                second: '११७.१८',
                third: '११७.७८',
            },
            {
                symbol: 'CHF',
                first: '1',
                second: '११७.१८',
                third: '११७.७८',
            },
            {
                symbol: 'CNY',
                first: '1',
                second: '११७.१८',
                third: '११७.७८',
            },
            {
                symbol: 'DKK',
                first: '1',
                second: '११७.१८',
                third: '११७.७८',
            },
            {
                symbol: 'EUR',
                first: '1',
                second: '११७.१८',
                third: '११७.७८',
            },
            {
                symbol: 'GBP',
                first: '1',
                second: '११७.१८',
                third: '११७.७८',
            },
            {
                symbol: 'HKD',
                first: '1',
                second: '११७.१८',
                third: '११७.७८',
            },
            {
                symbol: 'INR',
                first: '1',
                second: '११७.१८',
                third: '११७.७८',
            },
            {
                symbol: 'JPY',
                first: '1',
                second: '११७.१८',
                third: '११७.७८',
            },
            {
                symbol: 'KRW',
                first: '1',
                second: '११७.१८',
                third: '११७.७८',
            },
            {
                symbol: 'KWD',
                first: '1',
                second: '११७.१८',
                third: '११७.७८',
            },
            {
                symbol: 'MYR',
                first: '1',
                second: '११७.१८',
                third: '११७.७८',
            },
            {
                symbol: 'NEP',
                first: '1',
                second: '११७.१८',
                third: '११७.७८',
            },
            {
                symbol: 'QAR',
                first: '1',
                second: '११७.१८',
                third: '११७.७८',
            },
            {
                symbol: 'SAR',
                first: '1',
                second: '११७.१८',
                third: '११७.७८',
            },
            {
                symbol: 'SEK',
                first: '1',
                second: '११७.१८',
                third: '११७.७८',
            },
            {
                symbol: 'SGD',
                first: '1',
                second: '११७.१८',
                third: '११७.७८',
            },
            {
                symbol: 'THB',
                first: '1',
                second: '११७.१८',
                third: '११७.७८',
            },
            {
                symbol: 'USD',
                first: '1',
                second: '११७.१८',
                third: '११७.७८',
            },
        ],
    },
]

const shareMarketData = [
    {
        indexName: 'नेप्से',
        amount: '2878.46',
        changedAmountNum: '1.88',
        changedAmountPercent: '0.07',
        result: 'profit',
    },
    {
        indexName: 'नेप्से',
        amount: '2878.46',
        changedAmountNum: '1.88',
        changedAmountPercent: '0.07',
        result: 'loss',
    },
    {
        indexName: 'नेप्से',
        amount: '2878.46',
        changedAmountNum: '1.88',
        changedAmountPercent: '0.07',
        result: 'profit',
    },
    {
        indexName: 'नेप्से',
        amount: '2878.46',
        changedAmountNum: '1.88',
        changedAmountPercent: '0.07',
        result: 'loss',
    },
    {
        indexName: 'नेप्से',
        amount: '2878.46',
        changedAmountNum: '1.88',
        changedAmountPercent: '0.07',
        result: 'profit',
    },
    {
        indexName: 'नेप्से',
        amount: '2878.46',
        changedAmountNum: '1.88',
        changedAmountPercent: '0.07',
        result: 'loss',
    },
    {
        indexName: 'नेप्से',
        amount: '2878.46',
        changedAmountNum: '1.88',
        changedAmountPercent: '0.07',
        result: 'profit',
    },
    {
        indexName: 'नेप्से',
        amount: '2878.46',
        changedAmountNum: '1.88',
        changedAmountPercent: '0.07',
        result: 'loss',
    },
    {
        indexName: 'नेप्से',
        amount: '2878.46',
        changedAmountNum: '1.88',
        changedAmountPercent: '0.07',
        result: 'profit',
    },
    {
        indexName: 'नेप्से',
        amount: '2878.46',
        changedAmountNum: '1.88',
        changedAmountPercent: '0.07',
        result: 'loss',
    },
    {
        indexName: 'नेप्से',
        amount: '2878.46',
        changedAmountNum: '1.88',
        changedAmountPercent: '0.07',
        result: 'profit',
    },
    {
        indexName: 'नेप्से',
        amount: '2878.46',
        changedAmountNum: '1.88',
        changedAmountPercent: '0.07',
        result: 'profit',
    },
    {
        indexName: 'नेप्से',
        amount: '2878.46',
        changedAmountNum: '1.88',
        changedAmountPercent: '0.07',
        result: 'profit',
    },
    {
        indexName: 'नेप्से',
        amount: '2878.46',
        changedAmountNum: '1.88',
        changedAmountPercent: '0.07',
        result: 'profit',
    },
    {
        indexName: 'नेप्से',
        amount: '2878.46',
        changedAmountNum: '1.88',
        changedAmountPercent: '0.07',
        result: 'profit',
    },
]

const dashboardListTemplateData = {
    ePaper: {
        title: 'ई पेपर',
        urlLink: '/e-paper',
        img: dashboardListTemplateEPaperImg,
        payload: [
            {
                name: 'Annapurna Media',
                image: annapurnaMediaNetworkImg,
            },
            {
                name: 'Annapurna Times',
                image: annapurnaTimesImg,
            },
            {
                name: 'Nagarik',
                image: nagarikImg,
            },
            {
                name: 'Nepal Samachar Patra',
                image: nepalSamacharPatraImg,
            },
            {
                name: 'Rajdhani',
                image: rajdhaniImg,
            },
            {
                name: 'The Himalayan Times',
                image: theHimalayanTimesImg,
            },
            {
                name: 'Annapurna Media',
                image: annapurnaMediaNetworkImg,
            },
            {
                name: 'Annapurna Times',
                image: annapurnaTimesImg,
            },
            {
                name: 'Nagarik',
                image: nagarikImg,
            },
            {
                name: 'Nepal Samachar Patra',
                image: nepalSamacharPatraImg,
            },
            {
                name: 'Rajdhani',
                image: rajdhaniImg,
            },
            {
                name: 'The Himalayan Times',
                image: theHimalayanTimesImg,
            },
            {
                name: 'Annapurna Media',
                image: annapurnaMediaNetworkImg,
            },
            {
                name: 'Annapurna Times',
                image: annapurnaTimesImg,
            },
            {
                name: 'Nagarik',
                image: nagarikImg,
            },
            {
                name: 'Nepal Samachar Patra',
                image: nepalSamacharPatraImg,
            },
            {
                name: 'Rajdhani',
                image: rajdhaniImg,
            },
            {
                name: 'The Himalayan Times',
                image: theHimalayanTimesImg,
            },
            {
                name: 'Annapurna Media',
                image: annapurnaMediaNetworkImg,
            },
            {
                name: 'Annapurna Times',
                image: annapurnaTimesImg,
            },
            {
                name: 'Nagarik',
                image: nagarikImg,
            },
            {
                name: 'Nepal Samachar Patra',
                image: nepalSamacharPatraImg,
            },
            {
                name: 'Rajdhani',
                image: rajdhaniImg,
            },
            {
                name: 'The Himalayan Times',
                image: theHimalayanTimesImg,
            },
        ],
    },
    fm: {
        title: 'एफएमहरु',
        urlLink: '/fm',
        img: dashboardListTemplateRadioImg,
        payload: [
            {
                name: 'इमेज एफएम ९७.९',
                image: image97Img,
            },
            {
                name: 'इमेज न्युज एफएम',
                image: image103Img,
            },
            {
                name: 'नेपाल एफएम',
                image: nepalFMImg,
            },
            {
                name: 'Radio Annapurna',
                image: radioAnnapurnaImg,
            },
            {
                name: 'रेडियो नागरिक',
                image: radioNagarikImg,
            },
            {
                name: 'इमेज एफएम ९७.९',
                image: image97Img,
            },
            {
                name: 'इमेज न्युज एफएम',
                image: image103Img,
            },
            {
                name: 'नेपाल एफएम',
                image: nepalFMImg,
            },
            {
                name: 'Radio Annapurna',
                image: radioAnnapurnaImg,
            },
            {
                name: 'रेडियो नागरिक',
                image: radioNagarikImg,
            },
            {
                name: 'इमेज एफएम ९७.९',
                image: image97Img,
            },
            {
                name: 'इमेज न्युज एफएम',
                image: image103Img,
            },
            {
                name: 'नेपाल एफएम',
                image: nepalFMImg,
            },
            {
                name: 'Radio Annapurna',
                image: radioAnnapurnaImg,
            },
            {
                name: 'रेडियो नागरिक',
                image: radioNagarikImg,
            },
            {
                name: 'इमेज एफएम ९७.९',
                image: image97Img,
            },
            {
                name: 'इमेज न्युज एफएम',
                image: image103Img,
            },
            {
                name: 'नेपाल एफएम',
                image: nepalFMImg,
            },
            {
                name: 'Radio Annapurna',
                image: radioAnnapurnaImg,
            },
            {
                name: 'रेडियो नागरिक',
                image: radioNagarikImg,
            },
        ],
    },
    tv: {
        title: 'टिभीहरु',
        urlLink: '/tv',
        img: dashboardListTemplateTvImg,
        payload: [
            {
                name: 'एभिन्यूज खबर',
                image: abNewsImg,
            },
            {
                name: ' एपी १ एचडि',
                image: ap1Img,
            },
            {
                name: ' ईमेज च्यानल',
                image: imageChannelImg,
            },
            {
                name: ' जनता टिभी',
                image: janataNewsImg,
            },
            {
                name: 'माउन्टेन टिभी',
                image: mountainTvImg,
            },
            {
                name: 'Prime Television',
                image: primeHdImg,
            },
            {
                name: 'सगरमाथा टेलिभिजन ',
                image: sagarmathaTvImg,
            },
            {
                name: 'YoHo television',
                image: yohoTvImg,
            },
            {
                name: 'एभिन्यूज खबर',
                image: abNewsImg,
            },
            {
                name: ' एपी १ एचडि',
                image: ap1Img,
            },
            {
                name: ' ईमेज च्यानल',
                image: imageChannelImg,
            },
            {
                name: ' जनता टिभी',
                image: janataNewsImg,
            },
            {
                name: 'माउन्टेन टिभी',
                image: mountainTvImg,
            },
            {
                name: 'Prime Television',
                image: primeHdImg,
            },
            {
                name: 'सगरमाथा टेलिभिजन ',
                image: sagarmathaTvImg,
            },
            {
                name: 'YoHo television',
                image: yohoTvImg,
            },
            {
                name: 'एभिन्यूज खबर',
                image: abNewsImg,
            },
            {
                name: ' एपी १ एचडि',
                image: ap1Img,
            },
            {
                name: ' ईमेज च्यानल',
                image: imageChannelImg,
            },
            {
                name: ' जनता टिभी',
                image: janataNewsImg,
            },
            {
                name: 'माउन्टेन टिभी',
                image: mountainTvImg,
            },
            {
                name: 'Prime Television',
                image: primeHdImg,
            },
            {
                name: 'सगरमाथा टेलिभिजन ',
                image: sagarmathaTvImg,
            },
            {
                name: 'YoHo television',
                image: yohoTvImg,
            },
            {
                name: 'एभिन्यूज खबर',
                image: abNewsImg,
            },
            {
                name: ' एपी १ एचडि',
                image: ap1Img,
            },
            {
                name: ' ईमेज च्यानल',
                image: imageChannelImg,
            },
            {
                name: ' जनता टिभी',
                image: janataNewsImg,
            },
            {
                name: 'माउन्टेन टिभी',
                image: mountainTvImg,
            },
            {
                name: 'Prime Television',
                image: primeHdImg,
            },
            {
                name: 'सगरमाथा टेलिभिजन ',
                image: sagarmathaTvImg,
            },
            {
                name: 'YoHo television',
                image: yohoTvImg,
            },
        ],
    },
}

const calculatorHeadings = [
    'calculator.shareCalculator.title',
    'calculator.emiCalculator.title',
    'calculator.landCalculator.title',
    'calculator.weightCalculator',
    'calculator.temperatureCalculator',
    // 'मिति कनवर्टर',
    // 'युनिकोड',
]

const calculatorHeadingsSelect = [
    { label: 'calculator.shareCalculator', value: 0 },
    { label: 'calculator.emiCalculator.title', value: 1 },
    { label: 'calculator.landCalculator.title', value: 2 },
    { label: 'calculator.weightCalculator', value: 3 },
    { label: 'calculator.temperatureCalculator', value: 4 },
]

const nepaliMonths = {
    0: 'वैशाख',
    1: 'जेठ',
    2: 'असार',
    3: 'श्रावण',
    4: 'भदौ',
    5: 'आश्विन',
    6: 'कार्तिक',
    7: 'मंसिर',
    8: 'पुष',
    9: 'माघ',
    10: 'फाल्गुन',
    11: 'चैत्र',
}

const nepaliMonthsIndex = {
    वैशाख: 0,
    जेठ: 1,
    असार: 2,
    श्रावण: 3,
    भदौ: 4,
    आश्विन: 5,
    कार्तिक: 6,
    मंसिर: 7,
    पुष: 8,
    माघ: 9,
    फाल्गुन: 10,
    चैत्र: 11,
}

const nepaliToEnglishMonths = {
    वैशाख: 'Baisakh',
    जेठ: 'Jestha',
    असार: 'Ashadh',
    श्रावण: 'Shrawan',
    भदौ: 'Bhadau',
    आश्विन: 'Ashwin',
    कार्तिक: 'Kartik',
    मंसिर: 'Mangsir',
    पुष: 'Poush',
    माघ: 'Magh',
    फाल्गुन: 'Falgun',
    चैत्र: 'Chaitra',
}

const englishToEnglishMonths = {
    0: 'Jan',
    1: 'Feb',
    2: 'Mar',
    3: 'Apr',
    4: 'May',
    5: 'June',
    6: 'July',
    7: 'Aug',
    8: 'Sep',
    9: 'Oct',
    10: 'Nov',
    11: 'Dec',
}
const englishToEnglishDay = {
    0: 'Sunday',
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
}
const englishNumberToEnglishDay = {
    0: 'आइतबार',
    1: 'सोमबार',
    2: 'मंगलबार ',
    3: 'बुधवार',
    4: 'विहीवार',
    5: 'शुक्रबार',
    6: 'शनिवार',
}

const englishToNepaliDays = {
    sunday: 'आइतबार',
    monday: 'सोमबार',
    tuesday: 'मंगलबार',
    wednesday: 'बुधवार',
    thursday: 'विहीवार',
    friday: 'शुक्रबार',
    saturday: 'शनिवार',
}

const ePaperData = [
    {
        title: 'Annapurna Post',
        image: annapurnaMediaNetworkImg,
        url: 'https://epaper.amn.media/',
    },
    {
        title: 'Rajdhani',
        image: rajdhaniImg,
        url: 'https://rajdhanidaily.com/rajdhanidaily-epaper/',
    },
    {
        title: 'Nepal Samachar Patra',
        image: nepalSamacharPatraImg,
        url: 'https://epaper.newsofnepal.com/',
    },
    {
        title: 'Nagarik',
        image: nagarikImg,
        url: 'https://epaper.nagariknetwork.com/nagarik/src/login.php',
    },
    {
        title: 'Himalaya Times',
        image: himalayaTimes,
        url: 'https://ehimalayatimes.com/',
    },
    {
        title: 'Kamana',
        image: nepalSamacharPatraImg,
        url: 'https://epaper.newsofnepal.com/category/%E0%A4%95%E0%A4%BE%E0%A4%AE%E0%A4%A8%E0%A4%BE/',
    },
    {
        title: 'Sadhana',
        image: nepalSamacharPatraImg,
        url: 'https://epaper.newsofnepal.com/category/%E0%A4%B8%E0%A4%BE%E0%A4%A7%E0%A4%A8%E0%A4%BE/',
    },
    {
        title: 'Shukrabar',
        image: nagarikImg,
        url: 'https://shukrabar.nagariknetwork.com/',
    },
    {
        title: 'Pariwar',
        image: nagarikImg,
        url: 'https://epaper.nagariknetwork.com/paenglisriwar/src/pariwar.php',
    },
]

const advertisementTypes = [
    // REMINDER: The format is: {id:type}
    {
        1: 'Leaderboard Ads',
        size: '1177 * 90',
    },
    { 2: 'Dashboard Tab Ads', size: '391 * 73' },
    { 3: 'Dashboard Content Ads', size: '713 * 143' },
    { 4: 'Pop-up Type 1 Ads', size: '1200 * 720' },
    { 5: 'Pop-up Type 2 Ads', size: '1080 * 1080' },
    { 6: 'News Section Ads', size: '375 * 465' },
    { 7: 'Half Page Ads', size: '300 * 600' },
    { 8: 'Mobile Pop-up full screen Ads', size: '374 * 777' },
    { 9: 'Mobile in-between content Ads', size: '414 * 231' },
]

const forexData = {
    status: {
        code: 200,
    },
    errors: {
        validation: null,
    },
    params: {
        date: '2021-12-29',
        from: null,
        to: null,
        post_type: null,
        per_page: null,
        page: null,
        slug: null,
        q: null,
    },
    data: {
        payload: {
            date: '2021-12-29',
            published_on: '2021-12-29 00:00:34',
            modified_on: '2021-12-28 16:06:50',
            rates: [
                {
                    currency: {
                        iso3: 'INR',
                        name: 'Indian Rupee',
                        unit: 100,
                    },
                    buy: '160.00',
                    sell: '160.15',
                },
                {
                    currency: {
                        iso3: 'USD',
                        name: 'U.S. Dollar',
                        unit: 1,
                    },
                    buy: '119.15',
                    sell: '119.75',
                },
                {
                    currency: {
                        iso3: 'EUR',
                        name: 'European Euro',
                        unit: 1,
                    },
                    buy: '135.04',
                    sell: '135.72',
                },
                {
                    currency: {
                        iso3: 'GBP',
                        name: 'UK Pound Sterling',
                        unit: 1,
                    },
                    buy: '160.23',
                    sell: '161.03',
                },
                {
                    currency: {
                        iso3: 'CHF',
                        name: 'Swiss Franc',
                        unit: 1,
                    },
                    buy: '130.06',
                    sell: '130.71',
                },
                {
                    currency: {
                        iso3: 'AUD',
                        name: 'Australian Dollar',
                        unit: 1,
                    },
                    buy: '86.37',
                    sell: '86.80',
                },
                {
                    currency: {
                        iso3: 'CAD',
                        name: 'Canadian Dollar',
                        unit: 1,
                    },
                    buy: '93.09',
                    sell: '93.55',
                },
                {
                    currency: {
                        iso3: 'SGD',
                        name: 'Singapore Dollar',
                        unit: 1,
                    },
                    buy: '88.00',
                    sell: '88.44',
                },
                {
                    currency: {
                        iso3: 'JPY',
                        name: 'Japanese Yen',
                        unit: 10,
                    },
                    buy: '10.38',
                    sell: '10.43',
                },
                {
                    currency: {
                        iso3: 'CNY',
                        name: 'Chinese Yuan',
                        unit: 1,
                    },
                    buy: '18.71',
                    sell: '18.80',
                },
                {
                    currency: {
                        iso3: 'SAR',
                        name: 'Saudi Arabian Riyal',
                        unit: 1,
                    },
                    buy: '31.72',
                    sell: '31.88',
                },
                {
                    currency: {
                        iso3: 'QAR',
                        name: 'Qatari Riyal',
                        unit: 1,
                    },
                    buy: '32.72',
                    sell: '32.89',
                },
                {
                    currency: {
                        iso3: 'THB',
                        name: 'Thai Baht',
                        unit: 1,
                    },
                    buy: '3.55',
                    sell: '3.57',
                },
                {
                    currency: {
                        iso3: 'AED',
                        name: 'UAE Dirham',
                        unit: 1,
                    },
                    buy: '32.44',
                    sell: '32.60',
                },
                {
                    currency: {
                        iso3: 'MYR',
                        name: 'Malaysian Ringgit',
                        unit: 1,
                    },
                    buy: '28.50',
                    sell: '28.64',
                },
                {
                    currency: {
                        iso3: 'KRW',
                        name: 'South Korean Won',
                        unit: 100,
                    },
                    buy: '10.03',
                    sell: '10.08',
                },
                {
                    currency: {
                        iso3: 'SEK',
                        name: 'Swedish Kroner',
                        unit: 1,
                    },
                    buy: '13.14',
                    sell: '13.21',
                },
                {
                    currency: {
                        iso3: 'DKK',
                        name: 'Danish Kroner',
                        unit: 1,
                    },
                    buy: '18.16',
                    sell: '18.25',
                },
                {
                    currency: {
                        iso3: 'HKD',
                        name: 'Hong Kong Dollar',
                        unit: 1,
                    },
                    buy: '15.28',
                    sell: '15.35',
                },
                {
                    currency: {
                        iso3: 'KWD',
                        name: 'Kuwaity Dinar',
                        unit: 1,
                    },
                    buy: '393.86',
                    sell: '395.84',
                },
                {
                    currency: {
                        iso3: 'BHD',
                        name: 'Bahrain Dinar',
                        unit: 1,
                    },
                    buy: '316.05',
                    sell: '317.64',
                },
            ],
        },
    },
    pagination: {
        page: null,
        pages: null,
        per_page: null,
        total: null,
        links: {
            prev: null,
            next: null,
        },
    },
}

const dateConverterDataMonthDay = [
    {
        month_en: 'January',
        month_np: '	वैशाख',
        day_en: [
            { value: 1, label: 1 },
            { value: 2, label: 2 },
            { value: 3, label: 3 },
            { value: 4, label: 4 },
            { value: 5, label: 5 },
            { value: 6, label: 6 },
            { value: 7, label: 7 },
            { value: 8, label: 8 },
            { value: 9, label: 9 },
            { value: 10, label: 10 },
            { value: 11, label: 11 },
            { value: 12, label: 12 },
            { value: 13, label: 13 },
            { value: 14, label: 14 },
            { value: 15, label: 15 },
            { value: 16, label: 16 },
            { value: 17, label: 17 },
            { value: 18, label: 18 },
            { value: 19, label: 19 },
            { value: 20, label: 20 },
            { value: 21, label: 21 },
            { value: 22, label: 22 },
            { value: 23, label: 23 },
            { value: 24, label: 24 },
            { value: 25, label: 25 },
            { value: 26, label: 26 },
            { value: 27, label: 27 },
            { value: 28, label: 28 },
            { value: 29, label: 29 },
            { value: 30, label: 30 },
            { value: 31, label: 31 },
        ],
        day_np: [
            { value: 1, label: 1 },
            { value: 2, label: 2 },
            { value: 3, label: 3 },
            { value: 4, label: 4 },
            { value: 5, label: 5 },
            { value: 6, label: 6 },
            { value: 7, label: 7 },
            { value: 8, label: 8 },
            { value: 9, label: 9 },
            { value: 10, label: 10 },
            { value: 11, label: 11 },
            { value: 12, label: 12 },
            { value: 13, label: 13 },
            { value: 14, label: 14 },
            { value: 15, label: 15 },
            { value: 16, label: 16 },
            { value: 17, label: 17 },
            { value: 18, label: 18 },
            { value: 19, label: 19 },
            { value: 20, label: 20 },
            { value: 21, label: 21 },
            { value: 22, label: 22 },
            { value: 23, label: 23 },
            { value: 24, label: 24 },
            { value: 25, label: 25 },
            { value: 26, label: 26 },
            { value: 27, label: 27 },
            { value: 28, label: 28 },
            { value: 29, label: 29 },
            { value: 30, label: 30 },
            { value: 31, label: 31 },
        ],
    },
    {
        month_en: 'February',
        month_np: 'जेठ',
        day_en: [
            { value: 1, label: 1 },
            { value: 2, label: 2 },
            { value: 3, label: 3 },
            { value: 4, label: 4 },
            { value: 5, label: 5 },
            { value: 6, label: 6 },
            { value: 7, label: 7 },
            { value: 8, label: 8 },
            { value: 9, label: 9 },
            { value: 10, label: 10 },
            { value: 11, label: 11 },
            { value: 12, label: 12 },
            { value: 13, label: 13 },
            { value: 14, label: 14 },
            { value: 15, label: 15 },
            { value: 16, label: 16 },
            { value: 17, label: 17 },
            { value: 18, label: 18 },
            { value: 19, label: 19 },
            { value: 20, label: 20 },
            { value: 21, label: 21 },
            { value: 22, label: 22 },
            { value: 23, label: 23 },
            { value: 24, label: 24 },
            { value: 25, label: 25 },
            { value: 26, label: 26 },
            { value: 27, label: 27 },
            { value: 28, label: 28 },
            { value: 29, label: 29 },
        ],
        day_np: [
            { value: 1, label: 1 },
            { value: 2, label: 2 },
            { value: 3, label: 3 },
            { value: 4, label: 4 },
            { value: 5, label: 5 },
            { value: 6, label: 6 },
            { value: 7, label: 7 },
            { value: 8, label: 8 },
            { value: 9, label: 9 },
            { value: 10, label: 10 },
            { value: 11, label: 11 },
            { value: 12, label: 12 },
            { value: 13, label: 13 },
            { value: 14, label: 14 },
            { value: 15, label: 15 },
            { value: 16, label: 16 },
            { value: 17, label: 17 },
            { value: 18, label: 18 },
            { value: 19, label: 19 },
            { value: 20, label: 20 },
            { value: 21, label: 21 },
            { value: 22, label: 22 },
            { value: 23, label: 23 },
            { value: 24, label: 24 },
            { value: 25, label: 25 },
            { value: 26, label: 26 },
            { value: 27, label: 27 },
            { value: 28, label: 28 },
            { value: 29, label: 29 },
            { value: 30, label: 30 },
            { value: 31, label: 31 },
            { value: 32, label: 32 },
        ],
    },
    {
        month_en: 'March',
        month_np: 'असार',
        day_en: [
            { value: 1, label: 1 },
            { value: 2, label: 2 },
            { value: 3, label: 3 },
            { value: 4, label: 4 },
            { value: 5, label: 5 },
            { value: 6, label: 6 },
            { value: 7, label: 7 },
            { value: 8, label: 8 },
            { value: 9, label: 9 },
            { value: 10, label: 10 },
            { value: 11, label: 11 },
            { value: 12, label: 12 },
            { value: 13, label: 13 },
            { value: 14, label: 14 },
            { value: 15, label: 15 },
            { value: 16, label: 16 },
            { value: 17, label: 17 },
            { value: 18, label: 18 },
            { value: 19, label: 19 },
            { value: 20, label: 20 },
            { value: 21, label: 21 },
            { value: 22, label: 22 },
            { value: 23, label: 23 },
            { value: 24, label: 24 },
            { value: 25, label: 25 },
            { value: 26, label: 26 },
            { value: 27, label: 27 },
            { value: 28, label: 28 },
            { value: 29, label: 29 },
            { value: 30, label: 30 },
            { value: 31, label: 31 },
        ],
        day_np: [
            { value: 1, label: 1 },
            { value: 2, label: 2 },
            { value: 3, label: 3 },
            { value: 4, label: 4 },
            { value: 5, label: 5 },
            { value: 6, label: 6 },
            { value: 7, label: 7 },
            { value: 8, label: 8 },
            { value: 9, label: 9 },
            { value: 10, label: 10 },
            { value: 11, label: 11 },
            { value: 12, label: 12 },
            { value: 13, label: 13 },
            { value: 14, label: 14 },
            { value: 15, label: 15 },
            { value: 16, label: 16 },
            { value: 17, label: 17 },
            { value: 18, label: 18 },
            { value: 19, label: 19 },
            { value: 20, label: 20 },
            { value: 21, label: 21 },
            { value: 22, label: 22 },
            { value: 23, label: 23 },
            { value: 24, label: 24 },
            { value: 25, label: 25 },
            { value: 26, label: 26 },
            { value: 27, label: 27 },
            { value: 28, label: 28 },
            { value: 29, label: 29 },
            { value: 30, label: 30 },
            { value: 31, label: 31 },
            { value: 32, label: 32 },
        ],
    },
    {
        month_en: 'April',
        month_np: 'साउन',
        day_en: [
            { value: 1, label: 1 },
            { value: 2, label: 2 },
            { value: 3, label: 3 },
            { value: 4, label: 4 },
            { value: 5, label: 5 },
            { value: 6, label: 6 },
            { value: 7, label: 7 },
            { value: 8, label: 8 },
            { value: 9, label: 9 },
            { value: 10, label: 10 },
            { value: 11, label: 11 },
            { value: 12, label: 12 },
            { value: 13, label: 13 },
            { value: 14, label: 14 },
            { value: 15, label: 15 },
            { value: 16, label: 16 },
            { value: 17, label: 17 },
            { value: 18, label: 18 },
            { value: 19, label: 19 },
            { value: 20, label: 20 },
            { value: 21, label: 21 },
            { value: 22, label: 22 },
            { value: 23, label: 23 },
            { value: 24, label: 24 },
            { value: 25, label: 25 },
            { value: 26, label: 26 },
            { value: 27, label: 27 },
            { value: 28, label: 28 },
            { value: 29, label: 29 },
            { value: 30, label: 30 },
        ],
        day_np: [
            { value: 1, label: 1 },
            { value: 2, label: 2 },
            { value: 3, label: 3 },
            { value: 4, label: 4 },
            { value: 5, label: 5 },
            { value: 6, label: 6 },
            { value: 7, label: 7 },
            { value: 8, label: 8 },
            { value: 9, label: 9 },
            { value: 10, label: 10 },
            { value: 11, label: 11 },
            { value: 12, label: 12 },
            { value: 13, label: 13 },
            { value: 14, label: 14 },
            { value: 15, label: 15 },
            { value: 16, label: 16 },
            { value: 17, label: 17 },
            { value: 18, label: 18 },
            { value: 19, label: 19 },
            { value: 20, label: 20 },
            { value: 21, label: 21 },
            { value: 22, label: 22 },
            { value: 23, label: 23 },
            { value: 24, label: 24 },
            { value: 25, label: 25 },
            { value: 26, label: 26 },
            { value: 27, label: 27 },
            { value: 28, label: 28 },
            { value: 29, label: 29 },
            { value: 30, label: 30 },
            { value: 31, label: 31 },
            { value: 32, label: 32 },
        ],
    },
    {
        month_en: 'May',
        month_np: 'भदौ',
        day_en: [
            { value: 1, label: 1 },
            { value: 2, label: 2 },
            { value: 3, label: 3 },
            { value: 4, label: 4 },
            { value: 5, label: 5 },
            { value: 6, label: 6 },
            { value: 7, label: 7 },
            { value: 8, label: 8 },
            { value: 9, label: 9 },
            { value: 10, label: 10 },
            { value: 11, label: 11 },
            { value: 12, label: 12 },
            { value: 13, label: 13 },
            { value: 14, label: 14 },
            { value: 15, label: 15 },
            { value: 16, label: 16 },
            { value: 17, label: 17 },
            { value: 18, label: 18 },
            { value: 19, label: 19 },
            { value: 20, label: 20 },
            { value: 21, label: 21 },
            { value: 22, label: 22 },
            { value: 23, label: 23 },
            { value: 24, label: 24 },
            { value: 25, label: 25 },
            { value: 26, label: 26 },
            { value: 27, label: 27 },
            { value: 28, label: 28 },
            { value: 29, label: 29 },
            { value: 30, label: 30 },
            { value: 31, label: 31 },
        ],
        day_np: [
            { value: 1, label: 1 },
            { value: 2, label: 2 },
            { value: 3, label: 3 },
            { value: 4, label: 4 },
            { value: 5, label: 5 },
            { value: 6, label: 6 },
            { value: 7, label: 7 },
            { value: 8, label: 8 },
            { value: 9, label: 9 },
            { value: 10, label: 10 },
            { value: 11, label: 11 },
            { value: 12, label: 12 },
            { value: 13, label: 13 },
            { value: 14, label: 14 },
            { value: 15, label: 15 },
            { value: 16, label: 16 },
            { value: 17, label: 17 },
            { value: 18, label: 18 },
            { value: 19, label: 19 },
            { value: 20, label: 20 },
            { value: 21, label: 21 },
            { value: 22, label: 22 },
            { value: 23, label: 23 },
            { value: 24, label: 24 },
            { value: 25, label: 25 },
            { value: 26, label: 26 },
            { value: 27, label: 27 },
            { value: 28, label: 28 },
            { value: 29, label: 29 },
            { value: 30, label: 30 },
            { value: 31, label: 31 },
            { value: 32, label: 32 },
        ],
    },
    {
        month_en: 'June',
        month_np: 'आश्विन',
        day_en: [
            { value: 1, label: 1 },
            { value: 2, label: 2 },
            { value: 3, label: 3 },
            { value: 4, label: 4 },
            { value: 5, label: 5 },
            { value: 6, label: 6 },
            { value: 7, label: 7 },
            { value: 8, label: 8 },
            { value: 9, label: 9 },
            { value: 10, label: 10 },
            { value: 11, label: 11 },
            { value: 12, label: 12 },
            { value: 13, label: 13 },
            { value: 14, label: 14 },
            { value: 15, label: 15 },
            { value: 16, label: 16 },
            { value: 17, label: 17 },
            { value: 18, label: 18 },
            { value: 19, label: 19 },
            { value: 20, label: 20 },
            { value: 21, label: 21 },
            { value: 22, label: 22 },
            { value: 23, label: 23 },
            { value: 24, label: 24 },
            { value: 25, label: 25 },
            { value: 26, label: 26 },
            { value: 27, label: 27 },
            { value: 28, label: 28 },
            { value: 29, label: 29 },
            { value: 30, label: 30 },
        ],
        day_np: [
            { value: 1, label: 1 },
            { value: 2, label: 2 },
            { value: 3, label: 3 },
            { value: 4, label: 4 },
            { value: 5, label: 5 },
            { value: 6, label: 6 },
            { value: 7, label: 7 },
            { value: 8, label: 8 },
            { value: 9, label: 9 },
            { value: 10, label: 10 },
            { value: 11, label: 11 },
            { value: 12, label: 12 },
            { value: 13, label: 13 },
            { value: 14, label: 14 },
            { value: 15, label: 15 },
            { value: 16, label: 16 },
            { value: 17, label: 17 },
            { value: 18, label: 18 },
            { value: 19, label: 19 },
            { value: 20, label: 20 },
            { value: 21, label: 21 },
            { value: 22, label: 22 },
            { value: 23, label: 23 },
            { value: 24, label: 24 },
            { value: 25, label: 25 },
            { value: 26, label: 26 },
            { value: 27, label: 27 },
            { value: 28, label: 28 },
            { value: 29, label: 29 },
            { value: 30, label: 30 },
            { value: 31, label: 31 },
        ],
    },
    {
        month_en: 'July',
        month_np: 'कार्तिक',
        day_en: [
            { value: 1, label: 1 },
            { value: 2, label: 2 },
            { value: 3, label: 3 },
            { value: 4, label: 4 },
            { value: 5, label: 5 },
            { value: 6, label: 6 },
            { value: 7, label: 7 },
            { value: 8, label: 8 },
            { value: 9, label: 9 },
            { value: 10, label: 10 },
            { value: 11, label: 11 },
            { value: 12, label: 12 },
            { value: 13, label: 13 },
            { value: 14, label: 14 },
            { value: 15, label: 15 },
            { value: 16, label: 16 },
            { value: 17, label: 17 },
            { value: 18, label: 18 },
            { value: 19, label: 19 },
            { value: 20, label: 20 },
            { value: 21, label: 21 },
            { value: 22, label: 22 },
            { value: 23, label: 23 },
            { value: 24, label: 24 },
            { value: 25, label: 25 },
            { value: 26, label: 26 },
            { value: 27, label: 27 },
            { value: 28, label: 28 },
            { value: 29, label: 29 },
            { value: 30, label: 30 },
            { value: 31, label: 31 },
        ],
        day_np: [
            { value: 1, label: 1 },
            { value: 2, label: 2 },
            { value: 3, label: 3 },
            { value: 4, label: 4 },
            { value: 5, label: 5 },
            { value: 6, label: 6 },
            { value: 7, label: 7 },
            { value: 8, label: 8 },
            { value: 9, label: 9 },
            { value: 10, label: 10 },
            { value: 11, label: 11 },
            { value: 12, label: 12 },
            { value: 13, label: 13 },
            { value: 14, label: 14 },
            { value: 15, label: 15 },
            { value: 16, label: 16 },
            { value: 17, label: 17 },
            { value: 18, label: 18 },
            { value: 19, label: 19 },
            { value: 20, label: 20 },
            { value: 21, label: 21 },
            { value: 22, label: 22 },
            { value: 23, label: 23 },
            { value: 24, label: 24 },
            { value: 25, label: 25 },
            { value: 26, label: 26 },
            { value: 27, label: 27 },
            { value: 28, label: 28 },
            { value: 29, label: 29 },
            { value: 30, label: 30 },
        ],
    },
    {
        month_en: 'August',
        month_np: '	मंसिर',
        day_en: [
            { value: 1, label: 1 },
            { value: 2, label: 2 },
            { value: 3, label: 3 },
            { value: 4, label: 4 },
            { value: 5, label: 5 },
            { value: 6, label: 6 },
            { value: 7, label: 7 },
            { value: 8, label: 8 },
            { value: 9, label: 9 },
            { value: 10, label: 10 },
            { value: 11, label: 11 },
            { value: 12, label: 12 },
            { value: 13, label: 13 },
            { value: 14, label: 14 },
            { value: 15, label: 15 },
            { value: 16, label: 16 },
            { value: 17, label: 17 },
            { value: 18, label: 18 },
            { value: 19, label: 19 },
            { value: 20, label: 20 },
            { value: 21, label: 21 },
            { value: 22, label: 22 },
            { value: 23, label: 23 },
            { value: 24, label: 24 },
            { value: 25, label: 25 },
            { value: 26, label: 26 },
            { value: 27, label: 27 },
            { value: 28, label: 28 },
            { value: 29, label: 29 },
            { value: 30, label: 30 },
            { value: 31, label: 31 },
        ],
        day_np: [
            { value: 1, label: 1 },
            { value: 2, label: 2 },
            { value: 3, label: 3 },
            { value: 4, label: 4 },
            { value: 5, label: 5 },
            { value: 6, label: 6 },
            { value: 7, label: 7 },
            { value: 8, label: 8 },
            { value: 9, label: 9 },
            { value: 10, label: 10 },
            { value: 11, label: 11 },
            { value: 12, label: 12 },
            { value: 13, label: 13 },
            { value: 14, label: 14 },
            { value: 15, label: 15 },
            { value: 16, label: 16 },
            { value: 17, label: 17 },
            { value: 18, label: 18 },
            { value: 19, label: 19 },
            { value: 20, label: 20 },
            { value: 21, label: 21 },
            { value: 22, label: 22 },
            { value: 23, label: 23 },
            { value: 24, label: 24 },
            { value: 25, label: 25 },
            { value: 26, label: 26 },
            { value: 27, label: 27 },
            { value: 28, label: 28 },
            { value: 29, label: 29 },
            { value: 30, label: 30 },
        ],
    },
    {
        month_en: 'September',
        month_np: 'पुष ',
        day_en: [
            { value: 1, label: 1 },
            { value: 2, label: 2 },
            { value: 3, label: 3 },
            { value: 4, label: 4 },
            { value: 5, label: 5 },
            { value: 6, label: 6 },
            { value: 7, label: 7 },
            { value: 8, label: 8 },
            { value: 9, label: 9 },
            { value: 10, label: 10 },
            { value: 11, label: 11 },
            { value: 12, label: 12 },
            { value: 13, label: 13 },
            { value: 14, label: 14 },
            { value: 15, label: 15 },
            { value: 16, label: 16 },
            { value: 17, label: 17 },
            { value: 18, label: 18 },
            { value: 19, label: 19 },
            { value: 20, label: 20 },
            { value: 21, label: 21 },
            { value: 22, label: 22 },
            { value: 23, label: 23 },
            { value: 24, label: 24 },
            { value: 25, label: 25 },
            { value: 26, label: 26 },
            { value: 27, label: 27 },
            { value: 28, label: 28 },
            { value: 29, label: 29 },
            { value: 30, label: 30 },
        ],
        day_np: [
            { value: 1, label: 1 },
            { value: 2, label: 2 },
            { value: 3, label: 3 },
            { value: 4, label: 4 },
            { value: 5, label: 5 },
            { value: 6, label: 6 },
            { value: 7, label: 7 },
            { value: 8, label: 8 },
            { value: 9, label: 9 },
            { value: 10, label: 10 },
            { value: 11, label: 11 },
            { value: 12, label: 12 },
            { value: 13, label: 13 },
            { value: 14, label: 14 },
            { value: 15, label: 15 },
            { value: 16, label: 16 },
            { value: 17, label: 17 },
            { value: 18, label: 18 },
            { value: 19, label: 19 },
            { value: 20, label: 20 },
            { value: 21, label: 21 },
            { value: 22, label: 22 },
            { value: 23, label: 23 },
            { value: 24, label: 24 },
            { value: 25, label: 25 },
            { value: 26, label: 26 },
            { value: 27, label: 27 },
            { value: 28, label: 28 },
            { value: 29, label: 29 },
            { value: 30, label: 30 },
        ],
    },
    {
        month_en: 'October',
        month_np: 'माघ',
        day_en: [
            { value: 1, label: 1 },
            { value: 2, label: 2 },
            { value: 3, label: 3 },
            { value: 4, label: 4 },
            { value: 5, label: 5 },
            { value: 6, label: 6 },
            { value: 7, label: 7 },
            { value: 8, label: 8 },
            { value: 9, label: 9 },
            { value: 10, label: 10 },
            { value: 11, label: 11 },
            { value: 12, label: 12 },
            { value: 13, label: 13 },
            { value: 14, label: 14 },
            { value: 15, label: 15 },
            { value: 16, label: 16 },
            { value: 17, label: 17 },
            { value: 18, label: 18 },
            { value: 19, label: 19 },
            { value: 20, label: 20 },
            { value: 21, label: 21 },
            { value: 22, label: 22 },
            { value: 23, label: 23 },
            { value: 24, label: 24 },
            { value: 25, label: 25 },
            { value: 26, label: 26 },
            { value: 27, label: 27 },
            { value: 28, label: 28 },
            { value: 29, label: 29 },
            { value: 30, label: 30 },
            { value: 31, label: 31 },
        ],
        day_np: [
            { value: 1, label: 1 },
            { value: 2, label: 2 },
            { value: 3, label: 3 },
            { value: 4, label: 4 },
            { value: 5, label: 5 },
            { value: 6, label: 6 },
            { value: 7, label: 7 },
            { value: 8, label: 8 },
            { value: 9, label: 9 },
            { value: 10, label: 10 },
            { value: 11, label: 11 },
            { value: 12, label: 12 },
            { value: 13, label: 13 },
            { value: 14, label: 14 },
            { value: 15, label: 15 },
            { value: 16, label: 16 },
            { value: 17, label: 17 },
            { value: 18, label: 18 },
            { value: 19, label: 19 },
            { value: 20, label: 20 },
            { value: 21, label: 21 },
            { value: 22, label: 22 },
            { value: 23, label: 23 },
            { value: 24, label: 24 },
            { value: 25, label: 25 },
            { value: 26, label: 26 },
            { value: 27, label: 27 },
            { value: 28, label: 28 },
            { value: 29, label: 29 },
            { value: 30, label: 30 },
        ],
    },
    {
        month_en: 'November',
        month_np: '	फागुन ',
        day_en: [
            { value: 1, label: 1 },
            { value: 2, label: 2 },
            { value: 3, label: 3 },
            { value: 4, label: 4 },
            { value: 5, label: 5 },
            { value: 6, label: 6 },
            { value: 7, label: 7 },
            { value: 8, label: 8 },
            { value: 9, label: 9 },
            { value: 10, label: 10 },
            { value: 11, label: 11 },
            { value: 12, label: 12 },
            { value: 13, label: 13 },
            { value: 14, label: 14 },
            { value: 15, label: 15 },
            { value: 16, label: 16 },
            { value: 17, label: 17 },
            { value: 18, label: 18 },
            { value: 19, label: 19 },
            { value: 20, label: 20 },
            { value: 21, label: 21 },
            { value: 22, label: 22 },
            { value: 23, label: 23 },
            { value: 24, label: 24 },
            { value: 25, label: 25 },
            { value: 26, label: 26 },
            { value: 27, label: 27 },
            { value: 28, label: 28 },
            { value: 29, label: 29 },
            { value: 30, label: 30 },
        ],
        day_np: [
            { value: 1, label: 1 },
            { value: 2, label: 2 },
            { value: 3, label: 3 },
            { value: 4, label: 4 },
            { value: 5, label: 5 },
            { value: 6, label: 6 },
            { value: 7, label: 7 },
            { value: 8, label: 8 },
            { value: 9, label: 9 },
            { value: 10, label: 10 },
            { value: 11, label: 11 },
            { value: 12, label: 12 },
            { value: 13, label: 13 },
            { value: 14, label: 14 },
            { value: 15, label: 15 },
            { value: 16, label: 16 },
            { value: 17, label: 17 },
            { value: 18, label: 18 },
            { value: 19, label: 19 },
            { value: 20, label: 20 },
            { value: 21, label: 21 },
            { value: 22, label: 22 },
            { value: 23, label: 23 },
            { value: 24, label: 24 },
            { value: 25, label: 25 },
            { value: 26, label: 26 },
            { value: 27, label: 27 },
            { value: 28, label: 28 },
            { value: 29, label: 29 },
            { value: 30, label: 30 },
        ],
    },
    {
        month_en: 'December',
        month_np: 'चैत्र',
        day_en: [
            { value: 1, label: 1 },
            { value: 2, label: 2 },
            { value: 3, label: 3 },
            { value: 4, label: 4 },
            { value: 5, label: 5 },
            { value: 6, label: 6 },
            { value: 7, label: 7 },
            { value: 8, label: 8 },
            { value: 9, label: 9 },
            { value: 10, label: 10 },
            { value: 11, label: 11 },
            { value: 12, label: 12 },
            { value: 13, label: 13 },
            { value: 14, label: 14 },
            { value: 15, label: 15 },
            { value: 16, label: 16 },
            { value: 17, label: 17 },
            { value: 18, label: 18 },
            { value: 19, label: 19 },
            { value: 20, label: 20 },
            { value: 21, label: 21 },
            { value: 22, label: 22 },
            { value: 23, label: 23 },
            { value: 24, label: 24 },
            { value: 25, label: 25 },
            { value: 26, label: 26 },
            { value: 27, label: 27 },
            { value: 28, label: 28 },
            { value: 29, label: 29 },
            { value: 30, label: 30 },
            { value: 31, label: 31 },
        ],
        day_np: [
            { value: 1, label: 1 },
            { value: 2, label: 2 },
            { value: 3, label: 3 },
            { value: 4, label: 4 },
            { value: 5, label: 5 },
            { value: 6, label: 6 },
            { value: 7, label: 7 },
            { value: 8, label: 8 },
            { value: 9, label: 9 },
            { value: 10, label: 10 },
            { value: 11, label: 11 },
            { value: 12, label: 12 },
            { value: 13, label: 13 },
            { value: 14, label: 14 },
            { value: 15, label: 15 },
            { value: 16, label: 16 },
            { value: 17, label: 17 },
            { value: 18, label: 18 },
            { value: 19, label: 19 },
            { value: 20, label: 20 },
            { value: 21, label: 21 },
            { value: 22, label: 22 },
            { value: 23, label: 23 },
            { value: 24, label: 24 },
            { value: 25, label: 25 },
            { value: 26, label: 26 },
            { value: 27, label: 27 },
            { value: 28, label: 28 },
            { value: 29, label: 29 },
            { value: 30, label: 30 },
            { value: 31, label: 31 },
        ],
    },
]

const dateConverterMonthEn = [
    { label: 'January', value: '0' },
    { label: 'February', value: '1' },
    { label: 'March', value: '2' },
    { label: 'April', value: '3' },
    { label: 'May', value: '4' },
    { label: 'June', value: '5' },
    { label: 'July', value: '6' },
    { label: 'August', value: '7' },
    { label: 'September', value: '8' },
    { label: 'October', value: '9' },
    { label: 'November', value: '10' },
    { label: 'December', value: '11' },
]

const dateConverterMonthEnShort = [
    { label: 'Jan', value: '0' },
    { label: 'Feb', value: '1' },
    { label: 'March', value: '2' },
    { label: 'April', value: '3' },
    { label: 'May', value: '4' },
    { label: 'June', value: '5' },
    { label: 'July', value: '6' },
    { label: 'August', value: '7' },
    { label: 'Sept', value: '8' },
    { label: 'Oct', value: '9' },
    { label: 'Nov', value: '10' },
    { label: 'Dec', value: '11' },
]

const dateConverterMonthNp = [
    { value: '0', label: 'Baisakh' },
    { value: '1', label: 'Jestha' },
    { value: '2', label: 'Ashadh' },
    { value: '3', label: 'Shrawan' },
    { value: '4', label: 'Bhadau' },
    { value: '5', label: 'Ashwin' },
    { value: '6', label: 'Kartik' },
    { value: '7', label: 'Mangsir' },
    { value: '8', label: 'Poush' },
    { value: '9', label: 'Magh' },
    { value: '10', label: 'Falgun' },
    { value: '11', label: 'Chaitra' },
]

const dateConverterMonthId = {
    January: 0,
    February: 1,
    March: 2,
    April: 3,
    May: 4,
    June: 5,
    July: 6,
    August: 7,
    September: 8,
    October: 9,
    November: 10,
    December: 11,
}

const dateConverterDataYear = {
    en: [
        { value: 2033, label: 2033 },
        { value: 2032, label: 2032 },
        { value: 2031, label: 2031 },
        { value: 2030, label: 2030 },
        { value: 2029, label: 2029 },
        { value: 2028, label: 2028 },
        { value: 2027, label: 2027 },
        { value: 2026, label: 2026 },
        { value: 2025, label: 2025 },
        { value: 2024, label: 2024 },
        { value: 2023, label: 2023 },
        { value: 2022, label: 2022 },
        { value: 2021, label: 2021 },
        { value: 2020, label: 2020 },
        { value: 2019, label: 2019 },
        { value: 2018, label: 2018 },
        { value: 2017, label: 2017 },
        { value: 2016, label: 2016 },
        { value: 2015, label: 2015 },
        { value: 2014, label: 2014 },
        { value: 2013, label: 2013 },
        { value: 2012, label: 2012 },
        { value: 2011, label: 2011 },
        { value: 2010, label: 2010 },
        { value: 2009, label: 2009 },
        { value: 2008, label: 2008 },
        { value: 2007, label: 2007 },
        { value: 2006, label: 2006 },
        { value: 2005, label: 2005 },
        { value: 2004, label: 2004 },
        { value: 2003, label: 2003 },
        { value: 2002, label: 2002 },
        { value: 2001, label: 2001 },
        { value: 2000, label: 2000 },
        { value: 1999, label: 1999 },
        { value: 1998, label: 1998 },
        { value: 1997, label: 1997 },
        { value: 1996, label: 1996 },
        { value: 1995, label: 1995 },
        { value: 1994, label: 1994 },
        { value: 1993, label: 1993 },
        { value: 1992, label: 1992 },
        { value: 1991, label: 1991 },
        { value: 1990, label: 1990 },
        { value: 1989, label: 1989 },
        { value: 1988, label: 1988 },
        { value: 1987, label: 1987 },
        { value: 1986, label: 1986 },
        { value: 1985, label: 1985 },
        { value: 1984, label: 1984 },
        { value: 1983, label: 1983 },
        { value: 1982, label: 1982 },
        { value: 1981, label: 1981 },
        { value: 1980, label: 1980 },
        { value: 1979, label: 1979 },
        { value: 1978, label: 1978 },
        { value: 1977, label: 1977 },
        { value: 1976, label: 1976 },
        { value: 1975, label: 1975 },
        { value: 1974, label: 1974 },
        { value: 1973, label: 1973 },
        { value: 1972, label: 1972 },
        { value: 1971, label: 1971 },
        { value: 1970, label: 1970 },
        { value: 1969, label: 1969 },
        { value: 1968, label: 1968 },
        { value: 1967, label: 1967 },
        { value: 1966, label: 1966 },
        { value: 1965, label: 1965 },
        { value: 1964, label: 1964 },
        { value: 1963, label: 1963 },
        { value: 1962, label: 1962 },
        { value: 1961, label: 1961 },
        { value: 1960, label: 1960 },
        { value: 1959, label: 1959 },
        { value: 1958, label: 1958 },
        { value: 1957, label: 1957 },
        { value: 1956, label: 1956 },
        { value: 1955, label: 1955 },
        { value: 1954, label: 1954 },
        { value: 1953, label: 1953 },
        { value: 1952, label: 1952 },
        { value: 1951, label: 1951 },
        { value: 1950, label: 1950 },
        { value: 1949, label: 1949 },
        { value: 1948, label: 1948 },
        { value: 1947, label: 1947 },
        { value: 1946, label: 1946 },
        { value: 1945, label: 1945 },
        { value: 1944, label: 1944 },
    ],
    np: [
        { value: 2089, label: 2089 },
        { value: 2088, label: 2088 },
        { value: 2087, label: 2087 },
        { value: 2086, label: 2086 },
        { value: 2085, label: 2085 },
        { value: 2084, label: 2084 },
        { value: 2083, label: 2083 },
        { value: 2082, label: 2082 },
        { value: 2081, label: 2081 },
        { value: 2080, label: 2080 },
        { value: 2079, label: 2079 },
        { value: 2078, label: 2078 },
        { value: 2077, label: 2077 },
        { value: 2076, label: 2076 },
        { value: 2075, label: 2075 },
        { value: 2074, label: 2074 },
        { value: 2073, label: 2073 },
        { value: 2072, label: 2072 },
        { value: 2071, label: 2071 },
        { value: 2070, label: 2070 },
        { value: 2069, label: 2069 },
        { value: 2068, label: 2068 },
        { value: 2067, label: 2067 },
        { value: 2066, label: 2066 },
        { value: 2065, label: 2065 },
        { value: 2064, label: 2064 },
        { value: 2063, label: 2063 },
        { value: 2062, label: 2062 },
        { value: 2061, label: 2061 },
        { value: 2060, label: 2060 },
        { value: 2059, label: 2059 },
        { value: 2058, label: 2058 },
        { value: 2057, label: 2057 },
        { value: 2056, label: 2056 },
        { value: 2055, label: 2055 },
        { value: 2054, label: 2054 },
        { value: 2053, label: 2053 },
        { value: 2052, label: 2052 },
        { value: 2051, label: 2051 },
        { value: 2050, label: 2050 },
        { value: 2049, label: 2049 },
        { value: 2048, label: 2048 },
        { value: 2047, label: 2047 },
        { value: 2046, label: 2046 },
        { value: 2045, label: 2045 },
        { value: 2044, label: 2044 },
        { value: 2043, label: 2043 },
        { value: 2042, label: 2042 },
        { value: 2041, label: 2041 },
        { value: 2040, label: 2040 },
        { value: 2039, label: 2039 },
        { value: 2038, label: 2038 },
        { value: 2037, label: 2037 },
        { value: 2036, label: 2036 },
        { value: 2035, label: 2035 },
        { value: 2034, label: 2034 },
        { value: 2033, label: 2033 },
        { value: 2032, label: 2032 },
        { value: 2031, label: 2031 },
        { value: 2030, label: 2030 },
        { value: 2029, label: 2029 },
        { value: 2028, label: 2028 },
        { value: 2027, label: 2027 },
        { value: 2026, label: 2026 },
        { value: 2025, label: 2025 },
        { value: 2024, label: 2024 },
        { value: 2023, label: 2023 },
        { value: 2022, label: 2022 },
        { value: 2021, label: 2021 },
        { value: 2020, label: 2020 },
        { value: 2019, label: 2019 },
        { value: 2018, label: 2018 },
        { value: 2017, label: 2017 },
        { value: 2016, label: 2016 },
        { value: 2015, label: 2015 },
        { value: 2014, label: 2014 },
        { value: 2013, label: 2013 },
        { value: 2012, label: 2012 },
        { value: 2011, label: 2011 },
        { value: 2010, label: 2010 },
        { value: 2009, label: 2009 },
        { value: 2008, label: 2008 },
        { value: 2007, label: 2007 },
        { value: 2006, label: 2006 },
        { value: 2005, label: 2005 },
        { value: 2004, label: 2004 },
        { value: 2003, label: 2003 },
        { value: 2002, label: 2002 },
        { value: 2001, label: 2001 },
        { value: 2000, label: 2000 },
    ],
}

const forexWidgetHeading = [
    'forex.currency',
    'forex.unit',
    'forex.buyingRate',
    'forex.sellingRate',
]

const shareWidgetHeading = [
    'shareMarket.symbol',
    'shareMarket.ltp',
    'shareMarket.percentChange',
    'shareMarket.quantity',
]

const shareCardData = {
    status: 'ok',
    message: 'Index listed successfully.',
    payload: [
        {
            id: 1,
            trade_date: '2022-01-24',
            nepse: 2990.31,
            sensitive: 123456,
            float: 4577,
            sensitive_float: 7634,
            banking: 1930.98,
            trading: 3080.3,
            hotel_and_tourism: 3245.37,
            development_bank: 5107.09,
            hydro_power: 3429.19,
            finance: 2551.87,
            non_life_insurance: 13089.84,
            manufacturing_and_processing: 7504.12,
            other: 2205.96,
            microfinance: 5551.78,
            life_insurance: 16199.39,
            mutual_fund: 660.78,
            investment: 93.77,
            market_capitalization: 4623842307,
            sensitive_market_capitalization: 91325793375,
            float_market_capitalization: 12345630,
            sensitive_float_market_capitalization: 4321120,
            nepse_point_change: 0,
            nepse_percentage_change: 0,
            sensitive_point_change: 0,
            sensitive_percentage_change: 0,
            float_point_change: 0,
            float_percentage_change: 0,
            sensitive_float_point_change: 0,
            sensitive_float_percentage_change: 0,
            banking_point_change: 0,
            banking_percentage_change: 0,
            trading_point_change: 0,
            trading_percentage_change: 0,
            hotel_and_tourism_point_change: 0,
            hotel_and_tourism_percentage_change: 0,
            development_bank_point_change: 0,
            development_bank_percentage_change: 0,
            hydro_power_point_change: 0,
            hydro_power_percentage_change: 0,
            finance_point_change: 0,
            finance_percentage_change: 0,
            non_life_insurance_point_change: 0,
            non_life_insurance_percentage_change: 0,
            manufacturing_and_processing_point_change: 0,
            manufacturing_and_processing_percentage_change: 0,
            other_point_change: 0,
            other_percentage_change: 0,
            microfinance_point_change: 0,
            microfinance_percentage_change: 0,
            life_insurance_point_change: 0,
            life_insurance_percentage_change: 0,
            mutual_fund_point_change: 0,
            mutual_fund_percentage_change: 0,
            investment_point_change: 0,
            investment_percentage_change: 0,
            market_capitalization_point_change: 0,
            market_capitalization_percentage_change: 0,
            sensitive_market_capitalization_point_change: 0,
            sensitive_market_capitalization_percentage_change: 0,
            float_market_capitalization_point_change: 0,
            float_market_capitalization_percentage_change: 0,
            sensitive_float_market_capitalization_point_change: 0,
            sensitive_float_market_capitalization_percentage_change: 0,
            created_at: '2022-02-21T04:46:22.000000Z',
            updated_at: '2022-02-21T04:46:22.000000Z',
        },
    ],
    meta_data: {
        pagination: {
            total: 1,
            per_page: 15,
            last_page: 1,
            current_page: 1,
            from: 1,
            to: 1,
        },
    },
}

const closePriceData = {
    status: 'ok',
    message: 'Close price listed successfully.',
    payload: [
        {
            id: 3,
            trade_date: '2022-01-20',
            company_id: 2,
            close_price: 542,
            open_price: 550,
            high_price: 559.9,
            low_price: 540,
            total_traded_quantity: 367013,
            total_traded_value: 200739654.5,
            total_trades: 1887,
            previous_day_close_price: 560,
            average_traded_price: 546.96,
            week_high: 785,
            week_low: 230,
            market_capitalization: 9374.4,
            point_change: -18,
            percentage_change: -3.32,
            created_at: '2022-02-17T06:09:33.000000Z',
            updated_at: '2022-02-17T06:09:33.000000Z',
            company: {
                id: 2,
                sector_id: 3,
                rts_id: 2,
                company_name: 'Standard Chartered Bank Limited',
                symbol: 'SCB',
                address: null,
                phone: null,
                email: 'Legal.Nepal@sc.com',
                website: null,
                face_value: '100',
                listed_share: '94294537',
                listed_status: 'Active',
                management_head: null,
                promoter_holding: null,
                public_holding: null,
                government_holding: null,
                foreign_ownership: null,
                created_at: '2022-02-10T04:35:37.000000Z',
                updated_at: '2022-02-10T04:35:37.000000Z',
            },
        },
        {
            id: 2,
            trade_date: '2022-01-20',
            company_id: 1,
            close_price: 433,
            open_price: 440,
            high_price: 442,
            low_price: 426.3,
            total_traded_quantity: 60921,
            total_traded_value: 26352303.2,
            total_trades: 401,
            previous_day_close_price: 440,
            average_traded_price: 432.57,
            week_high: 620,
            week_low: 398,
            market_capitalization: 57103.67,
            point_change: -7,
            percentage_change: 0,
            created_at: '2022-02-17T06:09:33.000000Z',
            updated_at: '2022-02-17T06:09:33.000000Z',
            company: {
                id: 1,
                sector_id: 2,
                rts_id: 2,
                company_name: 'Nabil Bank Limited',
                symbol: 'NABIL',
                address: null,
                phone: null,
                email: 'prakash.basukala@nabilbank.com',
                website: null,
                face_value: '100',
                listed_share: '184961869',
                listed_status: 'Active',
                management_head: null,
                promoter_holding: null,
                public_holding: null,
                government_holding: null,
                foreign_ownership: null,
                created_at: '2022-02-10T04:35:37.000000Z',
                updated_at: '2022-02-10T04:35:37.000000Z',
            },
        },
        {
            id: 1,
            trade_date: '2022-01-20',
            company_id: 4,
            close_price: 1509.9,
            open_price: 1517,
            high_price: 1520,
            low_price: 1490,
            total_traded_quantity: 4552,
            total_traded_value: 6840635.7,
            total_trades: 91,
            previous_day_close_price: 1521,
            average_traded_price: 1502.78,
            week_high: 1938,
            week_low: 1077,
            market_capitalization: 4634.23,
            point_change: -11.1,
            percentage_change: 1,
            created_at: '2022-02-17T06:09:33.000000Z',
            updated_at: '2022-02-17T06:09:33.000000Z',
            company: {
                id: 4,
                sector_id: 3,
                rts_id: 2,
                company_name: 'Standard Chartered Bank Limited',
                symbol: 'ACLBSL',
                address: null,
                phone: null,
                email: 'Legal.Nepal@sc.com',
                website: null,
                face_value: '100',
                listed_share: '94294537',
                listed_status: 'Active',
                management_head: null,
                promoter_holding: null,
                public_holding: null,
                government_holding: null,
                foreign_ownership: null,
                created_at: '2022-02-10T04:35:37.000000Z',
                updated_at: '2022-02-10T04:35:37.000000Z',
            },
        },
        {
            id: 3,
            trade_date: '2022-01-20',
            company_id: 2,
            close_price: 542,
            open_price: 550,
            high_price: 559.9,
            low_price: 540,
            total_traded_quantity: 367013,
            total_traded_value: 200739654.5,
            total_trades: 1887,
            previous_day_close_price: 560,
            average_traded_price: 546.96,
            week_high: 785,
            week_low: 230,
            market_capitalization: 9374.4,
            point_change: -18,
            percentage_change: -3.32,
            created_at: '2022-02-17T06:09:33.000000Z',
            updated_at: '2022-02-17T06:09:33.000000Z',
            company: {
                id: 2,
                sector_id: 3,
                rts_id: 2,
                company_name: 'Standard Chartered Bank Limited',
                symbol: 'SCB',
                address: null,
                phone: null,
                email: 'Legal.Nepal@sc.com',
                website: null,
                face_value: '100',
                listed_share: '94294537',
                listed_status: 'Active',
                management_head: null,
                promoter_holding: null,
                public_holding: null,
                government_holding: null,
                foreign_ownership: null,
                created_at: '2022-02-10T04:35:37.000000Z',
                updated_at: '2022-02-10T04:35:37.000000Z',
            },
        },
        {
            id: 2,
            trade_date: '2022-01-20',
            company_id: 1,
            close_price: 433,
            open_price: 440,
            high_price: 442,
            low_price: 426.3,
            total_traded_quantity: 60921,
            total_traded_value: 26352303.2,
            total_trades: 401,
            previous_day_close_price: 440,
            average_traded_price: 432.57,
            week_high: 620,
            week_low: 398,
            market_capitalization: 57103.67,
            point_change: -7,
            percentage_change: 0,
            created_at: '2022-02-17T06:09:33.000000Z',
            updated_at: '2022-02-17T06:09:33.000000Z',
            company: {
                id: 1,
                sector_id: 2,
                rts_id: 2,
                company_name: 'Nabil Bank Limited',
                symbol: 'NABIL',
                address: null,
                phone: null,
                email: 'prakash.basukala@nabilbank.com',
                website: null,
                face_value: '100',
                listed_share: '184961869',
                listed_status: 'Active',
                management_head: null,
                promoter_holding: null,
                public_holding: null,
                government_holding: null,
                foreign_ownership: null,
                created_at: '2022-02-10T04:35:37.000000Z',
                updated_at: '2022-02-10T04:35:37.000000Z',
            },
        },
        {
            id: 1,
            trade_date: '2022-01-20',
            company_id: 4,
            close_price: 1509.9,
            open_price: 1517,
            high_price: 1520,
            low_price: 1490,
            total_traded_quantity: 4552,
            total_traded_value: 6840635.7,
            total_trades: 91,
            previous_day_close_price: 1521,
            average_traded_price: 1502.78,
            week_high: 1938,
            week_low: 1077,
            market_capitalization: 4634.23,
            point_change: -11.1,
            percentage_change: 1,
            created_at: '2022-02-17T06:09:33.000000Z',
            updated_at: '2022-02-17T06:09:33.000000Z',
            company: {
                id: 4,
                sector_id: 3,
                rts_id: 2,
                company_name: 'Standard Chartered Bank Limited',
                symbol: 'ACLBSL',
                address: null,
                phone: null,
                email: 'Legal.Nepal@sc.com',
                website: null,
                face_value: '100',
                listed_share: '94294537',
                listed_status: 'Active',
                management_head: null,
                promoter_holding: null,
                public_holding: null,
                government_holding: null,
                foreign_ownership: null,
                created_at: '2022-02-10T04:35:37.000000Z',
                updated_at: '2022-02-10T04:35:37.000000Z',
            },
        },
        {
            id: 3,
            trade_date: '2022-01-20',
            company_id: 2,
            close_price: 542,
            open_price: 550,
            high_price: 559.9,
            low_price: 540,
            total_traded_quantity: 367013,
            total_traded_value: 200739654.5,
            total_trades: 1887,
            previous_day_close_price: 560,
            average_traded_price: 546.96,
            week_high: 785,
            week_low: 230,
            market_capitalization: 9374.4,
            point_change: -18,
            percentage_change: -3.32,
            created_at: '2022-02-17T06:09:33.000000Z',
            updated_at: '2022-02-17T06:09:33.000000Z',
            company: {
                id: 2,
                sector_id: 3,
                rts_id: 2,
                company_name: 'Standard Chartered Bank Limited',
                symbol: 'SCB',
                address: null,
                phone: null,
                email: 'Legal.Nepal@sc.com',
                website: null,
                face_value: '100',
                listed_share: '94294537',
                listed_status: 'Active',
                management_head: null,
                promoter_holding: null,
                public_holding: null,
                government_holding: null,
                foreign_ownership: null,
                created_at: '2022-02-10T04:35:37.000000Z',
                updated_at: '2022-02-10T04:35:37.000000Z',
            },
        },
        {
            id: 2,
            trade_date: '2022-01-20',
            company_id: 1,
            close_price: 433,
            open_price: 440,
            high_price: 442,
            low_price: 426.3,
            total_traded_quantity: 60921,
            total_traded_value: 26352303.2,
            total_trades: 401,
            previous_day_close_price: 440,
            average_traded_price: 432.57,
            week_high: 620,
            week_low: 398,
            market_capitalization: 57103.67,
            point_change: -7,
            percentage_change: 0,
            created_at: '2022-02-17T06:09:33.000000Z',
            updated_at: '2022-02-17T06:09:33.000000Z',
            company: {
                id: 1,
                sector_id: 2,
                rts_id: 2,
                company_name: 'Nabil Bank Limited',
                symbol: 'NABIL',
                address: null,
                phone: null,
                email: 'prakash.basukala@nabilbank.com',
                website: null,
                face_value: '100',
                listed_share: '184961869',
                listed_status: 'Active',
                management_head: null,
                promoter_holding: null,
                public_holding: null,
                government_holding: null,
                foreign_ownership: null,
                created_at: '2022-02-10T04:35:37.000000Z',
                updated_at: '2022-02-10T04:35:37.000000Z',
            },
        },
        {
            id: 1,
            trade_date: '2022-01-20',
            company_id: 4,
            close_price: 1509.9,
            open_price: 1517,
            high_price: 1520,
            low_price: 1490,
            total_traded_quantity: 4552,
            total_traded_value: 6840635.7,
            total_trades: 91,
            previous_day_close_price: 1521,
            average_traded_price: 1502.78,
            week_high: 1938,
            week_low: 1077,
            market_capitalization: 4634.23,
            point_change: -11.1,
            percentage_change: 1,
            created_at: '2022-02-17T06:09:33.000000Z',
            updated_at: '2022-02-17T06:09:33.000000Z',
            company: {
                id: 4,
                sector_id: 3,
                rts_id: 2,
                company_name: 'Standard Chartered Bank Limited',
                symbol: 'ACLBSL',
                address: null,
                phone: null,
                email: 'Legal.Nepal@sc.com',
                website: null,
                face_value: '100',
                listed_share: '94294537',
                listed_status: 'Active',
                management_head: null,
                promoter_holding: null,
                public_holding: null,
                government_holding: null,
                foreign_ownership: null,
                created_at: '2022-02-10T04:35:37.000000Z',
                updated_at: '2022-02-10T04:35:37.000000Z',
            },
        },
        {
            id: 3,
            trade_date: '2022-01-20',
            company_id: 2,
            close_price: 542,
            open_price: 550,
            high_price: 559.9,
            low_price: 540,
            total_traded_quantity: 367013,
            total_traded_value: 200739654.5,
            total_trades: 1887,
            previous_day_close_price: 560,
            average_traded_price: 546.96,
            week_high: 785,
            week_low: 230,
            market_capitalization: 9374.4,
            point_change: -18,
            percentage_change: -3.32,
            created_at: '2022-02-17T06:09:33.000000Z',
            updated_at: '2022-02-17T06:09:33.000000Z',
            company: {
                id: 2,
                sector_id: 3,
                rts_id: 2,
                company_name: 'Standard Chartered Bank Limited',
                symbol: 'SCB',
                address: null,
                phone: null,
                email: 'Legal.Nepal@sc.com',
                website: null,
                face_value: '100',
                listed_share: '94294537',
                listed_status: 'Active',
                management_head: null,
                promoter_holding: null,
                public_holding: null,
                government_holding: null,
                foreign_ownership: null,
                created_at: '2022-02-10T04:35:37.000000Z',
                updated_at: '2022-02-10T04:35:37.000000Z',
            },
        },
        {
            id: 2,
            trade_date: '2022-01-20',
            company_id: 1,
            close_price: 433,
            open_price: 440,
            high_price: 442,
            low_price: 426.3,
            total_traded_quantity: 60921,
            total_traded_value: 26352303.2,
            total_trades: 401,
            previous_day_close_price: 440,
            average_traded_price: 432.57,
            week_high: 620,
            week_low: 398,
            market_capitalization: 57103.67,
            point_change: -7,
            percentage_change: 0,
            created_at: '2022-02-17T06:09:33.000000Z',
            updated_at: '2022-02-17T06:09:33.000000Z',
            company: {
                id: 1,
                sector_id: 2,
                rts_id: 2,
                company_name: 'Nabil Bank Limited',
                symbol: 'NABIL',
                address: null,
                phone: null,
                email: 'prakash.basukala@nabilbank.com',
                website: null,
                face_value: '100',
                listed_share: '184961869',
                listed_status: 'Active',
                management_head: null,
                promoter_holding: null,
                public_holding: null,
                government_holding: null,
                foreign_ownership: null,
                created_at: '2022-02-10T04:35:37.000000Z',
                updated_at: '2022-02-10T04:35:37.000000Z',
            },
        },
        {
            id: 1,
            trade_date: '2022-01-20',
            company_id: 4,
            close_price: 1509.9,
            open_price: 1517,
            high_price: 1520,
            low_price: 1490,
            total_traded_quantity: 4552,
            total_traded_value: 6840635.7,
            total_trades: 91,
            previous_day_close_price: 1521,
            average_traded_price: 1502.78,
            week_high: 1938,
            week_low: 1077,
            market_capitalization: 4634.23,
            point_change: -11.1,
            percentage_change: 1,
            created_at: '2022-02-17T06:09:33.000000Z',
            updated_at: '2022-02-17T06:09:33.000000Z',
            company: {
                id: 4,
                sector_id: 3,
                rts_id: 2,
                company_name: 'Standard Chartered Bank Limited',
                symbol: 'ACLBSL',
                address: null,
                phone: null,
                email: 'Legal.Nepal@sc.com',
                website: null,
                face_value: '100',
                listed_share: '94294537',
                listed_status: 'Active',
                management_head: null,
                promoter_holding: null,
                public_holding: null,
                government_holding: null,
                foreign_ownership: null,
                created_at: '2022-02-10T04:35:37.000000Z',
                updated_at: '2022-02-10T04:35:37.000000Z',
            },
        },
        {
            id: 3,
            trade_date: '2022-01-20',
            company_id: 2,
            close_price: 542,
            open_price: 550,
            high_price: 559.9,
            low_price: 540,
            total_traded_quantity: 367013,
            total_traded_value: 200739654.5,
            total_trades: 1887,
            previous_day_close_price: 560,
            average_traded_price: 546.96,
            week_high: 785,
            week_low: 230,
            market_capitalization: 9374.4,
            point_change: -18,
            percentage_change: -3.32,
            created_at: '2022-02-17T06:09:33.000000Z',
            updated_at: '2022-02-17T06:09:33.000000Z',
            company: {
                id: 2,
                sector_id: 3,
                rts_id: 2,
                company_name: 'Standard Chartered Bank Limited',
                symbol: 'SCB',
                address: null,
                phone: null,
                email: 'Legal.Nepal@sc.com',
                website: null,
                face_value: '100',
                listed_share: '94294537',
                listed_status: 'Active',
                management_head: null,
                promoter_holding: null,
                public_holding: null,
                government_holding: null,
                foreign_ownership: null,
                created_at: '2022-02-10T04:35:37.000000Z',
                updated_at: '2022-02-10T04:35:37.000000Z',
            },
        },
        {
            id: 2,
            trade_date: '2022-01-20',
            company_id: 1,
            close_price: 433,
            open_price: 440,
            high_price: 442,
            low_price: 426.3,
            total_traded_quantity: 60921,
            total_traded_value: 26352303.2,
            total_trades: 401,
            previous_day_close_price: 440,
            average_traded_price: 432.57,
            week_high: 620,
            week_low: 398,
            market_capitalization: 57103.67,
            point_change: -7,
            percentage_change: 0,
            created_at: '2022-02-17T06:09:33.000000Z',
            updated_at: '2022-02-17T06:09:33.000000Z',
            company: {
                id: 1,
                sector_id: 2,
                rts_id: 2,
                company_name: 'Nabil Bank Limited',
                symbol: 'NABIL',
                address: null,
                phone: null,
                email: 'prakash.basukala@nabilbank.com',
                website: null,
                face_value: '100',
                listed_share: '184961869',
                listed_status: 'Active',
                management_head: null,
                promoter_holding: null,
                public_holding: null,
                government_holding: null,
                foreign_ownership: null,
                created_at: '2022-02-10T04:35:37.000000Z',
                updated_at: '2022-02-10T04:35:37.000000Z',
            },
        },
        {
            id: 1,
            trade_date: '2022-01-20',
            company_id: 4,
            close_price: 1509.9,
            open_price: 1517,
            high_price: 1520,
            low_price: 1490,
            total_traded_quantity: 4552,
            total_traded_value: 6840635.7,
            total_trades: 91,
            previous_day_close_price: 1521,
            average_traded_price: 1502.78,
            week_high: 1938,
            week_low: 1077,
            market_capitalization: 4634.23,
            point_change: -11.1,
            percentage_change: 1,
            created_at: '2022-02-17T06:09:33.000000Z',
            updated_at: '2022-02-17T06:09:33.000000Z',
            company: {
                id: 4,
                sector_id: 3,
                rts_id: 2,
                company_name: 'Standard Chartered Bank Limited',
                symbol: 'ACLBSL',
                address: null,
                phone: null,
                email: 'Legal.Nepal@sc.com',
                website: null,
                face_value: '100',
                listed_share: '94294537',
                listed_status: 'Active',
                management_head: null,
                promoter_holding: null,
                public_holding: null,
                government_holding: null,
                foreign_ownership: null,
                created_at: '2022-02-10T04:35:37.000000Z',
                updated_at: '2022-02-10T04:35:37.000000Z',
            },
        },
        {
            id: 3,
            trade_date: '2022-01-20',
            company_id: 2,
            close_price: 542,
            open_price: 550,
            high_price: 559.9,
            low_price: 540,
            total_traded_quantity: 367013,
            total_traded_value: 200739654.5,
            total_trades: 1887,
            previous_day_close_price: 560,
            average_traded_price: 546.96,
            week_high: 785,
            week_low: 230,
            market_capitalization: 9374.4,
            point_change: -18,
            percentage_change: -3.32,
            created_at: '2022-02-17T06:09:33.000000Z',
            updated_at: '2022-02-17T06:09:33.000000Z',
            company: {
                id: 2,
                sector_id: 3,
                rts_id: 2,
                company_name: 'Standard Chartered Bank Limited',
                symbol: 'SCB',
                address: null,
                phone: null,
                email: 'Legal.Nepal@sc.com',
                website: null,
                face_value: '100',
                listed_share: '94294537',
                listed_status: 'Active',
                management_head: null,
                promoter_holding: null,
                public_holding: null,
                government_holding: null,
                foreign_ownership: null,
                created_at: '2022-02-10T04:35:37.000000Z',
                updated_at: '2022-02-10T04:35:37.000000Z',
            },
        },
        {
            id: 2,
            trade_date: '2022-01-20',
            company_id: 1,
            close_price: 433,
            open_price: 440,
            high_price: 442,
            low_price: 426.3,
            total_traded_quantity: 60921,
            total_traded_value: 26352303.2,
            total_trades: 401,
            previous_day_close_price: 440,
            average_traded_price: 432.57,
            week_high: 620,
            week_low: 398,
            market_capitalization: 57103.67,
            point_change: -7,
            percentage_change: 0,
            created_at: '2022-02-17T06:09:33.000000Z',
            updated_at: '2022-02-17T06:09:33.000000Z',
            company: {
                id: 1,
                sector_id: 2,
                rts_id: 2,
                company_name: 'Nabil Bank Limited',
                symbol: 'NABIL',
                address: null,
                phone: null,
                email: 'prakash.basukala@nabilbank.com',
                website: null,
                face_value: '100',
                listed_share: '184961869',
                listed_status: 'Active',
                management_head: null,
                promoter_holding: null,
                public_holding: null,
                government_holding: null,
                foreign_ownership: null,
                created_at: '2022-02-10T04:35:37.000000Z',
                updated_at: '2022-02-10T04:35:37.000000Z',
            },
        },
        {
            id: 1,
            trade_date: '2022-01-20',
            company_id: 4,
            close_price: 1509.9,
            open_price: 1517,
            high_price: 1520,
            low_price: 1490,
            total_traded_quantity: 4552,
            total_traded_value: 6840635.7,
            total_trades: 91,
            previous_day_close_price: 1521,
            average_traded_price: 1502.78,
            week_high: 1938,
            week_low: 1077,
            market_capitalization: 4634.23,
            point_change: -11.1,
            percentage_change: 1,
            created_at: '2022-02-17T06:09:33.000000Z',
            updated_at: '2022-02-17T06:09:33.000000Z',
            company: {
                id: 4,
                sector_id: 3,
                rts_id: 2,
                company_name: 'Standard Chartered Bank Limited',
                symbol: 'ACLBSL',
                address: null,
                phone: null,
                email: 'Legal.Nepal@sc.com',
                website: null,
                face_value: '100',
                listed_share: '94294537',
                listed_status: 'Active',
                management_head: null,
                promoter_holding: null,
                public_holding: null,
                government_holding: null,
                foreign_ownership: null,
                created_at: '2022-02-10T04:35:37.000000Z',
                updated_at: '2022-02-10T04:35:37.000000Z',
            },
        },
        {
            id: 3,
            trade_date: '2022-01-20',
            company_id: 2,
            close_price: 542,
            open_price: 550,
            high_price: 559.9,
            low_price: 540,
            total_traded_quantity: 367013,
            total_traded_value: 200739654.5,
            total_trades: 1887,
            previous_day_close_price: 560,
            average_traded_price: 546.96,
            week_high: 785,
            week_low: 230,
            market_capitalization: 9374.4,
            point_change: -18,
            percentage_change: -3.32,
            created_at: '2022-02-17T06:09:33.000000Z',
            updated_at: '2022-02-17T06:09:33.000000Z',
            company: {
                id: 2,
                sector_id: 3,
                rts_id: 2,
                company_name: 'Standard Chartered Bank Limited',
                symbol: 'SCB',
                address: null,
                phone: null,
                email: 'Legal.Nepal@sc.com',
                website: null,
                face_value: '100',
                listed_share: '94294537',
                listed_status: 'Active',
                management_head: null,
                promoter_holding: null,
                public_holding: null,
                government_holding: null,
                foreign_ownership: null,
                created_at: '2022-02-10T04:35:37.000000Z',
                updated_at: '2022-02-10T04:35:37.000000Z',
            },
        },
        {
            id: 2,
            trade_date: '2022-01-20',
            company_id: 1,
            close_price: 433,
            open_price: 440,
            high_price: 442,
            low_price: 426.3,
            total_traded_quantity: 60921,
            total_traded_value: 26352303.2,
            total_trades: 401,
            previous_day_close_price: 440,
            average_traded_price: 432.57,
            week_high: 620,
            week_low: 398,
            market_capitalization: 57103.67,
            point_change: -7,
            percentage_change: 0,
            created_at: '2022-02-17T06:09:33.000000Z',
            updated_at: '2022-02-17T06:09:33.000000Z',
            company: {
                id: 1,
                sector_id: 2,
                rts_id: 2,
                company_name: 'Nabil Bank Limited',
                symbol: 'NABIL',
                address: null,
                phone: null,
                email: 'prakash.basukala@nabilbank.com',
                website: null,
                face_value: '100',
                listed_share: '184961869',
                listed_status: 'Active',
                management_head: null,
                promoter_holding: null,
                public_holding: null,
                government_holding: null,
                foreign_ownership: null,
                created_at: '2022-02-10T04:35:37.000000Z',
                updated_at: '2022-02-10T04:35:37.000000Z',
            },
        },
        {
            id: 1,
            trade_date: '2022-01-20',
            company_id: 4,
            close_price: 1509.9,
            open_price: 1517,
            high_price: 1520,
            low_price: 1490,
            total_traded_quantity: 4552,
            total_traded_value: 6840635.7,
            total_trades: 91,
            previous_day_close_price: 1521,
            average_traded_price: 1502.78,
            week_high: 1938,
            week_low: 1077,
            market_capitalization: 4634.23,
            point_change: -11.1,
            percentage_change: 1,
            created_at: '2022-02-17T06:09:33.000000Z',
            updated_at: '2022-02-17T06:09:33.000000Z',
            company: {
                id: 4,
                sector_id: 3,
                rts_id: 2,
                company_name: 'Standard Chartered Bank Limited',
                symbol: 'ACLBSL',
                address: null,
                phone: null,
                email: 'Legal.Nepal@sc.com',
                website: null,
                face_value: '100',
                listed_share: '94294537',
                listed_status: 'Active',
                management_head: null,
                promoter_holding: null,
                public_holding: null,
                government_holding: null,
                foreign_ownership: null,
                created_at: '2022-02-10T04:35:37.000000Z',
                updated_at: '2022-02-10T04:35:37.000000Z',
            },
        },
        {
            id: 3,
            trade_date: '2022-01-20',
            company_id: 2,
            close_price: 542,
            open_price: 550,
            high_price: 559.9,
            low_price: 540,
            total_traded_quantity: 367013,
            total_traded_value: 200739654.5,
            total_trades: 1887,
            previous_day_close_price: 560,
            average_traded_price: 546.96,
            week_high: 785,
            week_low: 230,
            market_capitalization: 9374.4,
            point_change: -18,
            percentage_change: -3.32,
            created_at: '2022-02-17T06:09:33.000000Z',
            updated_at: '2022-02-17T06:09:33.000000Z',
            company: {
                id: 2,
                sector_id: 3,
                rts_id: 2,
                company_name: 'Standard Chartered Bank Limited',
                symbol: 'SCB',
                address: null,
                phone: null,
                email: 'Legal.Nepal@sc.com',
                website: null,
                face_value: '100',
                listed_share: '94294537',
                listed_status: 'Active',
                management_head: null,
                promoter_holding: null,
                public_holding: null,
                government_holding: null,
                foreign_ownership: null,
                created_at: '2022-02-10T04:35:37.000000Z',
                updated_at: '2022-02-10T04:35:37.000000Z',
            },
        },
        {
            id: 2,
            trade_date: '2022-01-20',
            company_id: 1,
            close_price: 433,
            open_price: 440,
            high_price: 442,
            low_price: 426.3,
            total_traded_quantity: 60921,
            total_traded_value: 26352303.2,
            total_trades: 401,
            previous_day_close_price: 440,
            average_traded_price: 432.57,
            week_high: 620,
            week_low: 398,
            market_capitalization: 57103.67,
            point_change: -7,
            percentage_change: 0,
            created_at: '2022-02-17T06:09:33.000000Z',
            updated_at: '2022-02-17T06:09:33.000000Z',
            company: {
                id: 1,
                sector_id: 2,
                rts_id: 2,
                company_name: 'Nabil Bank Limited',
                symbol: 'NABIL',
                address: null,
                phone: null,
                email: 'prakash.basukala@nabilbank.com',
                website: null,
                face_value: '100',
                listed_share: '184961869',
                listed_status: 'Active',
                management_head: null,
                promoter_holding: null,
                public_holding: null,
                government_holding: null,
                foreign_ownership: null,
                created_at: '2022-02-10T04:35:37.000000Z',
                updated_at: '2022-02-10T04:35:37.000000Z',
            },
        },
        {
            id: 1,
            trade_date: '2022-01-20',
            company_id: 4,
            close_price: 1509.9,
            open_price: 1517,
            high_price: 1520,
            low_price: 1490,
            total_traded_quantity: 4552,
            total_traded_value: 6840635.7,
            total_trades: 91,
            previous_day_close_price: 1521,
            average_traded_price: 1502.78,
            week_high: 1938,
            week_low: 1077,
            market_capitalization: 4634.23,
            point_change: -11.1,
            percentage_change: 1,
            created_at: '2022-02-17T06:09:33.000000Z',
            updated_at: '2022-02-17T06:09:33.000000Z',
            company: {
                id: 4,
                sector_id: 3,
                rts_id: 2,
                company_name: 'Standard Chartered Bank Limited',
                symbol: 'ACLBSL',
                address: null,
                phone: null,
                email: 'Legal.Nepal@sc.com',
                website: null,
                face_value: '100',
                listed_share: '94294537',
                listed_status: 'Active',
                management_head: null,
                promoter_holding: null,
                public_holding: null,
                government_holding: null,
                foreign_ownership: null,
                created_at: '2022-02-10T04:35:37.000000Z',
                updated_at: '2022-02-10T04:35:37.000000Z',
            },
        },
        {
            id: 3,
            trade_date: '2022-01-20',
            company_id: 2,
            close_price: 542,
            open_price: 550,
            high_price: 559.9,
            low_price: 540,
            total_traded_quantity: 367013,
            total_traded_value: 200739654.5,
            total_trades: 1887,
            previous_day_close_price: 560,
            average_traded_price: 546.96,
            week_high: 785,
            week_low: 230,
            market_capitalization: 9374.4,
            point_change: -18,
            percentage_change: -3.32,
            created_at: '2022-02-17T06:09:33.000000Z',
            updated_at: '2022-02-17T06:09:33.000000Z',
            company: {
                id: 2,
                sector_id: 3,
                rts_id: 2,
                company_name: 'Standard Chartered Bank Limited',
                symbol: 'SCB',
                address: null,
                phone: null,
                email: 'Legal.Nepal@sc.com',
                website: null,
                face_value: '100',
                listed_share: '94294537',
                listed_status: 'Active',
                management_head: null,
                promoter_holding: null,
                public_holding: null,
                government_holding: null,
                foreign_ownership: null,
                created_at: '2022-02-10T04:35:37.000000Z',
                updated_at: '2022-02-10T04:35:37.000000Z',
            },
        },
        {
            id: 2,
            trade_date: '2022-01-20',
            company_id: 1,
            close_price: 433,
            open_price: 440,
            high_price: 442,
            low_price: 426.3,
            total_traded_quantity: 60921,
            total_traded_value: 26352303.2,
            total_trades: 401,
            previous_day_close_price: 440,
            average_traded_price: 432.57,
            week_high: 620,
            week_low: 398,
            market_capitalization: 57103.67,
            point_change: -7,
            percentage_change: 0,
            created_at: '2022-02-17T06:09:33.000000Z',
            updated_at: '2022-02-17T06:09:33.000000Z',
            company: {
                id: 1,
                sector_id: 2,
                rts_id: 2,
                company_name: 'Nabil Bank Limited',
                symbol: 'NABIL',
                address: null,
                phone: null,
                email: 'prakash.basukala@nabilbank.com',
                website: null,
                face_value: '100',
                listed_share: '184961869',
                listed_status: 'Active',
                management_head: null,
                promoter_holding: null,
                public_holding: null,
                government_holding: null,
                foreign_ownership: null,
                created_at: '2022-02-10T04:35:37.000000Z',
                updated_at: '2022-02-10T04:35:37.000000Z',
            },
        },
        {
            id: 1,
            trade_date: '2022-01-20',
            company_id: 4,
            close_price: 1509.9,
            open_price: 1517,
            high_price: 1520,
            low_price: 1490,
            total_traded_quantity: 4552,
            total_traded_value: 6840635.7,
            total_trades: 91,
            previous_day_close_price: 1521,
            average_traded_price: 1502.78,
            week_high: 1938,
            week_low: 1077,
            market_capitalization: 4634.23,
            point_change: -11.1,
            percentage_change: 1,
            created_at: '2022-02-17T06:09:33.000000Z',
            updated_at: '2022-02-17T06:09:33.000000Z',
            company: {
                id: 4,
                sector_id: 3,
                rts_id: 2,
                company_name: 'Standard Chartered Bank Limited',
                symbol: 'ACLBSL',
                address: null,
                phone: null,
                email: 'Legal.Nepal@sc.com',
                website: null,
                face_value: '100',
                listed_share: '94294537',
                listed_status: 'Active',
                management_head: null,
                promoter_holding: null,
                public_holding: null,
                government_holding: null,
                foreign_ownership: null,
                created_at: '2022-02-10T04:35:37.000000Z',
                updated_at: '2022-02-10T04:35:37.000000Z',
            },
        },
        {
            id: 3,
            trade_date: '2022-01-20',
            company_id: 2,
            close_price: 542,
            open_price: 550,
            high_price: 559.9,
            low_price: 540,
            total_traded_quantity: 367013,
            total_traded_value: 200739654.5,
            total_trades: 1887,
            previous_day_close_price: 560,
            average_traded_price: 546.96,
            week_high: 785,
            week_low: 230,
            market_capitalization: 9374.4,
            point_change: -18,
            percentage_change: -3.32,
            created_at: '2022-02-17T06:09:33.000000Z',
            updated_at: '2022-02-17T06:09:33.000000Z',
            company: {
                id: 2,
                sector_id: 3,
                rts_id: 2,
                company_name: 'Standard Chartered Bank Limited',
                symbol: 'SCB',
                address: null,
                phone: null,
                email: 'Legal.Nepal@sc.com',
                website: null,
                face_value: '100',
                listed_share: '94294537',
                listed_status: 'Active',
                management_head: null,
                promoter_holding: null,
                public_holding: null,
                government_holding: null,
                foreign_ownership: null,
                created_at: '2022-02-10T04:35:37.000000Z',
                updated_at: '2022-02-10T04:35:37.000000Z',
            },
        },
        {
            id: 2,
            trade_date: '2022-01-20',
            company_id: 1,
            close_price: 433,
            open_price: 440,
            high_price: 442,
            low_price: 426.3,
            total_traded_quantity: 60921,
            total_traded_value: 26352303.2,
            total_trades: 401,
            previous_day_close_price: 440,
            average_traded_price: 432.57,
            week_high: 620,
            week_low: 398,
            market_capitalization: 57103.67,
            point_change: -7,
            percentage_change: 0,
            created_at: '2022-02-17T06:09:33.000000Z',
            updated_at: '2022-02-17T06:09:33.000000Z',
            company: {
                id: 1,
                sector_id: 2,
                rts_id: 2,
                company_name: 'Nabil Bank Limited',
                symbol: 'NABIL',
                address: null,
                phone: null,
                email: 'prakash.basukala@nabilbank.com',
                website: null,
                face_value: '100',
                listed_share: '184961869',
                listed_status: 'Active',
                management_head: null,
                promoter_holding: null,
                public_holding: null,
                government_holding: null,
                foreign_ownership: null,
                created_at: '2022-02-10T04:35:37.000000Z',
                updated_at: '2022-02-10T04:35:37.000000Z',
            },
        },
        {
            id: 1,
            trade_date: '2022-01-20',
            company_id: 4,
            close_price: 1509.9,
            open_price: 1517,
            high_price: 1520,
            low_price: 1490,
            total_traded_quantity: 4552,
            total_traded_value: 6840635.7,
            total_trades: 91,
            previous_day_close_price: 1521,
            average_traded_price: 1502.78,
            week_high: 1938,
            week_low: 1077,
            market_capitalization: 4634.23,
            point_change: -11.1,
            percentage_change: 1,
            created_at: '2022-02-17T06:09:33.000000Z',
            updated_at: '2022-02-17T06:09:33.000000Z',
            company: {
                id: 4,
                sector_id: 3,
                rts_id: 2,
                company_name: 'Standard Chartered Bank Limited',
                symbol: 'ACLBSL',
                address: null,
                phone: null,
                email: 'Legal.Nepal@sc.com',
                website: null,
                face_value: '100',
                listed_share: '94294537',
                listed_status: 'Active',
                management_head: null,
                promoter_holding: null,
                public_holding: null,
                government_holding: null,
                foreign_ownership: null,
                created_at: '2022-02-10T04:35:37.000000Z',
                updated_at: '2022-02-10T04:35:37.000000Z',
            },
        },
        {
            id: 3,
            trade_date: '2022-01-20',
            company_id: 2,
            close_price: 542,
            open_price: 550,
            high_price: 559.9,
            low_price: 540,
            total_traded_quantity: 367013,
            total_traded_value: 200739654.5,
            total_trades: 1887,
            previous_day_close_price: 560,
            average_traded_price: 546.96,
            week_high: 785,
            week_low: 230,
            market_capitalization: 9374.4,
            point_change: -18,
            percentage_change: -3.32,
            created_at: '2022-02-17T06:09:33.000000Z',
            updated_at: '2022-02-17T06:09:33.000000Z',
            company: {
                id: 2,
                sector_id: 3,
                rts_id: 2,
                company_name: 'Standard Chartered Bank Limited',
                symbol: 'SCB',
                address: null,
                phone: null,
                email: 'Legal.Nepal@sc.com',
                website: null,
                face_value: '100',
                listed_share: '94294537',
                listed_status: 'Active',
                management_head: null,
                promoter_holding: null,
                public_holding: null,
                government_holding: null,
                foreign_ownership: null,
                created_at: '2022-02-10T04:35:37.000000Z',
                updated_at: '2022-02-10T04:35:37.000000Z',
            },
        },
        {
            id: 2,
            trade_date: '2022-01-20',
            company_id: 1,
            close_price: 433,
            open_price: 440,
            high_price: 442,
            low_price: 426.3,
            total_traded_quantity: 60921,
            total_traded_value: 26352303.2,
            total_trades: 401,
            previous_day_close_price: 440,
            average_traded_price: 432.57,
            week_high: 620,
            week_low: 398,
            market_capitalization: 57103.67,
            point_change: -7,
            percentage_change: 0,
            created_at: '2022-02-17T06:09:33.000000Z',
            updated_at: '2022-02-17T06:09:33.000000Z',
            company: {
                id: 1,
                sector_id: 2,
                rts_id: 2,
                company_name: 'Nabil Bank Limited',
                symbol: 'NABIL',
                address: null,
                phone: null,
                email: 'prakash.basukala@nabilbank.com',
                website: null,
                face_value: '100',
                listed_share: '184961869',
                listed_status: 'Active',
                management_head: null,
                promoter_holding: null,
                public_holding: null,
                government_holding: null,
                foreign_ownership: null,
                created_at: '2022-02-10T04:35:37.000000Z',
                updated_at: '2022-02-10T04:35:37.000000Z',
            },
        },
        {
            id: 1,
            trade_date: '2022-01-20',
            company_id: 4,
            close_price: 1509.9,
            open_price: 1517,
            high_price: 1520,
            low_price: 1490,
            total_traded_quantity: 4552,
            total_traded_value: 6840635.7,
            total_trades: 91,
            previous_day_close_price: 1521,
            average_traded_price: 1502.78,
            week_high: 1938,
            week_low: 1077,
            market_capitalization: 4634.23,
            point_change: -11.1,
            percentage_change: 1,
            created_at: '2022-02-17T06:09:33.000000Z',
            updated_at: '2022-02-17T06:09:33.000000Z',
            company: {
                id: 4,
                sector_id: 3,
                rts_id: 2,
                company_name: 'Standard Chartered Bank Limited',
                symbol: 'ACLBSL',
                address: null,
                phone: null,
                email: 'Legal.Nepal@sc.com',
                website: null,
                face_value: '100',
                listed_share: '94294537',
                listed_status: 'Active',
                management_head: null,
                promoter_holding: null,
                public_holding: null,
                government_holding: null,
                foreign_ownership: null,
                created_at: '2022-02-10T04:35:37.000000Z',
                updated_at: '2022-02-10T04:35:37.000000Z',
            },
        },
        {
            id: 3,
            trade_date: '2022-01-20',
            company_id: 2,
            close_price: 542,
            open_price: 550,
            high_price: 559.9,
            low_price: 540,
            total_traded_quantity: 367013,
            total_traded_value: 200739654.5,
            total_trades: 1887,
            previous_day_close_price: 560,
            average_traded_price: 546.96,
            week_high: 785,
            week_low: 230,
            market_capitalization: 9374.4,
            point_change: -18,
            percentage_change: -3.32,
            created_at: '2022-02-17T06:09:33.000000Z',
            updated_at: '2022-02-17T06:09:33.000000Z',
            company: {
                id: 2,
                sector_id: 3,
                rts_id: 2,
                company_name: 'Standard Chartered Bank Limited',
                symbol: 'SCB',
                address: null,
                phone: null,
                email: 'Legal.Nepal@sc.com',
                website: null,
                face_value: '100',
                listed_share: '94294537',
                listed_status: 'Active',
                management_head: null,
                promoter_holding: null,
                public_holding: null,
                government_holding: null,
                foreign_ownership: null,
                created_at: '2022-02-10T04:35:37.000000Z',
                updated_at: '2022-02-10T04:35:37.000000Z',
            },
        },
        {
            id: 2,
            trade_date: '2022-01-20',
            company_id: 1,
            close_price: 433,
            open_price: 440,
            high_price: 442,
            low_price: 426.3,
            total_traded_quantity: 60921,
            total_traded_value: 26352303.2,
            total_trades: 401,
            previous_day_close_price: 440,
            average_traded_price: 432.57,
            week_high: 620,
            week_low: 398,
            market_capitalization: 57103.67,
            point_change: -7,
            percentage_change: 0,
            created_at: '2022-02-17T06:09:33.000000Z',
            updated_at: '2022-02-17T06:09:33.000000Z',
            company: {
                id: 1,
                sector_id: 2,
                rts_id: 2,
                company_name: 'Nabil Bank Limited',
                symbol: 'NABIL',
                address: null,
                phone: null,
                email: 'prakash.basukala@nabilbank.com',
                website: null,
                face_value: '100',
                listed_share: '184961869',
                listed_status: 'Active',
                management_head: null,
                promoter_holding: null,
                public_holding: null,
                government_holding: null,
                foreign_ownership: null,
                created_at: '2022-02-10T04:35:37.000000Z',
                updated_at: '2022-02-10T04:35:37.000000Z',
            },
        },
        {
            id: 1,
            trade_date: '2022-01-20',
            company_id: 4,
            close_price: 1509.9,
            open_price: 1517,
            high_price: 1520,
            low_price: 1490,
            total_traded_quantity: 4552,
            total_traded_value: 6840635.7,
            total_trades: 91,
            previous_day_close_price: 1521,
            average_traded_price: 1502.78,
            week_high: 1938,
            week_low: 1077,
            market_capitalization: 4634.23,
            point_change: -11.1,
            percentage_change: 1,
            created_at: '2022-02-17T06:09:33.000000Z',
            updated_at: '2022-02-17T06:09:33.000000Z',
            company: {
                id: 4,
                sector_id: 3,
                rts_id: 2,
                company_name: 'Standard Chartered Bank Limited',
                symbol: 'ACLBSL',
                address: null,
                phone: null,
                email: 'Legal.Nepal@sc.com',
                website: null,
                face_value: '100',
                listed_share: '94294537',
                listed_status: 'Active',
                management_head: null,
                promoter_holding: null,
                public_holding: null,
                government_holding: null,
                foreign_ownership: null,
                created_at: '2022-02-10T04:35:37.000000Z',
                updated_at: '2022-02-10T04:35:37.000000Z',
            },
        },
        {
            id: 3,
            trade_date: '2022-01-20',
            company_id: 2,
            close_price: 542,
            open_price: 550,
            high_price: 559.9,
            low_price: 540,
            total_traded_quantity: 367013,
            total_traded_value: 200739654.5,
            total_trades: 1887,
            previous_day_close_price: 560,
            average_traded_price: 546.96,
            week_high: 785,
            week_low: 230,
            market_capitalization: 9374.4,
            point_change: -18,
            percentage_change: -3.32,
            created_at: '2022-02-17T06:09:33.000000Z',
            updated_at: '2022-02-17T06:09:33.000000Z',
            company: {
                id: 2,
                sector_id: 3,
                rts_id: 2,
                company_name: 'Standard Chartered Bank Limited',
                symbol: 'SCB',
                address: null,
                phone: null,
                email: 'Legal.Nepal@sc.com',
                website: null,
                face_value: '100',
                listed_share: '94294537',
                listed_status: 'Active',
                management_head: null,
                promoter_holding: null,
                public_holding: null,
                government_holding: null,
                foreign_ownership: null,
                created_at: '2022-02-10T04:35:37.000000Z',
                updated_at: '2022-02-10T04:35:37.000000Z',
            },
        },
        {
            id: 2,
            trade_date: '2022-01-20',
            company_id: 1,
            close_price: 433,
            open_price: 440,
            high_price: 442,
            low_price: 426.3,
            total_traded_quantity: 60921,
            total_traded_value: 26352303.2,
            total_trades: 401,
            previous_day_close_price: 440,
            average_traded_price: 432.57,
            week_high: 620,
            week_low: 398,
            market_capitalization: 57103.67,
            point_change: -7,
            percentage_change: 0,
            created_at: '2022-02-17T06:09:33.000000Z',
            updated_at: '2022-02-17T06:09:33.000000Z',
            company: {
                id: 1,
                sector_id: 2,
                rts_id: 2,
                company_name: 'Nabil Bank Limited',
                symbol: 'NABIL',
                address: null,
                phone: null,
                email: 'prakash.basukala@nabilbank.com',
                website: null,
                face_value: '100',
                listed_share: '184961869',
                listed_status: 'Active',
                management_head: null,
                promoter_holding: null,
                public_holding: null,
                government_holding: null,
                foreign_ownership: null,
                created_at: '2022-02-10T04:35:37.000000Z',
                updated_at: '2022-02-10T04:35:37.000000Z',
            },
        },
        {
            id: 1,
            trade_date: '2022-01-20',
            company_id: 4,
            close_price: 1509.9,
            open_price: 1517,
            high_price: 1520,
            low_price: 1490,
            total_traded_quantity: 4552,
            total_traded_value: 6840635.7,
            total_trades: 91,
            previous_day_close_price: 1521,
            average_traded_price: 1502.78,
            week_high: 1938,
            week_low: 1077,
            market_capitalization: 4634.23,
            point_change: -11.1,
            percentage_change: 1,
            created_at: '2022-02-17T06:09:33.000000Z',
            updated_at: '2022-02-17T06:09:33.000000Z',
            company: {
                id: 4,
                sector_id: 3,
                rts_id: 2,
                company_name: 'Standard Chartered Bank Limited',
                symbol: 'ACLBSL',
                address: null,
                phone: null,
                email: 'Legal.Nepal@sc.com',
                website: null,
                face_value: '100',
                listed_share: '94294537',
                listed_status: 'Active',
                management_head: null,
                promoter_holding: null,
                public_holding: null,
                government_holding: null,
                foreign_ownership: null,
                created_at: '2022-02-10T04:35:37.000000Z',
                updated_at: '2022-02-10T04:35:37.000000Z',
            },
        },
    ],
    meta_data: {
        pagination: {
            total: 3,
            per_page: 15,
            last_page: 1,
            current_page: 1,
            from: 1,
            to: 3,
        },
    },
}

const marketSummaryData = {
    trade_date: 'shareMarket.tradeDate',
    total_turnovers: 'shareMarket.totalTurnovers',
    total_traded_shares: 'shareMarket.totalTradedShares',
    total_transaction: 'shareMarket.totalTransaction',
    total_script_traded: 'shareMarket.totalScriptTraded',
    total_market_capitalization: 'shareMarket.totalMarketCapitalization',
    float_market_capitalization: 'shareMarket.floatMarketCapitalization',
}

export {
    dummyData,
    navigationTitles,
    drawerDummyData,
    countryDetails,
    countryFlags,
    interestTopicsHeadings,
    interestTopicsImages,
    newsTopics,
    ellipseMenuItems,
    nepaliEnglishNumerals,
    commentData,
    widgetData,
    shareMarketData,
    dashboardListTemplateData,
    calculatorHeadings,
    calculatorHeadingsSelect,
    englishNepaliNumerals,
    nepaliMonths,
    ePaperData,
    englishToNepaliDays,
    englishToEnglishMonths,
    advertisementTypes,
    englishToEnglishDay,
    englishNumberToEnglishDay,
    forexData,
    dateConverterDataMonthDay,
    dateConverterDataYear,
    dateConverterMonthId,
    nepaliMonthsIndex,
    dateConverterMonthEn,
    dateConverterMonthEnShort,
    dateConverterMonthNp,
    nepaliToEnglishMonths,
    forexWidgetHeading,
    shareWidgetHeading,
    shareCardData,
    closePriceData,
    marketSummaryData,
}

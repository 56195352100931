import { endpoints } from 'global/endpoints';
import http from 'utils/http';
import { useQuery } from 'react-query';

const fetchRoleById = (id) => {
  if (id) {
    const updatedEndpoints = endpoints.role.specific.replace('{id}', id)
    return http('json').get(updatedEndpoints)
  }
}

export function useFetchUserRole(id) {
  return useQuery(['sssdf', id], () => fetchRoleById(id))
}

import { endpoints } from 'global/endpoints'
import http from 'utils/http'
import { useMutation } from 'react-query'

import { useFetchUserRole } from 'hooks/role/useFetchUserRole'

const postAdminLogin = (adminCredentials) => {
  if (adminCredentials) {
    return http().post(endpoints.login.base, adminCredentials)
  }
}
export default function usePostAdminLogin() {
  const fetchUserRole = useFetchUserRole()
  return useMutation(postAdminLogin)
}
